import React, { useEffect, useState } from "react";
import { Col, Container, Row, Button, Input, Modal, ModalHeader, ModalBody, Alert, Label, ModalFooter, Table, } from "reactstrap";
import { Link, useHistory } from 'react-router-dom';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
import FotoModal from "../../assets/images/telkom/modal.png";

import imgPelamar from "../../assets/images/telkom/jumlahPerserta.png";
import imgFeedback from "../../assets/images/telkom/jumlahFeedback.png";
import imgSubmit from "../../assets/images/telkom/telahSubmit.png";

import Timeout from "../../assets/images/telkom/timeout.svg";
import crypto from "../Setting/crypto";

import axios from "axios";

const I1 = () => {
  document.title = "Dashboard Admin";

  // ---------- GET ALL EVENT ------------ //
  const [dataUser, setDataUser] = useState({});

  // -------- Get All Event Interview -------- //
  const [nama, setNama] = useState("");
  const [eventList, setEventList] = useState([]);
  const [ambilIdEvent, setAmbilIdEvent] = useState(sessionStorage.getItem("pilihEvent"));
  //`Telah di edit oleh ${localStorage.nama} (admin) pada waktu ${fungsi javascript pengambilan waktu saat ini}}`
  // -------- Get Number Count ------- //
  const [jumlahPelamar, setJumlahPelamar] = useState(0);
  const [jumlahFeedback, setJumlahFeedback] = useState(0);
  const [jumlahSubmit, setJumlahSubmit] = useState(0);

  // ---------- GET ALL EVENT PELAMAR ------------- //
  const [pelamar, setPelamar] = useState([]);

  // ------- Get Pelemar By ID ------- //
  const [namato, setNamato] = useState("");
  const [emailto, setEmailto] = useState("");
  const [noHPto, setNoHPto] = useState("");
  const [alamatto, setAlamatto] = useState("");
  const [tanggalLahirto, setTanggalLahirto] = useState("");

  // ------- Agenda -------- //
  const [eventTanggal, setEventTanggal] = useState([]);
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [eventOnDate, setEventOnDate] = useState([]);
  const [errorAdd, setErrorAdd] = useState(true);
  const [message, setMessage] = useState("");

  // -------- Buat jalanin Get All ------- //
  useEffect(() => {

    // Check Local Storage
    // const user = JSON.parse(localStorage.getItem("user"));
    const user = JSON.parse(sessionStorage.getItem("user"));
    if (user) {
      setDataUser(user.results);

      // Langsung Run Function
      setNama(crypto.aesDecryptIdentitas(user.results.nama));
      getAllEventInterview(user.results.api_key);
      defaultGetAllEventPeserta(user.results.api_key);

    } else {
      window.location.href = "/login-admin";
    }
  }, []);

  // -------- Get All Event Interview -------- //
  const getAllEventInterview = (api_key) => {

    const config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/eventlist`,
      headers: {
        'x-api-key': api_key
      }
    };

    axios(config)
      .then(function (response) {
        const data = response.data;
        const bln = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];

        data.forEach((e) => {
          let a = new Date(e.tanggal_Mulai);
          let b = new Date(e.tanggal_Berakhir);
          for (let i = 0; i < bln.length; i++) {
            if (i === a.getMonth()) {
              e.bulanMulaiName = bln[i];
            }
            if (i === b.getMonth()) {
              e.bulanAkhirName = bln[i];
            }
          }
          e.bulan = a.getMonth() + 1;
          e.bulanAkhir = b.getMonth() + 1;
          e.tahun = a.getFullYear();
          e.tahunAkhir = b.getFullYear();
          e.tanggal = a.getDate();
          e.tanggalAkhir = b.getDate();

          setEventTanggal([{ title: e.judul_Interview, start: e.tanggal_Mulai, end: e.tanggal_Berakhir }]);
        });

        setEventList(JSON.parse(JSON.stringify(data)));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // -------- Get Number Count ------- //
  const countPelamar = (api_key, id_event) => {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/pesertaJumlah/${id_event}`,
      headers: {
        'x-api-key': api_key
      }
    };

    axios(config)
      .then(function (response) {
        setJumlahPelamar(JSON.parse(JSON.stringify(response.data.count)));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const countFeedback = (api_key, id_event) => {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/jlmhFeedback/${id_event}`,
      headers: {
        'x-api-key': api_key
      }
    };

    axios(config)
      .then(function (response) {
        setJumlahFeedback(JSON.parse(JSON.stringify(response.data.count)));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const countInterview = (api_key, id_event) => {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/interviewJumlah/${id_event}`,
      headers: {
        'x-api-key': api_key
      }
    };

    axios(config)
      .then(function (response) {
        setJumlahSubmit(JSON.parse(JSON.stringify(response.data.count)));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // --------- Lihat Selengkapnya ---------- //
  const history = useHistory();
  const lihatSelengkapJumlahPelamar = () => {
    checkJWT();
    history.push("/onProcess");
  };
  const lihatSelengkapJumlahSubmit = () => {
    checkJWT();
    history.push("/previewResult");
  };
  const lihatSelengkapJumlahFeedback = () => {
    checkJWT();
    history.push("/i2");
    sessionStorage.setItem("dataInterview", ambilIdEvent)
  };

  // ---------- GET ALL EVENT PELAMAR ------------- //
  const defaultGetAllEventPeserta = (api_key) => {
    countPelamar(api_key, ambilIdEvent);
    countFeedback(api_key, ambilIdEvent);
    countInterview(api_key, ambilIdEvent);

    const config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/peserta/${ambilIdEvent}`,
      headers: {
        'x-api-key': api_key
      }
    };

    axios(config)
      .then(function (response) {
        setPelamar(JSON.parse(JSON.stringify(response.data)));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const changeGetAllEventPeserta = (api_key, id_event) => {
    countPelamar(api_key, id_event);
    countFeedback(api_key, id_event);
    countInterview(api_key, id_event);

    const config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/peserta/${id_event}`,
      headers: {
        'x-api-key': api_key
      }
    };

    axios(config)
      .then(function (response) {
        setPelamar(JSON.parse(JSON.stringify(response.data)));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const selectDataInterview = (id_event) => {
    checkJWT();

    setAmbilIdEvent(id_event);
    sessionStorage.setItem("pilihEvent", id_event);

    changeGetAllEventPeserta(dataUser.api_key, id_event);
  }

  // ---------- GET PELAMAR BY ID ------------- //
  function getPesertaByIdDetail(id) {

    const config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/pesertaEvent/${id}/${ambilIdEvent}`,
      headers: {
        'x-api-key': dataUser.token
      },
    };

    axios(config)
      .then(function (response) {
        const a = response.data;
        const data = a[0];

        setNamato(data.nama);
        setEmailto(data.email);
        setNoHPto(data.no_HP);
        setAlamatto(data.alamat);
        const date = new Date(data.tanggal_Lahir);
        const tgl =
          date.getFullYear() +
          "-" +
          (date.getMonth() + 1 > 9
            ? date.getMonth() + 1
            : "0" + (date.getMonth() + 1)) +
          "-" +
          (date.getDate() > 9 ? date.getDate() : "0" + date.getDate());
        if (data.tanggal_Lahir !== null) {
          setTanggalLahirto(tgl);
        } else {
          setTanggalLahirto("---")
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }

  // ---- MODAL PELAMAR BY ID ---- //
  const [modalDetailPelamar, setModalDetailPelamar] = useState(false);
  const openModalDetailPelamar = () => {
    setModalDetailPelamar(!modalDetailPelamar);
  };

  // -------- AGENDA --------- //
  const [select, setSelect] = useState();
  const [tanggalMulai, setTanggalMulai] = useState("");
  const [errorTanggalMulai, setErrorTanggalMulai] = useState("");
  const [tanggalBerakhir, setTanggalBerakhir] = useState("");
  const [errorTanggalBerakhir, setErrorTanggalBerakhir] = useState("");
  const [errorTanggal, setErrorTanggal] = useState(true);

  const changeTanggalMulai = (event) => {
    const value = event.target.value;
    setTanggalMulai(value);
    if (!value) {
      setErrorTanggalMulai("Maaf, Tanggal Mulai tidak boleh kosong!");
    } else {
      setErrorTanggalMulai("");
    }
  };
  const changeTanggalBerakhir = (event) => {
    const value = event.target.value;
    setTanggalBerakhir(value);
    if (!value) {
      setErrorTanggalBerakhir("Maaf, Tanggal Berakhir tidak boleh kosong!");
    } else {
      setErrorTanggalBerakhir("");
    }
  };

  const getDate = () => {
    const date = new Date();
    const bulan = date.getMonth();
    const tahun = date.getFullYear();
    setMonth(bulan + 1);
    setYear(tahun);
  };

  const selectEvent = () => {
    setErrorAdd(true);
    setErrorTanggal(true);

    const data = JSON.stringify({
      from: tanggalMulai,
      to: tanggalBerakhir,
    });

    const date1 = new Date(tanggalMulai);
    const date2 = new Date(tanggalBerakhir);

    if (date2 < date1) {
      setErrorTanggal(false);
      setEventOnDate([]);
      setTimeout(() => {
        setErrorTanggal(true);
      }, 5000);
    } else {
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/eventOnDate`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          const data = response.data;
          const bln = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];

          data.forEach((e) => {
            let a = new Date(e.tanggal_Mulai);
            let b = new Date(e.tanggal_Berakhir);
            for (let i = 0; i < bln.length; i++) {
              if (i === a.getMonth()) {
                e.bulanMulaiName = bln[i];
              }
              if (i === b.getMonth()) {
                e.bulanAkhirName = bln[i];
              }
            }
            e.bulan = a.getMonth() + 1;
            e.tahun = a.getFullYear();
            e.tahunAkhir = b.getFullYear();
            e.tanggal = a.getDate();
            e.tanggalAkhir = b.getDate();
          });
          if (data.length === 0) {
            setMessage(" ------------------------- Tidak Ada Agenda -------------------------- ")
          }
          setEventOnDate(data);
        })
        .catch(function (error) {
          setErrorAdd(false);
          console.log(error);
          setTimeout(() => {
            setErrorAdd(true);
          }, 5000);
        });
    }
  };

  // ---- MODAL AGENDA ---- //
  const [modalAgenda, setModalAgenda] = useState(false);
  const openModalAgenda = () => {
    setModalAgenda(!modalAgenda);
  };

  // --------- MODAL JWT ---------- //
  const [modalJWT, setModalJWT] = useState(false);
  // ----------- CHECK JWT --------- //
  const checkJWT = () => {

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/checkJwt`,
      headers: {
        "x-api-key": dataUser.token,
      },
    };

    axios(config)
      .then(function (response) {
        if (response.status === 403) {
          setModalJWT(true);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  function openModalJWT() {
    setModalJWT(!modalJWT);
  }

  return (
    <React.Fragment>
      <div className="page-content" style={{ fontFamily: 'Nunito' }}>

        {/* -------- SAMBUTAN -------- */}
        <div>
          <h1 style={{ color: "#2A4365" }}>Selamat Datang, {nama}</h1>
        </div>

        {/* -------- DROP DOWN -------- */}
        <div style={{ width: "320px", marginTop: "20px" }}>
          <p style={{ marginBottom: "5px", fontSize: "18px", fontWeight: "700", color: "#4A5568" }}>
            Pilih Data Interview
          </p>
          <select style={{ fontSize: '14px' }} className="form-select mb-3" aria-label="Default select example" value={ambilIdEvent}
            onChange={(event) => {
              // setAmbilIdEvent(event.target.value);
              selectDataInterview(event.target.value)
            }}
          // onClick={() => {
          //   checkJWT();
          //   getAllEventPeserta(dataUser.api_key);
          // }}
          >
            <option value="" selected="selected" hidden="hidden">
              Choose here
            </option>
            {eventList.map((item, index) => (
              <>
                <option key={index} value={item.id}>
                  {item.judul_Interview}
                </option>
              </>
            ))}
          </select>
        </div>

        {/* -------- MENU LIST -------- */}
        <Row style={{ marginTop: "20px", marginBottom: "30px", marginLeft: "auto", marginRight: "auto", display: "flex", flexDirection: "row", justifyContent: "space-between", rowGap: '30px' }}>

          {/*  JUMLAH PELAMAR  */}
          <Col xs="3" className="kartu" style={{ borderRadius: "10px", padding: "20px 30px", width: "320px", boxShadow: "0px 2px 5px #0000001a, 0px 0px 2px #00000033" }}>
            <p style={{ fontSize: "18px", fontWeight: "400", color: "#718096" }}>
              Jumlah Pelamar
            </p>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <h1 style={{ margin: "0", color: "#2A4365", fontSize: "40px" }}>
                {jumlahPelamar}
              </h1>
              <div>
                <img src={imgPelamar} alt="imgPelamar" />
              </div>
            </div>
            <hr />
            <span style={{ fontSize: "16px", fontWeight: "700", color: "#319795", cursor: "pointer" }} onClick={lihatSelengkapJumlahPelamar}>
              Lihat selengkapnya ...
            </span>
          </Col>

          {/*  TELAH SUBMIT INTERVIEW  */}
          <Col xs="3" className="kartu" style={{ borderRadius: "10px", padding: "20px 30px", width: "320px", boxShadow: "0px 2px 5px #0000001a, 0px 0px 2px #00000033" }}>
            <p style={{ fontSize: "18px", fontWeight: "400", color: "#718096" }}>
              Telah submit Interview
            </p>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <h1 style={{ margin: "0", color: "#2A4365", fontSize: "40px" }}>
                {jumlahSubmit}
              </h1>
              <div>
                <img src={imgSubmit} alt="imgSubmit" />
              </div>
            </div>
            <hr />
            <span style={{ fontSize: "16px", fontWeight: "700", color: "#319795", cursor: "pointer" }} onClick={lihatSelengkapJumlahSubmit}>
              Lihat selengkapnya ...
            </span>
          </Col>

          {/*  JUMLAH FEEDBACK  */}
          <Col xs="3" className="kartu" style={{ borderRadius: "10px", padding: "20px 30px", width: "320px", boxShadow: "0px 2px 5px #0000001a, 0px 0px 2px #00000033" }}>
            <p style={{ fontSize: "18px", fontWeight: "400", color: "#718096" }}>
              Jumlah Feedback
            </p>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <h1 style={{ margin: "0", color: "#2A4365", fontSize: "40px" }}>
                {jumlahFeedback}
              </h1>
              <div>
                <img src={imgFeedback} alt="imgFeedback" />
              </div>
            </div>
            <hr />
            <span style={{ fontSize: "16px", fontWeight: "700", color: "#319795", cursor: "pointer" }} onClick={lihatSelengkapJumlahFeedback}>
              Lihat selengkapnya ...
            </span>
          </Col>
        </Row>

        {/* -------- RIWAYAT PELAMAR & AGENDA -------- */}
        <Row style={{rowGap: '10px'}}>

          {/* RIWAYAT PELAMAR  */}
          <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={12}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p style={{ fontWeight: "600", fontSize: "22px", margin: "0", color: "#2A4365" }}>
                Riwayat Pelamar
              </p>
            </div>
            <div className="table-responsive">
              {pelamar.length !== 0 ? (
                <>
                  <Table style={{ fontSize: '14px' }} className="align-middle table-nowrap mb-0">
                    <thead style={{ backgroundColor: "#FED7D7", color: "#E53E3E" }}>
                      <tr>
                        <th scope="col">No</th>
                        <th scope="col">Nama</th>
                        <th scope="col">Kode</th>
                        <th scope="col">Tanggal Lahir</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pelamar.map((item, index) => (
                        <tr key={index}>
                          <th scope="row">{1 + index}</th>
                          <td>{crypto.aesDecryptIdentitas(item.nama)}</td>
                          <td>{item.kode}</td>
                          <td>
                            {item.tanggal_Lahir === null ?
                              "Tidak tersedia"
                              :
                              new Date(item.tanggal_Lahir).getFullYear() + "-"
                              + (new Date(item.tanggal_Lahir).getMonth() + 1 > 9 ?
                                new Date(item.tanggal_Lahir).getMonth() + 1
                                : "0" + (new Date(item.tanggal_Lahir).getMonth() + 1)) + "-"
                              + (new Date(item.tanggal_Lahir).getDate() > 9 ?
                                new Date(item.tanggal_Lahir).getDate()
                                : "0" + new Date(item.tanggal_Lahir).getDate())
                            }
                          </td>
                          <td>
                            <span className="badge bg-info" style={{ fontSize: "14px", cursor: "pointer" }}
                              onClick={() => {
                                getPesertaByIdDetail(item.id);
                                openModalDetailPelamar();
                                checkJWT();
                              }}
                            >
                              Lihat
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </>
              ) : (
                <>
                  <div style={{ textAlign: "center", border: "1px solid #FC8181", padding: "40px 10px", borderRadius: "10px", fontSize: "18px", fontWeight: "700", color: "#2A4365" }}>
                    Not Found
                  </div>
                </>
              )}
            </div>
          </Col>

          {/* AGENDA */}
          <Col xxl={4} xl={4} lg={4} md={12} sm={12} xs={12}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p style={{ fontWeight: "600", fontSize: "22px", margin: "0", color: "#2A4365" }}>
                Agenda
              </p>
              <Button
                style={{ padding: "0.5rem", width: "200px", height: "40px", fontWeight: "700", backgroundColor: "white", color: "#319795", border: "none", fontSize: '14px', textAlign: 'end' }}
                onClick={() => {
                  openModalAgenda();
                  getDate();
                  checkJWT();
                }}
              >
                Lihat selengkapnya →
              </Button>
            </div>
            <FullCalendar
              style={{ border: "1px solid #FC8181", borderRadius: "10px" }}
              plugins={[BootstrapTheme, dayGridPlugin, interactionPlugin]}
              themeSystem="bootstrap"
              headerToolbar={{
                left: "prev,next",
                center: "title",
                right: "",
              }}
            />
          </Col>
        </Row>
      </div>

      {/* - - - - MODAL DETAIL PELAMAR - - - - - */}
      <Modal size="sm" isOpen={modalDetailPelamar} toggle={() => { openModalDetailPelamar() }} centered>
        <ModalHeader className="modal-title" id="myModalLabel" toggle={() => { openModalDetailPelamar() }}>
          <p style={{ margin: "0px", color: "#2A4365", fontSize: "20px", fontWeight: "700", fontFamily: 'Nunito' }}>
            Detail Pelamar
          </p>
        </ModalHeader>

        <ModalBody style={{ fontFamily: 'Nunito' }}>
          <p style={{ fontWeight: "700", color: "#4A5568", fontSize: "16px", margin: "0" }}>
            Nama
          </p>
          <p style={{ fontWeight: "500", color: "#718096", fontSize: '14px' }}>{crypto.aesDecryptIdentitas(namato)}</p>

          <p style={{ fontWeight: "700", color: "#4A5568", fontSize: "16px", margin: "0" }}>
            Email
          </p>
          <p style={{ fontWeight: "500", color: "#718096", fontSize: '14px' }}>{crypto.aesDecryptIdentitas(emailto)}</p>

          <p style={{ fontWeight: "700", color: "#4A5568", fontSize: "16px", margin: "0" }}>
            No HP
          </p>
          {noHPto === "" ?
            <p style={{ fontWeight: "500", color: "#718096" }}>---</p>
            :
            <p style={{ fontWeight: "500", color: "#718096", fontSize: '14px' }}>{crypto.aesDecryptIdentitas(noHPto)}</p>
          }

          <p style={{ fontWeight: "700", color: "#4A5568", fontSize: "16px", margin: "0" }}>
            Alamat
          </p>
          {alamatto === "" ?
            <p style={{ fontWeight: "500", color: "#718096" }}>---</p>
            :
            <p style={{ fontWeight: "500", color: "#718096", fontSize: '14px' }}>{crypto.aesDecryptIdentitas(alamatto)}</p>
          }

          <p style={{ fontWeight: "700", color: "#4A5568", fontSize: "16px", margin: "0" }}>
            Tanggal Lahir
          </p>
          {tanggalLahirto === "" ?
            <p style={{ fontWeight: "500", color: "#718096" }}>---</p>
            :
            <p style={{ fontWeight: "500", color: "#718096", fontSize: '14px' }}>{tanggalLahirto}</p>
          }
        </ModalBody>
      </Modal>

      {/* - - - - MODAL DETAIL AGENDA - - - - - */}
      <Modal size="md" isOpen={modalAgenda} toggle={() => { openModalAgenda() }} centered>
        <ModalHeader className="modal-title" id="myModalLabel" toggle={() => { openModalAgenda() }}>
          <p style={{ margin: "0px", color: "#2A4365", fontSize: "20px", fontWeight: "700", fontFamily: 'Nunito' }}>
            Agenda
          </p>
        </ModalHeader>

        <ModalBody style={{ display: "flex", flexDirection: "column", alignItems: "center", overflow: "auto", height: "380px", fontFamily: 'Nunito' }}>

          {/* ALERT */}
          <Alert hidden={errorAdd} color="warning" style={{ textAlign: "center", backgroundColor: "#FFF3B5", color: "#D88E00", borderColor: "#FFF3B5", margin: "10px 0px", transition: "0.5s" }}>
            Something wrong, please check again
          </Alert>
          <Alert hidden={errorTanggal} color="warning" style={{ textAlign: "center", backgroundColor: "#FFF3B5", color: "#D88E00", borderColor: "#FFF3B5", margin: "10px 0px", transition: "0.5s" }}>
            Tanggal Berakhir tidak boleh kurang dari Tanggal Mulai
          </Alert>


          <Row style={{ alignItems: "center", width: "100%" }}>
            <div>
              <select className="form-select" data-choices aria-label="Default select example" required id="fruits" value={select} onChange={(e) => setSelect(e.target.value)}>
                <option value="" >
                  Pilih Rentang Waktu
                </option>
                <option value="Month">Bulan Ini</option>
                <option value="Year">Tahun Ini</option>
                <option value="Time">Pilih Rentang Waktu</option>
              </select>
            </div>

            {select === "Time" ? (
              <>
                <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6} style={{ marginBottom: "15px", marginTop: "25px" }}>
                  <div>
                    <Label htmlFor="mulai" className="form-label">
                      Dari Tanggal
                    </Label>
                    <Input className="form-control" type="date" value={tanggalMulai} onChange={changeTanggalMulai} />
                    {errorTanggalMulai && (
                      <p style={{ margin: "0", color: "#F56565", fontSize: "10px", position: "absolute" }}>
                        {errorTanggalMulai}
                      </p>
                    )}
                  </div>
                </Col>

                <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6} style={{ marginBottom: "15px", marginTop: "25px" }}>
                  <div>
                    <Label htmlFor="berakhir" className="form-label">
                      Sampai Tanggal
                    </Label>
                    <Input type="date" value={tanggalBerakhir} onChange={changeTanggalBerakhir} className="form-control" />
                    {errorTanggalBerakhir && (
                      <p style={{ margin: "0", color: "#F56565", fontSize: "10px", position: "absolute" }}>
                        {errorTanggalBerakhir}
                      </p>
                    )}
                  </div>
                </Col>
                <Col>
                  <div>
                    <div style={{ textAlign: "center" }}>
                      <Button className=" w-100 btn btn-success" color="success" centered onClick={() => { selectEvent() }}>
                        Pilih
                      </Button>
                    </div>
                    <div style={{ marginLeft: "5px" }}>
                      {eventOnDate.length !== 0 ? (
                        <>
                          <hr />
                          {eventOnDate.map((item) => (
                            <>
                              <div style={{ marginTop: "5px" }}>
                                <p>{item.judul_Interview}</p>
                                <p className="text-muted">
                                  {item.tanggal} {item.bulanMulaiName}{" "}
                                  {item.tahun} - {item.tanggalAkhir}{" "}
                                  {item.bulanAkhirName} {item.tahunAkhir}
                                </p>
                              </div>
                            </>
                          ))}
                        </>
                      ) : (
                        <div style={{ marginTop: "5px" }}>

                          <hr />
                          <br />
                          <br />


                          <div style={{ marginTop: "10px" }}>
                            <p className="text-muted"> {message} </p>

                          </div>

                        </div>)}
                    </div>
                  </div>
                </Col>
              </>
            ) : (
              <div style={{ marginLeft: "5px" }}>
                <hr />

                {eventList.map((item) => (
                  <>
                    {select === "Month" && (item.bulan === month || (item.bulanAkhir >= month && item.bulan <= month)) && (
                      <div style={{ marginTop: "5px" }}>
                        <p>{item.judul_Interview}</p>
                        <p className="text-muted">
                          {item.tanggal} {item.bulanMulaiName} {item.tahun} -{" "}
                          {item.tanggalAkhir} {item.bulanAkhirName}{" "}
                          {item.tahunAkhir}
                        </p>
                      </div>
                    )}

                    {select === "Year" && (item.tahun === year || (item.tahunAkhir >= year && item.tahun <= year)) && (
                      <>
                        <p>{item.judul_Interview}</p>
                        <p className="text-muted">
                          {item.tanggal} {item.bulanMulaiName} {item.tahun} -{" "}
                          {item.tanggalAkhir} {item.bulanAkhirName}{" "}
                          {item.tahunAkhir}
                        </p>
                      </>
                    )}
                  </>
                ))}
              </div>
            )}
          </Row>
        </ModalBody>
      </Modal>

      {/* - - - - MODAL JWT - - - - */}
      <Modal isOpen={modalJWT} centered>
        <ModalBody style={{ display: "flex", flexDirection: "column", alignItems: "center", fontFamily: 'Nunito' }}>
          <img src={Timeout} alt="Timeout" />
          <h5 style={{ textAlign: "center", marginTop: "30px", fontSize: "24px", fontWeight: "700", color: "#2A4365" }}>
            Maaf, Silahkan Login Kembali
          </h5>
          <p style={{ padding: "0 45px", textAlign: "center", fontSize: "16px", color: "#4A5568", fontWeight: "400", fontFamily: 'Nunito, Nunito' }}>
            Sesi Anda sudah kadaluarsa, Jangan khawatir... Click tombol di bawah
            ini untuk login kembali :)
          </p>
        </ModalBody>
        <ModalFooter style={{ justifyContent: "center" }}>
          <Button style={{ padding: "0.5rem 3.0rem", fontSize: '14px' }} className="btn btn-success" onClick={() => { window.localStorage.clear(); window.sessionStorage.clear(); window.location.reload(); window.location.href = "/login-admin" }}>
            Login
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default I1;
