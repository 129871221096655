import React from "react";
import { Col, Container, Row } from "reactstrap";
import no1 from "../../assets/images/telkom/no1.png";
import no2 from "../../assets/images/telkom/no2.png";
import no3 from "../../assets/images/telkom/no3.png";
import no4 from "../../assets/images/telkom/no4.png";
import no5 from "../../assets/images/telkom/no5.png";
import no6 from "../../assets/images/telkom/no6.png";


const P2 = () => {
  document.title="Dashboard | Velzon - React Admin & Dashboard Template";
  return (
    <React.Fragment>

    </React.Fragment>
  );
};

export default P2;
