import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { Alert, Card, Col, Container, Input, Label, Row ,Button} from 'reactstrap';
import AuthSlider from '../authCarousel';
import axios from 'axios';

import logoTelkom from "../../../assets/images/telkom/logoACI.png";
import Welcome from "../../../assets/images/telkom/welcomeNew.png";

const CoverSignUpterview = () => {
    document.title="Register Admin";

    // ---- Input Data Login ---- //
    const [nama, setNama] = useState('');
    const [errorNama, setErrorNama] = useState('');
    const [email, setEmail] = useState('');
    const [errorEmail, setErrorEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorPassword, setErrorPassword] = useState('');
    const [confPassword, setConfPassword] = useState('');
    const [special, setSpecial] = useState('');
    const [errorConfPassword, setErrorConfPassword] = useState('');

    // ---- Alert Error ---- //
    const [errorSubmit, setErrorSubmit] = useState(true);
    const [errorEmailSama, setErrorEmailSama] = useState(true);
    const [errorPasswordSama, setErrorPasswordSama] = useState(true);

    // -- Tombol Show Password -- //
    const [showPass, setShowPass] = useState(false);
    const [showConfPass, setShowConfPass] = useState(false);

    // ---- onChange Input Data ---- //
    const changeNama = (event) => {
        const value = event.target.value
        setNama(value)
        if(!value) {
            setErrorNama('Maaf, nama tidak boleh kosong!')
        }else{
            setErrorNama('')
        }
    }
    const changeEmail = (event) => {
        const value = event.target.value
        setEmail(value)
        if(!value) {
            setErrorEmail('Maaf, email tidak boleh kosong!')
        }else{
            setErrorEmail('')
        }
    }
    const changePassword= (event) => {
        const value = event.target.value
        setPassword(value)
        if(!value) {
            setErrorPassword('Maaf, password tidak boleh kosong!')
        }else{
            setErrorPassword('')
        }
    }
    const changeConfPassword = (event) => {
        const value = event.target.value
        setConfPassword(value)
        if(!value) {
            setErrorConfPassword('Maaf, password tidak boleh kosong!')
        } else if (password !== value) {
            setErrorConfPassword('Password tidak cocok')
        }
        else{
            setErrorConfPassword('')
        }
    }
    const changeSpecial= (event) => {
        const value = event.target.value
        setSpecial(value)
    }

    // -- Tombol Show Password -- //
    const shower = () => {
        setShowPass(!showPass);
    }
    const showerConf = () => {
        setShowConfPass(!showConfPass);
    }

    // -- Fungsi Register-- //
    const registerButton = () => {
        setErrorEmailSama(true)

        const data = JSON.stringify({
            nama: nama,
            email: email,
            password: password,
            confPassword: confPassword,
            passwordAdmin: special,
        });

        const config = {
            method: 'post',
            url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/register`,
            headers: {
                'Content-Type': 'application/json',
            },
            data: data,
        };

        axios(config).then((response) => {
            const statusCode = response.status;

            if(statusCode === 200 ) {
                window.location.href = "/login-admin"
            }
            if(statusCode === 403 ) {
                setErrorEmailSama(false)
                setTimeout(() => {
                    setErrorEmailSama(true)
                }, 5000)
            }
            if(statusCode === 401 ) {
                setErrorPasswordSama(false)
                setTimeout(() => {
                    setErrorPasswordSama(true)
                }, 5000)
            }
        }).catch((error) => {
            console.log(error)
            setErrorSubmit(false);
            setTimeout(() => {
                setErrorSubmit(true)
            }, 5000)
        })
    }

    return (
        <React.Fragment>

        <Row style={{margin: '0px', height: '100vh', alignItems: 'center', fontFamily: 'Nunito'}}>

            {/* Ini Welcome! Lets Get Started */}
            <Col md={12} lg={6} xl={6} style={{height: '100%', padding: '0px'}}>
                <img src={Welcome} style={{height: '-webkit-fill-available'}}/>
            </Col>

            <Col md={12} lg={6} xl={6} style={{padding: '0px', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <div style={{display: 'flex', justifyContent: 'center', marginRight: '50px'}}>
                    <img src={logoTelkom} />
                </div>

                <div style={{marginTop: '50px', padding: '25px', boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.08)', borderRadius: '8px', width: '65%', marginRight: '50px'}}>
                                                    
                    {/* ---- HEADER Daftar ---- */}
                    <p style={{fontWeight: '700', fontSize: '30px', color: '#F56565', textAlign: 'center', margin: '0'}}>Daftar</p>
                    <Alert hidden={errorEmailSama} color="warning" style={{ textAlign: 'center', backgroundColor: '#FFF3B5', color: '#D88E00', borderColor: '#FFF3B5', margin: '10px 0px', transition: '0.5s' }}>
                        Email sudah di gunakan
                    </Alert>
                    <Alert hidden={errorSubmit} color="warning" style={{ textAlign: 'center', backgroundColor: '#FFF3B5', color: '#D88E00', borderColor: '#FFF3B5', margin: '10px 0px', transition: '0.5s' }}>
                        Something wrong, please check again
                    </Alert>
                    <Alert hidden={errorPasswordSama} color="warning" style={{ textAlign: 'center', backgroundColor: '#FFF3B5', color: '#D88E00', borderColor: '#FFF3B5', margin: '10px 0px', transition: '0.5s' }}>
                        Password harus sama Confirm Password
                    </Alert>

                    {/* ---- Masukin Data Nama ---- */}
                    <div className="mb-3">
                        <Label htmlFor="nama" className="form-label">Nama</Label>
                        <Input value={nama} onChange={changeNama} type="text" className="form-control" id="nama" placeholder="Ketik nama Anda" required minLength={2} maxLength={100} />
                        {errorNama && (
                            <p style={{margin: '0', color: '#F56565', fontSize: '10px', position: 'absolute'}}>{errorNama}</p>
                        )}
                    </div>

                    {/* ---- Masukin Data Email ---- */}
                    <div className="mb-3">
                        <Label htmlFor="email" className="form-label">Email</Label>
                        <Input value={email} onChange={changeEmail} type="text" className="form-control" id="email" placeholder="Ketik email Anda" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" title="contoh@bebas.com" required maxLength={60}/>
                        {errorEmail && (
                            <p style={{margin: '0', color: '#F56565', fontSize: '10px', position: 'absolute'}}>{errorEmail}</p>
                        )}
                    </div>

                    {/* ---- Masukin Data Password ---- */}
                    <div className="mb-3">
                        <Label className="form-label" htmlFor="password-input">Password</Label>
                            <div className="position-relative auth-pass-inputgroup">
                                <Input value={password} onChange={changePassword} type={showPass ? "text" : "password"} className="form-control pe-5" placeholder="Masukan password" id="password-input" pattern='^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,24}$' title="Password 8-24 character, setidaknya ada satu huruf kecil, huruf besar, angka dan simbol" required minLength={8} maxLength={24}/>
                                <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" onClick={shower} type="button" id="password-addon"><i className="ri-eye-fill align-middle"></i></button>
                            </div>
                            <p style={{margin: '0', color: '#ED8936', fontSize: '10px'}}>*Kata sandi minimal 8 karakter harus terdiri atas huruf kapital, angka, dan simbol.</p>
                            {errorPassword && (
                                <p style={{margin: '0', color: '#F56565', fontSize: '10px', position: 'absolute'}}>{errorPassword}</p>
                            )}
                    </div>

                    {/* ---- Masukin Data ConfPassword ---- */}
                    <div className="mb-3">
                        <Label className="form-label" htmlFor="password-conf">Confirm Password</Label>
                            <div className="position-relative auth-pass-inputgroup">
                                <Input value={confPassword} onChange={changeConfPassword} type={showConfPass ? "text" : "password"} className="form-control pe-5" placeholder="Masukan Password kembali" id="password-conf" required minLength={8} maxLength={24}/>
                                <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" onClick={showerConf} type="button" id="password-addon"><i className="ri-eye-fill align-middle"></i></button>
                            </div>
                            {errorConfPassword && (
                                <p style={{margin: '0', color: '#F56565', fontSize: '10px', position: 'absolute'}}>{errorConfPassword}</p>
                            )}
                    </div>

                    {/* ---- Masukin Data ConfPassword ---- */}
                    <div className="mb-3">
                        <Label className="form-label" htmlFor="password-conf">Special Unique Code</Label>
                            <div className="position-relative auth-pass-inputgroup">
                                <Input value={special} onChange={changeSpecial} type={"password"} className="form-control pe-5" placeholder="Masukan Unique Code" required minLength={8} maxLength={24}/>
                            </div>
                    </div>
                                                        
                    {/* ---- Daftar ---- */}
                    <div className="">
                        <Button type="submit" color="success" className="w-100"
                        onClick={registerButton} >Daftar</Button>
                    </div>

                    <p style={{marginTop:'10px', marginBottom: '10px'}}>Sudah memiliki akun? <span style={{fontWeight: '700', color: '#F56565', cursor: 'pointer'}} onClick={() => {window.location.href = '/login-admin'}}> Masuk</span></p>

                </div>
            </Col>
        </Row>
        </React.Fragment>
    );
};

export default CoverSignUpterview;