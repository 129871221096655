import React from 'react';
import {  Button } from 'reactstrap';
import crypto from '../../pages/Setting/crypto';


const Header = ({ onChangeLayoutMode, layoutModeType, headerClass }) => {

    const auth = JSON.parse(sessionStorage.getItem('user')).results
    const nama = crypto.aesDecryptIdentitas(auth.nama)
    
    const logout = () => {
        window.localStorage.clear();
        window.sessionStorage.clear();
        window.location.reload();
        window.location.href = "/login-admin";
    }

    const toogleMenuBtn = () => {
        var windowSize = document.documentElement.clientWidth;

        if (windowSize > 767)
            document.querySelector(".hamburger-icon").classList.toggle('open');

        //For collapse horizontal menu
        if (document.documentElement.getAttribute('data-layout') === "horizontal") {
            document.body.classList.contains("menu") ? document.body.classList.remove("menu") : document.body.classList.add("menu");
        }

        //For collapse vertical menu
        if (document.documentElement.getAttribute('data-layout') === "vertical") {
            if (windowSize < 1025 && windowSize > 767) {
                document.body.classList.remove('vertical-sidebar-enable');
                (document.documentElement.getAttribute('data-sidebar-size') === 'sm') ? document.documentElement.setAttribute('data-sidebar-size', '') : document.documentElement.setAttribute('data-sidebar-size', 'sm');
            } else if (windowSize > 1025) {
                document.body.classList.remove('vertical-sidebar-enable');
                (document.documentElement.getAttribute('data-sidebar-size') === 'lg') ? document.documentElement.setAttribute('data-sidebar-size', 'sm') : document.documentElement.setAttribute('data-sidebar-size', 'lg');
            } else if (windowSize <= 767) {
                document.body.classList.add('vertical-sidebar-enable');
                document.documentElement.setAttribute('data-sidebar-size', 'lg');
            }
        }

        //Two column menu
        if (document.documentElement.getAttribute('data-layout') === "twocolumn") {
            document.body.classList.contains('twocolumn-panel') ? document.body.classList.remove('twocolumn-panel') : document.body.classList.add('twocolumn-panel');
        }
    };


    return (
        <React.Fragment>
            <header style={{backgroundColor: '#ffffff'}} id="page-topbar" className={headerClass}>
                <div className="layout-width" style={{ boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)' }}>
                    <div className="navbar-header">
                        <div className="d-flex">

                            <button
                                onClick={toogleMenuBtn}
                                type="button"
                                className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
                                id="topnav-hamburger-icon">
                                <span className="hamburger-icon">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </span>
                            </button>

                        </div>
                        
                        <div style={{display: 'flex', gap: '40px', alignItems: 'center'}}>
                            <p style={{margin: 0}}>{nama}</p>
                            <Button onClick={logout} style={{backgroundColor: '#fed7d7', color: '#e53e3e', border:'none'}}>Log Out</Button>
                        </div>


                    </div>
                </div>
            </header>
        </React.Fragment>
    );
};

export default Header;