import React, {useState, useRef, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { Alert, Button, Card, Col, Container, Row, Input, Label } from 'reactstrap';
import AuthSlider from '../authCarousel';
import axios from 'axios';
import ReactInputVerificationCode from 'react-input-verification-code';
import { useInterval } from "usehooks-ts";

import logoTelkom from "../../../assets/images/telkom/logoACI.png";
import Welcome from "../../../assets/images/telkom/lupa.png";

const CoverPasswReset = () => {
    document.title="Forgot Password";

    // ------- Input Email --------- //
    const [email, setEmail] = useState('');
    const [otp, setOTP] = useState('');
    const [apiKey, setApiKey] = useState('');
    const [password, setPassword] = useState('');
    const [confPassword, setConfPassword] = useState('');
    const [errorEmail, setErrorEmail] = useState(true);
    const [successEmail, setSuccessEmail] = useState(true);
    const [errorOTP, setErrorOTP] = useState(true);
    const [errorPassword, setErrorPassword] = useState(true);
    const [errorKontenPassword, setErrorKontenPassword] = useState('');

    // -- Tombol Show Password -- //
    const [showPass, setShowPass] = useState(false);
    const [showConfPass, setShowConfPass] = useState(false);

    // ---------- ON NEXT --------- //
    const [step, setStep] = useState(0);
    const onChange = (nextStep) => {
        setStep(nextStep < 0 ? 0 : nextStep > 4 ? 4 : nextStep);
    };
    const onNext = () => onChange(step + 1);
    const onPrevious = () => onChange(step - 1);

    // -- Tombol Show Password -- //
    const shower = () => {
        setShowPass(!showPass);
    }
    const showerConf = () => {
        setShowConfPass(!showConfPass);
    }

    // -------- Imput Email --------- //
    const changeEmail = (event) => {
        const value = event.target.value
        setEmail(value)
        // if(!value) {
        //     setErrorEmail('Maaf, email tidak boleh kosong!')
        // }else{
        //     setErrorEmail('')
        // }
    }

    const changePassword = (event) => {
        const value = event.target.value
        setPassword(value)
    }
    const changeConfPassword = (event) => {
        const value = event.target.value
        setConfPassword(value)
    }

    const sendEmail = () => {
        setErrorEmail(true)
        const data = JSON.stringify({"email": email});

        const config = {
        method: 'post',
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/sendOtp`,
        headers: { 
            'Content-Type': 'application/json'
        },
        data : data
        };

        axios(config)
        .then(function (response) {
        if(response.status === 200){
            onNext();
            setPejet(true)
        }
        })
        .catch(function (error) {
            setErrorEmail(false)
            setTimeout(() => {
                setErrorEmail(true)
            }, 4000)
        });
    }

    const resendEmail = () => {
        setSuccessEmail(true)
        setErrorEmail(true)
        const data = JSON.stringify({
        "email": email
        });

        const config = {
        method: 'post',
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/sendOtp`,
        headers: { 
            'Content-Type': 'application/json'
        },
        data : data
        };

        axios(config)
        .then(function (response) {
        if(response.status === 403){
            setErrorEmail(false)
            setTimeout(() => {
                setErrorEmail(true)
            }, 4000)
        }
        if(response.status === 200){
            setSuccessEmail(false);
            handleReset()
            setTimeout(() => {
                setSuccessEmail(true);
            },4000)
        }
        })
        .catch(function (error) {
        console.log(error);
        });
    }

    const sendOTP = () => {
        setErrorOTP(true)
        const data = JSON.stringify({
            "email": email,
            "kodeOtp": otp
        });
          
        const config = {
            method: 'post',
            url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/verifikasiKodeOtp`,
            headers: { 
              'Content-Type': 'application/json'
            },
            data : data
        };
          
        axios(config)
        .then(function (response) {
            if(response.status === 403) {
                setErrorOTP(false)
                setTimeout(() => {
                    setErrorOTP(true);
                }, 4000)
            }
            if(response.status === 200){
                setApiKey(response.apiKey)
                onNext();
                setPejet(false)
            }
        })
        .catch(function (error) {
        console.log(error);
        });
    }

    const sendChangePassword = () => {
        setErrorPassword(true);
        const data = JSON.stringify({
            "email": email,
            "newPassword": password,
            "confirmPassword": confPassword
        });
          
        const config = {
            method: 'post',
            url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/changePasswordAdmin`,
            headers: { 
              'x-api-key': apiKey, 
              'Content-Type': 'application/json'
            },
            data : data
        };
          
        axios(config)
        .then(function (response) {
            if(response.status === 403){
                setErrorKontenPassword('New Password dan Confirm Password tidak sama')
                setErrorPassword(false);
                setTimeout(() => {
                    setErrorPassword(true);
                }, 4000)
            }
            if(response.status === 200){
                window.location.href = '/login-admin'
            }
        })
        .catch(function (error) {
            setErrorKontenPassword('Silahkan check kembali Password Anda')
            setErrorPassword(false);
            setTimeout(() => {
                setErrorPassword(true);
            }, 4000)
        });
    }

    const [coba, setCoba] = useState(120);
    const [pejet, setPejet] = useState(false);

    useInterval(() => {
        setCoba(coba - 1);

        if(coba === 1) {
            setPejet(false)
        }

    },pejet ? 1000 : null);

    const formatTime = () => {
        const minutes = Math.floor(coba / 60);
        const seconds = coba % 60;
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
    } 
    const handleReset = () => {
        setCoba(120);
        setPejet(true)
    }

    return (
        <React.Fragment>            

        <Row style={{margin: '0px', height: '100vh', alignItems: 'center'}}>
            <Col md={12} lg={6} xl={6} style={{height: '100%', padding: '0px'}}>
                <img src={Welcome} style={{height: '-webkit-fill-available'}}/>
            </Col>

            <Col md={12} lg={6} xl={6} style={{padding: '0px', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>

                <div style={{display: 'flex', justifyContent: 'center', marginRight: '50px'}}>
                    <img src={logoTelkom}/>
                </div>

                <div style={{marginTop: '50px', padding: '25px', boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.08)', borderRadius: '8px', width: '65%', marginRight: '50px'}}>
                {step === 0 && (
                    <>
                        <p style={{fontWeight: '700', fontSize: '30px', color: '#F56565', textAlign: 'center'}}>Lupa Kata Sandi?</p>
                        <Alert hidden={errorEmail} color="warning" style={{ textAlign: 'center', backgroundColor: '#FFF3B5', color: '#D88E00', borderColor: '#FFF3B5', margin: '10px 0px', transition: '0.5s', fontSize: '14px' }}>
                            Email tidak di temukan
                        </Alert>
                        <div className="mb-4">
                            <label style={{fontSize: '14px'}} className="form-label">Email</label>
                            <input style={{fontSize: '14px'}} value={email} onChange={changeEmail} type="email" className="form-control" id="email" placeholder="Enter email address" required/>
                        </div>

                        <div className="text-center mt-4">
                            <Button style={{fontSize: '14px'}} color="success" className="w-100" onClick={() => {
                                sendEmail();
                                // onNext();
                                }}>
                                Kirim
                            </Button>
                        </div>
                    </>
                )}
                {step === 1 && (
                    <>
                    <p style={{fontWeight: '700', fontSize: '30px', color: '#F56565', textAlign: 'center'}}>Verifikasi OTP</p>
                    <Alert color="danger" style={{ textAlign: 'center', backgroundColor: '#FFF3B5', color: '#D88E00', borderColor: '#FFF3B5', margin: '10px 0px', transition: '0.5s', fontSize: '14px' }}>
                       OTP akan Kadaluarsa {formatTime()}
                    </Alert>
                    <Alert hidden={errorOTP} color="warning" style={{ textAlign: 'center', backgroundColor: '#FFF3B5', color: '#D88E00', borderColor: '#FFF3B5', margin: '10px 0px', transition: '0.5s', fontSize: '14px' }}>
                       Maaf, OTP tidak tepat
                    </Alert>
                    <Alert hidden={successEmail} color="success" style={{ textAlign: 'center', backgroundColor: '#F0FFF4', color: '#38A169', borderColor: '#F0FFF4', margin: '10px 0px', transition: '0.5s', fontSize: '14px'}}>
                       Silahkan check email Anda kembali
                    </Alert>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <ReactInputVerificationCode value={otp} onChange={setOTP}/>
                    </div>
                    <p style={{textAlign: 'center', marginTop: '20px', fontSize: '14px'}}> 
                        Anda masuk belum menerima kode? 
                        <span style={{fontWeight: '700', fontSize: '16px', color: '#F56565', cursor: 'pointer'}} onClick={() => {
                            resendEmail();
                            }}>Kirim Ulang</span>
                    </p>
                    
                    <div className="text-center mt-4">
                        <Button style={{fontSize: '14px'}} color="success" className="w-100" onClick={() => {
                            sendOTP();
                            // onNext(); 
                            }}>
                            Verifikasi
                        </Button>
                    </div>
                    </>
                )}
                {step === 2 && (
                    <>
                    {/* ---- Masukin Data Password ---- */}
                    <p style={{fontWeight: '700', fontSize: '30px', color: '#F56565', textAlign: 'center'}}>Kata Sandi Baru</p>
                    <Alert hidden={errorPassword} color="warning" style={{ textAlign: 'center', backgroundColor: '#FFF3B5', color: '#D88E00', borderColor: '#FFF3B5', margin: '10px 0px', transition: '0.5s', fontSize: '14px' }}>
                       {errorKontenPassword}
                    </Alert>
                    <div className="mb-3">
                        <Label style={{fontSize: '14px'}} className="form-label" htmlFor="password-input">New Password</Label>
                            <div className="position-relative auth-pass-inputgroup">
                                <Input style={{fontSize: '14px'}} value={password} onChange={changePassword} type={showPass ? "text" : "password"} className="form-control pe-5" placeholder="Enter password" id="password-input" pattern='^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,24}$' title="Password 8-24 character, setidaknya ada satu huruf kecil, huruf besar, angka dan simbol" required minLength={8} maxLength={24}/>
                                <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" onClick={shower} type="button" id="password-addon"><i className="ri-eye-fill align-middle"></i></button>
                            </div>
                            <p style={{margin: '0', color: '#ED8936', fontSize: '12px'}}>*Kata sandi minimal 8 karakter harus terdiri atas huruf kapital, angka, dan simbol.</p>
                    </div>

                    {/* ---- Masukin Data ConfPassword ---- */}
                    <div className="mb-3">
                        <Label style={{fontSize: '14px'}} className="form-label" htmlFor="password-conf">Confirm Password</Label>
                            <div className="position-relative auth-pass-inputgroup">
                                <Input style={{fontSize: '14px'}} value={confPassword} onChange={changeConfPassword} type={showConfPass ? "text" : "password"} className="form-control pe-5" placeholder="Enter password" id="password-conf" required minLength={8} maxLength={24}/>
                                <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" onClick={showerConf} type="button" id="password-addon"><i className="ri-eye-fill align-middle"></i></button>
                            </div>
                    </div>

                    <div className="text-center mt-4">
                        <Button style={{fontSize: '14px'}} color="success" className="w-100" onClick={() => {
                            sendChangePassword();
                            // onNext();
                            }}>
                            Ganti Kata Sandi
                        </Button>
                    </div>
                    </>
                )}
                </div>

                {step === 0 && (
                    <>
                    <div style={{ marginRight: '50px', fontSize: '14px'}} className="mt-5 text-center">
                        <p className="mb-0">Ingat Password ? <Link to="/login-admin" className="fw-semibold text-primary text-decoration-underline"> Klik Disini </Link> </p>
                    </div>
                    </>
                )}

            </Col>
        </Row>
        </React.Fragment>
    );
};

export default CoverPasswReset;