import React, { useState, useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import axios from 'axios';
import { Col, Container, Row , Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import { useProfile } from "../Components/Hooks/UserHooks";

import Timeout from '../assets/images/telkom/timeout.svg';

const AuthProtected = (props) => {
  const { userProfile, loading } = useProfile();

  // --------- MODAL JWT ---------- //
  const [modalJWT, setModalJWT] = useState(false);

  // if (localStorage.getItem('user') == null) {
  if (sessionStorage.getItem('user') == null) {
    window.location.href = '/login-admin';
  }

  // const auth = JSON.parse(localStorage.getItem('user')).results;
  const auth = JSON.parse(sessionStorage.getItem('user')).results;

  useEffect(() => {
    checkJWT()
  }, [])

  // ----------- CHECK JWT --------- //
  const checkJWT = () => {
    const config = {
      method: 'post',
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/checkJwt`,
      headers: { 
        'x-api-key': auth.token
      }
    };
    
    axios(config)
    .then(function (response) {
      if(response.status == 403){
        setModalJWT(true)
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }
  function openModalJWT() {
    setModalJWT(!modalJWT);
  }

  return (
  <>
  {props.children}
  <Modal isOpen={modalJWT} centered >
    <ModalBody style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <img src={Timeout} />
      <h5 style={{ textAlign: 'center', marginTop: '30px', fontSize: '24px', fontWeight: '700', color: '#2A4365' }}>Maaf, Silahkan Login Kembali</h5>
      <p style={{ padding: '0 45px', textAlign: 'center', fontSize: '16px', color: '#4A5568', fontWeight: '400' }}>Sesi Anda sudah kadaluarsa, Jangan khawatir... Click tombol di bawah ini untuk login kembali :)</p>
    </ModalBody>
    <ModalFooter style={{ justifyContent: 'center' }}>
      <Button style={{ padding: '0.5rem 3.0rem' }} className="btn btn-success" onClick={() => { window.localStorage.clear(); window.sessionStorage.clear(); window.location.reload(); window.location.href = "/login-admin"; }} >Login</Button>
    </ModalFooter>
  </Modal>
  </>
  );
};

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        return (<> <Component {...props} /> </>);
      }}
    />
  );
};

export { AuthProtected, AccessRoute };
