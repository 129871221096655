'use strict';
import CryptoJS from 'crypto-js'

const aesEncrypt = (content) => {
    const parsedkey = CryptoJS.enc.Utf8.parse(process.env.PASSWORD_ENCRYPT);
    const iv = CryptoJS.enc.Utf8.parse('test');
    const encrypted = CryptoJS.AES.encrypt(content, parsedkey, { iv: iv, mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
    return encrypted.toString();
};

// const aesDecrypt = (word) => {
//     var keys = CryptoJS.enc.Utf8.parse(process.env.PASSWORD_ENCRYPT);
//     let base64 = CryptoJS.enc.Base64.parse(word);
//     let src = CryptoJS.enc.Base64.stringify(base64);
//     var decrypt = CryptoJS.AES.decrypt(src, keys, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
//     return JSON.parse(decrypt.toString(CryptoJS.enc.Utf8));
// };

const aesEncryptIdentitas = (content) => {
    if(content != ""){
        const parsedkey = CryptoJS.enc.Utf8.parse(process.env.PASSWORD_ENCRYPT);
        const iv = CryptoJS.enc.Utf8.parse('identitas');
        const encrypted = CryptoJS.AES.encrypt(content, parsedkey, { iv: iv, mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
    
        return encrypted.toString();
    } else {
        return content
    }

};

const aesDecryptIdentitas = (content) => {
    if(content != ""){
        const parsedkey = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_PASSWORD_ENCRYPT);
        const decrypted = CryptoJS.AES.decrypt(content, parsedkey, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });

        return decrypted.toString(CryptoJS.enc.Utf8)
    } else {
        return content
    }

};



export default {aesEncrypt, aesEncryptIdentitas, aesDecryptIdentitas};
