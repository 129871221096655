import React, { useState, useRef, useEffect } from "react";
import Webcam from 'react-webcam';

import { useReactToPrint } from "react-to-print";

import { Col, Container, Row, Button, Input, Modal, ModalBody, ModalHeader, ModalFooter, Table, Label, Card, CardBody, Progress, Nav, NavItem, NavLink, TabContent, TabPane, Accordion, AccordionItem, Collapse } from "reactstrap";
import Slider from "react-rangeslider";
import classnames from "classnames";
import logoTelkom from '../../assets/images/telkom/logoACI.png';
import axios from 'axios';
import FotoModal from "../../assets/images/telkom/modal.png";
import DraftPenilaian from "../../assets/images/telkom/penilaian.svg";
import deletePhoto from '../../assets/images/telkom/delete.png';

import soalSalah from '../../assets/images/telkom/errorSoal.svg';
import Timeout from '../../assets/images/telkom/timeout.svg';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import success from '../../assets/images/telkom/success.svg';

import crypto from "../Setting/crypto";



const I5 = () => {
  document.title = "Data Pelamar | Preview Result";

  // ---------- GET ALL EVENT ------------ //
  const [dataUser, setDataUser] = useState({});

  const [nomor, setNomor] = useState(0);
  const [inputFields, setInputFields] = useState([]);

  const [nama, setNama] = useState('');
  const [eventList, setEventList] = useState([]);
  const [ambilId, setAmbilId] = useState('0');
  const [pelamar, setPelamar] = useState([]);

  // ---------- GET ALL EVENT PELAMAR ------------- //
  const [ambilIdEvent, setAmbilIdEvent] = useState(sessionStorage.getItem("pilihEvent"));
  const [pelamarById, setPelamarById] = useState();

  // ---------- DOWNLOAD ----------- //
  const [rating1, setRating1] = useState(0);
  const [rating2, setRating2] = useState(0);
  const [rating3, setRating3] = useState(0);
  const [rating4, setRating4] = useState(0);
  const [rating5, setRating5] = useState(0);
  const [judulto, setJudulto] = useState('');
  const [namato, setNamato] = useState("");
  const [emailto, setEmailto] = useState("");
  const [kodeto, setKodeto] = useState("");
  const [submitto, setSubmitto] = useState('');
  const [tanggalSekarang, setTanggalSekarang] = useState()
  const [noHPto, setNoHPto] = useState("");
  const [alamatto, setAlamatto] = useState("");
  const [tanggalLahirto, setTanggalLahirto] = useState("");

  // MEI 2023 ---------- SLIDER ------------ //
  const [amanahSlider, setAmanahSlider] = useState(0);
  const [kompetenSlider, setKompetenSlider] = useState(0);
  const [harmonisSlider, setHarmonisSlider] = useState(0);
  const [loyalSlider, setLoyalSlider] = useState(0);
  const [adaptifSlider, setAdaptifSlider] = useState(0);
  const [kolaboratifSlider, setKolaboratifSlider] = useState(0);
  const [impactSlider, setImpactSlider] = useState(0);
  const [oralSlider, setOralSlider] = useState(0);
  // MEI 2023 --------- UPDATE NILAI ------------ //
  const [komentarAmanah, setKomentarAmanah] = useState("");
  const [komentarKompeten, setKomentarKompeten] = useState("");
  const [komentarHarmonis, setKomentarHarmonis] = useState("");
  const [komentarLoyal, setKomentarLoyal] = useState("");
  const [komentarAdaptif, setKomentarAdaptif] = useState("");
  const [komentarKolaboratif, setKomentarKolaboratif] = useState("");
  const [aspekStrength, setAspekStrength] = useState("");
  const [aspekWeakness, setAspekWeakness] = useState("");
  const [komentarImpact, setKomentarImpact] = useState("");
  const [komentarOral, setKomentarOral] = useState("");
  const [appearanceStrength, setAppearanceStrength] = useState("");
  const [appearanceWeakness, setAppearanceWeakness] = useState("");
  const [statusNilai, setStatusNilai] = useState(null);
  const [errorKonten, setErrorKonten] = useState('');
  const [errorMenilai, setErrorMenilai] = useState(false)
  

  // MEI 2023 
  const [hasilPenilaianDownload, setHasilPenilaianDownload] = useState("Belum Ada Hasil");
  const [sumNilai, setSumNilai] = useState(0);
  const [nilai, setNilai] = useState([]);
  const [disablerSelesai, setDisablerSelesai] = useState(false);
  const [activeTab, setactiveTab] = useState(1);
  const [progressbarvalue, setprogressbarvalue] = useState(0);
  const [passedSteps, setPassedSteps] = useState([1]);
  const [hasilPenilaianAKHLAK, setHasilPenilaianAKHLAK] = useState("Belum Ada Hasil");
  const [modalSuccess, setModalSuccess] = useState(false);

  function openModalSuccess() {
    setModalSuccess(!modalSuccess);
  }

  // MEI 2023 -------- ACCORDIONS ---------- //
  const [colAmanah, setColAmanah] = useState(false);
  const kriteriaAmanah = () => {
    setColAmanah(!colAmanah);
  };
  const [colKompeten, setColKompeten] = useState(false);
  const kriteriaKompeten = () => {
    setColKompeten(!colKompeten);
  };
  const [colHarmonis, setColHarmonis] = useState(false);
  const kriteriaHarmonis = () => {
    setColHarmonis(!colHarmonis);
  };
  const [colLoyal, setColLoyal] = useState(false);
  const kriteriaLoyal = () => {
    setColLoyal(!colLoyal);
  };
  const [colAdaptif, setColAdaptif] = useState(false);
  const kriteriaAdaptif = () => {
    setColAdaptif(!colAdaptif);
  };
  const [colKolaboratif, setColKolaboratif] = useState(false);
  const kriteriaKolaboratif = () => {
    setColKolaboratif(!colKolaboratif);
  };
  const [colImpact, setColImpact] = useState(false);
  const kriteriaImpact = () => {
    setColImpact(!colImpact);
  };
  const [colOral, setColOral] = useState(false);
  const kriteriaOral = () => {
    setColOral(!colOral);
  };
  const [hasilPenilaianNEW, setHasilPenilaianNEW] = useState("Belum Ada Hasil");
  const penilaianStatusNEW = () => {
    const ratings = [impactSlider, oralSlider];
    if (ratings.filter((rating) => rating === 0).length === 2) {
      setHasilPenilaianNEW(`Belum Ada Hasil`);
    } else {
      if (
        ratings.filter((rating) => rating === 0).length > 0 ||
        ratings.filter((rating) => rating === 1).length > 0 ||
        ratings.filter((rating) => rating === 2).length > 0
      ) {
        setHasilPenilaianNEW(`Not Ready At This Time`);
      } else if (
        ratings.filter((rating) => rating === 3).length >= 1 ||
        ratings.filter((rating) => rating === 2).length >= 1
      ) {
        setHasilPenilaianNEW(`Ready With Development`);
      } else {
        setHasilPenilaianNEW(`Ready Now`);
      }
    }
  };
  const penilaianStatusAKHLAK = () => {
    const ratings = [
      amanahSlider,
      kompetenSlider,
      harmonisSlider,
      loyalSlider,
      adaptifSlider,
      kolaboratifSlider,
    ];
    
    if (ratings.filter((rating) => rating === 0).length === 6) {
      setHasilPenilaianAKHLAK(`Belum Ada Hasil`);
    } else {
      if (
        ratings.filter((rating) => rating === 0).length > 0 ||
        ratings.filter((rating) => rating === 1).length > 0 ||
        ratings.filter((rating) => rating === 2).length >= 3
      ) {
        setHasilPenilaianAKHLAK(`Not Ready At This Time`);
      } else if (
        ratings.filter((rating) => rating === 3).length >= 3 ||
        ratings.filter((rating) => rating === 2).length >= 1
      ) {
        setHasilPenilaianAKHLAK(`Ready With Development`);
      } else {
        setHasilPenilaianAKHLAK(`Ready Now`);
      }
    }
  };
  function toggleTab(tab, value) {
    if (activeTab !== tab) {
      const modifiedSteps = [...passedSteps, tab];

      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
    setprogressbarvalue(value);
  }
  const [modalDraft, setModalDraft] = useState(false);
  function openModalDraft() {
    setModalDraft(!modalDraft);
  }
  const [modalKirim, setModalKirim] = useState(false);
  function openModalKirim() {
    setModalKirim(!modalKirim);
  }

  const now = new Date();
  const options = { day: '2-digit', month: 'long', year: 'numeric' };
  const formattedDate = now.toLocaleDateString('id-ID', options);
  const time = now.toLocaleTimeString();

  const [coba, setCoba] = useState([])
  const createLogDraft = () => {
    const tambah = [...coba, `Telah diubah status penilaian menjadi Draft oleh Admin (${dataUser.nama}) pada waktu ${time} ${formattedDate}`];
    setCoba(tambah);
  };
  const createLog = () => {
    const tambah = [...coba, `Hasil review oleh Admin (${dataUser.nama}) pada waktu ${time} ${formattedDate}`];
    setCoba(tambah);
  };

  const penilaianPeserta = () => {

    const data = JSON.stringify({
      aspek_Nilai_Amanah: amanahSlider,
      aspek_Komentar_Amanah: komentarAmanah,
      aspek_Nilai_Kompeten: kompetenSlider,
      aspek_Komentar_Kompeten: komentarKompeten,
      aspek_Nilai_Harmonis: harmonisSlider,
      aspek_Komentar_Harmonis: komentarHarmonis,
      aspek_Nilai_Loyal: loyalSlider,
      aspek_Komentar_Loyal: komentarLoyal,
      aspek_Nilai_Adaptif: adaptifSlider,
      aspek_Komentar_Adaptif: komentarAdaptif,
      aspek_Nilai_Kolaboratif: kolaboratifSlider,
      aspek_Komentar_Kolaboratif: komentarKolaboratif,
      aspek_KomentarUmum_Strength: aspekStrength,
      aspek_KomentarUmum_Weakness: aspekWeakness,
      appearance_Nilai_Impact: impactSlider,
      appearance_Komentar_Impact: komentarImpact,
      appearance_Nilai_OralCom: oralSlider,
      appearance_Komentar_OralCom: komentarOral,
      appearance_KomentarUmum_Strength: appearanceStrength,
      appearance_KomentarUmum_Weakness: appearanceWeakness,
      id: ambilId,
      id_Event: ambilIdEvent,
      statusNilai: statusNilai,
      // log: JSON.stringify(coba)
    });

    if(amanahSlider > 0 && kompetenSlider > 0 && harmonisSlider > 0 && loyalSlider > 0 && adaptifSlider > 0 && kolaboratifSlider > 0 && impactSlider > 0 && oralSlider > 0 && aspekStrength !== '' && aspekWeakness !== '' && appearanceStrength !== '' && appearanceWeakness !== '') {
      const config = {
        method: "patch",
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/assesor/penilaian`,
        headers: {
          "x-api-key": dataUser.api_key,
          "Content-Type": "application/json",
        },
        data: data,
      };
  
      axios(config)
        .then(function (response) {
          setModalSuccess(true)
          setModalKirim(false)
          setModalDraft(false)
          if(response.status === 200){
            // window.location.reload()
          }
          if(response.status === 403){
            setModalJWT(true)
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      setModalKirim(false)
      setModalDraft(false)
      setErrorKonten('Ada yang belum di nilai atau Ada yang belum di komentar')
      setErrorMenilai(true)
    }

  };

  // -------- Search -------- //
  const [kata, setKata] = useState('')

  // ---------- ON NEXT --------- //
  const [step, setStep] = useState(0);

  const onChange = (nextStep) => {
    setStep(nextStep < 0 ? 0 : nextStep > 4 ? 4 : nextStep);
  };

  const onNext = () => onChange(step + 1);
  const onPrevious = () => onChange(step - 1);
  // ~~~~~~~~ ON NEXT ~~~~~~~~~ //

  // --------- MODAL ---------- //
  const [modal_list, setmodal_list] = useState(false);
  function tog_list() {
    setmodal_list(!modal_list);
  }

  // --------- MODAL DOWNLOAD ---------- //
  const [modalDownload, setModalDownload] = useState(false);
  function openModalDownload() {
    setModalDownload(!modalDownload);
  }

  // --------- MODAL TRANSKRIP ---------- //
  const [modalTranskrip, setModalTranskrip] = useState(false);
  function openModalTranskrip() {
    setModalTranskrip(!modalTranskrip);
  }

  // --------- MODAL CATATAN  ---------- //
  const [modalCatatan, setModalCatatan] = useState(false);
  function openModalCatatan() {
    setModalCatatan(!modalCatatan);
  }

  // --------- MODAL DELETE ---------- //
  const [modalDelete, setModalDelete] = useState(false);
  function openModalDelete() {
    setModalDelete(!modalDelete);
  }

  // --------- MODAL SUCCESS DELETE---------- //
  const [modalSuccessDelete, setModalSuccessDelete] = useState(false);
  function openModalSuccessDelete() {
    setModalSuccessDelete(!modalSuccessDelete);
  }


  // ------------ Print PDF ------------ //
  const ref = useRef();
  const handlePrint = useReactToPrint({
    content: () => ref.current,
  })

  // delete
  const [selectPeserta, setSelectPeserta] = useState([]);
  const [ids, setIds] = useState([]);

  useEffect(() => {
    // const user = JSON.parse(localStorage.getItem("user"));
    const user = JSON.parse(sessionStorage.getItem("user"));
    if (user) {
      setDataUser(user.results);

      defaultGetAllEventPeserta(user.results.api_key)
      getAllEventInterview(user.results.api_key);
    } else {
      window.location.href = "/login-admin";
    }
    // setNama(JSON.parse(localStorage.getItem('user')).results.nama);
    setNama(crypto.aesDecryptIdentitas(user.results.nama));
    
  }, [])

  const getAllEventInterview = (api_key) => {

    const config = {
      method: 'get',
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/eventlist`,
      headers: { 
        'x-api-key': api_key
      }
    };

    axios(config)
      .then(function (response) {
        setEventList(JSON.parse(JSON.stringify(response.data)));
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const defaultGetAllEventPeserta = (api_key) => {

    const config = {
      method: 'get',
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/assesor/pesertaSelesai/${ambilIdEvent}`,
      headers: { 
        'x-api-key': api_key
      }
    };

    axios(config)
      .then(function (response) {

        setSelectPeserta(
          response.data.map(peserta => {
            return {
              select: false,
              id: peserta.id,
              id_Event: peserta.id_Event,
              nama: crypto.aesDecryptIdentitas(peserta.nama),
              email: crypto.aesDecryptIdentitas(peserta.email),
              no_HP: crypto.aesDecryptIdentitas(peserta.no_HP),
              alamat: crypto.aesDecryptIdentitas(peserta.alamat),
              tanggal_Lahir: peserta.tanggal_Lahir,
              tanggal_Daftar: peserta.tanggal_Daftar,
              tanggal_Submit: peserta.tanggal_Submit,
              assesor: peserta.assesor,
              kode: peserta.kode,
              link: peserta.link,
              batas_Waktu: peserta.batas_Waktu,
              soal: peserta.soal,
              status: peserta.status,
              feedback: peserta.feedback,
              statusNilai: peserta.statusNilai,
              aspek_Nilai_Amanah: peserta.aspek_Nilai_Amanah,
              aspek_Komentar_Amanah: peserta.aspek_Komentar_Amanah,
              aspek_Nilai_Kompeten: peserta.aspek_Nilai_Kompeten,
              aspek_Komentar_Kompeten: peserta.aspek_Komentar_Kompeten,
              aspek_Nilai_Harmonis: peserta.aspek_Nilai_Harmonis,
              aspek_Komentar_Harmonis: peserta.aspek_Komentar_Harmonis,
              aspek_Nilai_Loyal: peserta.aspek_Nilai_Loyal,
              aspek_Komentar_Loyal: peserta.aspek_Komentar_Loyal,
              aspek_Nilai_Adaptif: peserta.aspek_Nilai_Adaptif,
              aspek_Komentar_Adaptif: peserta.aspek_Komentar_Adaptif,
              aspek_Nilai_Kolaboratif: peserta.aspek_Nilai_Kolaboratif,
              aspek_Komentar_Kolaboratif: peserta.aspek_Komentar_Kolaboratif,
              aspek_KomentarUmum_Strength: peserta.aspek_KomentarUmum_Strength,
              aspek_KomentarUmum_Weakness: peserta.aspek_KomentarUmum_Weakness,
              appearance_Nilai_Impact: peserta.appearance_Nilai_Impact,
              appearance_Komentar_Impact: peserta.appearance_Komentar_Impact,
              appearance_Nilai_OralCom: peserta.appearance_Nilai_OralCom,
              appearance_Komentar_OralCom: peserta.appearance_Komentar_OralCom,
              appearance_KomentarUmum_Strength: peserta.appearance_KomentarUmum_Strength,
              appearance_KomentarUmum_Weakness: peserta.appearance_KomentarUmum_Weakness,
              log: peserta.log,

            };
          }))
        setPelamar(JSON.parse(JSON.stringify(response.data)));
      })
      .catch(function (error) {
        console.log(error);
      });

  }

  const changeGetAllEventPeserta = (api_key, id_event) => {

    const config = {
      method: 'get',
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/assesor/pesertaSelesai/${id_event}`,
      headers: { 
        'x-api-key': api_key
      }
    };

    axios(config)
      .then(function (response) {

        setSelectPeserta(
          response.data.map(peserta => {
            return {
              select: false,
              id: peserta.id,
              id_Event: peserta.id_Event,
              nama: crypto.aesDecryptIdentitas(peserta.nama),
              email: crypto.aesDecryptIdentitas(peserta.email),
              no_HP: crypto.aesDecryptIdentitas(peserta.no_HP),
              alamat: crypto.aesDecryptIdentitas(peserta.alamat),
              tanggal_Lahir: peserta.tanggal_Lahir,
              tanggal_Daftar: peserta.tanggal_Daftar,
              tanggal_Submit: peserta.tanggal_Submit,
              assesor: peserta.assesor,
              kode: peserta.kode,
              link: peserta.link,
              batas_Waktu: peserta.batas_Waktu,
              soal: peserta.soal,
              status: peserta.status,
              feedback: peserta.feedback,
              statusNilai: peserta.statusNilai,
              aspek_Nilai_Amanah: peserta.aspek_Nilai_Amanah,
              aspek_Komentar_Amanah: peserta.aspek_Komentar_Amanah,
              aspek_Nilai_Kompeten: peserta.aspek_Nilai_Kompeten,
              aspek_Komentar_Kompeten: peserta.aspek_Komentar_Kompeten,
              aspek_Nilai_Harmonis: peserta.aspek_Nilai_Harmonis,
              aspek_Komentar_Harmonis: peserta.aspek_Komentar_Harmonis,
              aspek_Nilai_Loyal: peserta.aspek_Nilai_Loyal,
              aspek_Komentar_Loyal: peserta.aspek_Komentar_Loyal,
              aspek_Nilai_Adaptif: peserta.aspek_Nilai_Adaptif,
              aspek_Komentar_Adaptif: peserta.aspek_Komentar_Adaptif,
              aspek_Nilai_Kolaboratif: peserta.aspek_Nilai_Kolaboratif,
              aspek_Komentar_Kolaboratif: peserta.aspek_Komentar_Kolaboratif,
              aspek_KomentarUmum_Strength: peserta.aspek_KomentarUmum_Strength,
              aspek_KomentarUmum_Weakness: peserta.aspek_KomentarUmum_Weakness,
              appearance_Nilai_Impact: peserta.appearance_Nilai_Impact,
              appearance_Komentar_Impact: peserta.appearance_Komentar_Impact,
              appearance_Nilai_OralCom: peserta.appearance_Nilai_OralCom,
              appearance_Komentar_OralCom: peserta.appearance_Komentar_OralCom,
              appearance_KomentarUmum_Strength: peserta.appearance_KomentarUmum_Strength,
              appearance_KomentarUmum_Weakness: peserta.appearance_KomentarUmum_Weakness,
              log: peserta.log,
            };
          }))
        setPelamar(JSON.parse(JSON.stringify(response.data)));
      })
      .catch(function (error) {
        console.log(error);
      });

  }

  const getIds = () => {
    selectPeserta.forEach(d => {
      if (d.select) {
        setIds(d.id)

      }
    });
  }

  //delete
  const deletePeserta = () => {
    let arrayids = [];

    selectPeserta.forEach(d => {
      if (d.select) {
        //setIds(d.id)
        arrayids.push(d.id);
      }
    });

    const data = JSON.stringify({
      "ids": arrayids
    });

    const config = {
      method: 'delete',
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/peserta/remove`,
      headers: {
        'Content-Type': 'application/json',
        'x-api-key' : dataUser.token
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        setTimeout(() => {
          setModalSuccessDelete(true)
          getAllEventInterview(dataUser.api_key)
        }, 4000)
      })
      .catch(function (error) {
        console.log(error);
      });

  }


  const downloadPeserta = () => {
    let arrayids = [];

    selectPeserta.forEach(d => {
      if (d.select) {
        //setIds(d.id)
        arrayids.push(d.id);
      }
    });

    const data = JSON.stringify({
      "ids": arrayids
    });

    const config = {
      method: 'post',
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/downloadPeserta`,
      headers: {
        //'x-api-key': dataUser.token, 
        'Content-Type': 'application/json'
      },
      // responseType: 'blob',
      data: data
    };

    axios(config)
      .then(function (response) {
        let data = response.data
        let temp = []
        var no = 1;
        data.forEach(d => {
          let test = {
            "No": no,
            "Nama": d.nama,
            "Email": d.email,
            "Kode Peserta": d.kode,
            "Assessor": d.assesor,
            "Aspek Nilai Amanah": d.aspek_Nilai_Amanah,
            "Komentar Amanah": d.aspek_Komentar_Amanah,
            "Aspek Nilai Kompeten": d.aspek_Nilai_Kompeten,
            "Komentar Kompeten": d.aspek_Komentar_Kompeten,
            "Aspek Nilai Harmonis": d.aspek_Nilai_Harmonis,
            "Komentar Harmonis": d.aspek_Komentar_Harmonis,
            "Aspek Nilai Loyal": d.aspek_Nilai_Loyal,
            "Komentar Loyal": d.aspek_Komentar_Loyal,
            "Aspek Nilai Adaptif": d.aspek_Nilai_Adaptif,
            "Komentar Adaptif": d.aspek_Komentar_Adaptif,
            "Aspek Nilai Kolaboratif": d.aspek_Nilai_Kolaboratif,
            "Komentar Kolaboratif": d.aspek_Komentar_Kolaboratif,
            "Nilai Appearance Impact": d.appearance_Nilai_Impact,
            "Komentar Appearance Impact": d.appearance_Komentar_Impact,
            "Nilai Appearance OralCom": d.appearance_Nilai_OralCom,
            "Komentar Appearance OralCom": d.appearance_Komentar_OralCom


          }
          temp.push(test)
          no++;
        })


        const ws = XLSX.utils.json_to_sheet(temp);
        const wb = { Sheets: { 'Data Peserta': ws }, SheetNames: ['Data Peserta'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const dataa = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
        FileSaver.saveAs(dataa, `${judulto}.xlsx`);

        setTimeout(() => {
          // setModalSuccess(false)
          getAllEventInterview(dataUser.api_key)
        }, 4000)
      })
      .catch(function (error) {
        console.log(error);
      });

  }

  const getJudulInterview = (token) => {
    const config = {
      method: 'get',
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/pesertaJudul/${ambilIdEvent}`,
      header: {
        'x-api-key' : token
      }
    };

    axios(config)
      .then(function (response) {
        setJudulto(JSON.parse(JSON.stringify(response.data)).judul_Interview)
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const [batasWaktu, setBatasWaktu] = useState('');
  const [tanggalSubmit, setTanggalSubmit] = useState('');
  const [namaBulan, setNamaBulan] = useState('');
  // ------------ GET PESERTA BY ID ----------- //
  const getPesertaById = (id) => {
    const config = {
      method: 'get',
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/pesertaEvent/${id}/${ambilIdEvent}`,
      headers: { 
        'x-api-key': dataUser.token
      },
    };

    axios(config)
      .then( function (response) {
        const a = response.data;
        const data = a[0];
        setPelamarById(data);
        const tgl = new Date(data.tanggal_Submit);
        const tahun = tgl.getFullYear();
        const bulan = tgl.getMonth()+1 > 9 ? tgl.getMonth()+1 : '0'+(tgl.getMonth()+1)
        const hari = tgl.getDate() > 9 ? tgl.getDate() : '0'+tgl.getDate()
        setTanggalSubmit(`${tahun}-${bulan}-${hari}`);
        setBatasWaktu(data.batas_Waktu);
        const soal =  getUrlVideoSoal(JSON.parse(data.soal))
        const soalnJawaban = getUrlVideoJawaban(soal)
        setInputFields(soalnJawaban);
        onNext();
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  // MEI 2023
  const penilaianStatusDownload = (data) => {

    const ratings = [
      data.aspek_Nilai_Amanah,
      data.aspek_Nilai_Kompeten,
      data.aspek_Nilai_Harmonis,
      data.aspek_Nilai_Loyal,
      data.aspek_Nilai_Adaptif,
      data.aspek_Nilai_Kolaboratif,
      data.appearance_Nilai_Impact,
      data.appearance_Nilai_OralCom
    ];

   
    if (ratings.filter((rating) => rating === 0).length === 6) {
      setHasilPenilaianDownload(`Belum Ada Hasil`);
    } else {
      if (
       // ratings.filter((rating) => rating === 0).length > 0 ||
        ratings.filter((rating) => rating === 1).length > 0 ||
        ratings.filter((rating) => rating === 2).length >= 3
      ) {
        setHasilPenilaianDownload(`Not Ready At This Time`);
        data.statusNilai = `Not Ready At This Time`
        data.no = 1
      } else if (
        ratings.filter((rating) => rating === 3).length >= 3 ||
        ratings.filter((rating) => rating === 2).length >0
      ) {
        setHasilPenilaianDownload(`Ready With Development`);
        data.statusNilai = `Ready With Development`
        data.no = 2
      } 
      else {
        setHasilPenilaianDownload(`Ready Now`);
        data.statusNilai = `Ready Now`
        data.no = 3
      }
    }
  };

  // MEI 2023
  const getPesertaByIdDownload = (id) => {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/pesertaEvent/${id}/${ambilIdEvent}`,
      headers :{
        "x-api-key" : dataUser.token
      }
    };

    axios(config)
      .then(function (response) {
        
        const data = response.data[0];
        const loginaw = JSON.parse(data.log)
        
        setCoba(loginaw);
        setNamato(crypto.aesDecryptIdentitas(data.nama))
        setEmailto(crypto.aesDecryptIdentitas(data.email))
        setKodeto(data.kode)
        const now = new Date()
        const hariNow = now.getDate() > 9 ? now.getDate() : "0" + now.getDate();
        const bulanNow = now.getMonth() + 1 > 9 ? (now.getMonth()+1) : "0" + (now.getMonth()+1);
        const tahunNow = now.getFullYear()

        if(bulanNow === '01'){
          setTanggalSekarang(`${hariNow} Januari ${tahunNow}`)
        }
        if(bulanNow === '02'){
          setTanggalSekarang(`${hariNow} Februari ${tahunNow}`)
        }
        if(bulanNow === '03'){
          setTanggalSekarang(`${hariNow} Maret ${tahunNow}`)
        }
        if(bulanNow === '04'){
          setTanggalSekarang(`${hariNow} April ${tahunNow}`)
        }
        if(bulanNow === '05'){
          setTanggalSekarang(`${hariNow} Mei ${tahunNow}`)
        }
        if(bulanNow === '06'){
          setTanggalSekarang(`${hariNow} Juni ${tahunNow}`)
        }
        if(bulanNow === '07'){
          setTanggalSekarang(`${hariNow} Juli ${tahunNow}`)
        }
        if(bulanNow === '08'){
          setTanggalSekarang(`${hariNow} Agustus ${tahunNow}`)
        }
        if(bulanNow === '09'){
          setTanggalSekarang(`${hariNow} September ${tahunNow}`)
        }
        if(bulanNow === '10'){
          setTanggalSekarang(`${hariNow} Oktober ${tahunNow}`)
        }
        if(bulanNow === '11'){
          setTanggalSekarang(`${hariNow} November ${tahunNow}`)
        }
        if(bulanNow === '12'){
          setTanggalSekarang(`${hariNow} Desember ${tahunNow}`)
        }

        const tanggal = new Date(data.tanggal_Submit)
        const hari = tanggal.getDate() > 9 ? tanggal.getDate() : "0" + tanggal.getDate();
        const bulan = tanggal.getMonth() + 1 > 9 ? (tanggal.getMonth()+1) : "0" + (tanggal.getMonth()+1);
        const tahun = tanggal.getFullYear()
        
        if(bulan === '01'){
          setSubmitto(`${hari} Januari ${tahun}`)
        }
        if(bulan === '02'){
          setSubmitto(`${hari} Februari ${tahun}`)
        }
        if(bulan === '03'){
          setSubmitto(`${hari} Maret ${tahun}`)
        }
        if(bulan === '04'){
          setSubmitto(`${hari} April ${tahun}`)
        }
        if(bulan === '05'){
          setSubmitto(`${hari} Mei ${tahun}`)
        }
        if(bulan === '06'){
          setSubmitto(`${hari} Juni ${tahun}`)
        }
        if(bulan === '07'){
          setSubmitto(`${hari} Juli ${tahun}`)
        }
        if(bulan === '08'){
          setSubmitto(`${hari} Agustus ${tahun}`)
        }
        if(bulan === '09'){
          setSubmitto(`${hari} September ${tahun}`)
        }
        if(bulan === '10'){
          setSubmitto(`${hari} Oktober ${tahun}`)
        }
        if(bulan === '11'){
          setSubmitto(`${hari} November ${tahun}`)
        }
        if(bulan === '12'){
          setSubmitto(`${hari} Desember ${tahun}`)
        }

        const ratings = [
          data.aspek_Nilai_Amanah,
          data.aspek_Nilai_Kompeten,
          data.aspek_Nilai_Harmonis,
          data.aspek_Nilai_Loyal,
          data.aspek_Nilai_Adaptif,
          data.aspek_Nilai_Kolaboratif,
          data.appearance_Nilai_Impact,
          data.appearance_Nilai_OralCom
        ];
        penilaianStatusDownload(data)

        setRating1( ratings.filter((rating) => rating === 1).length)
        setRating2( ratings.filter((rating) => rating === 2).length)
        setRating3( ratings.filter((rating) => rating === 3).length)
        setRating4( ratings.filter((rating) => rating === 4).length)
        setRating5( ratings.filter((rating) => rating === 5).length)
        
        setSumNilai(ratings.reduce((a, b) => a + b, 0));
        
        setNilai([
          { 
            jenis:"Amanah",
            nilai: data.aspek_Nilai_Amanah,
          },
          {
            jenis:"Kompeten",
            nilai: data.aspek_Nilai_Kompeten,
          },
          {
            jenis:"Harmonis",
            nilai: data.aspek_Nilai_Harmonis,
          },
          {
            jenis: "Loyal", 
            nilai: data.aspek_Nilai_Loyal,
          },
          {
            jenis: "Adaptif",
            nilai: data.aspek_Nilai_Adaptif,
          },
          {
            jenis: "Kolaboratif" ,
            nilai: data.aspek_Nilai_Kolaboratif,
          },
          {
            jenis: "Impact",
            nilai: data.appearance_Nilai_Impact,
          },
          {
            jenis: "OralCom",
            nilai: data.appearance_Nilai_OralCom,
          }
        ])
        
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // MEI 2023
  function getUrlVideoSoal(soalnya){
    // const soalnya = JSON.parse(soal)

    soalnya.map(item => {
      if (item.type === "pengantar" || item.type === "video&video"){
        const data = JSON.stringify({
            fileName: item.namaVideoSoal,
        });
        const config = {
          method: 'patch',
          url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/getUrlVideo`,
          headers: {
            'x-api-key': dataUser.token,
              'Content-Type': 'application/json'
          },
          data: data
      };

      axios(config)
      .then(async function (response) {
        if (item.type === "pengantar"){
          item.file = await response.urlFile
        }
        else{
          item.tanya = await response.urlFile
        }
      })
      .catch(function (error) {
          console.log(error);
      });

      }

    })
    return soalnya;

  }

  function getUrlVideoJawaban(jawaban){
    jawaban.map(item => {

      if (item.namaVideoJawab !== "" && (item.type === "video&video" || item.type === "text&video" )){
        const data = JSON.stringify({
            fileName: item.namaVideoJawab,
        });
        const config = {
          method: 'patch',
          url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/getUrlVideoJawaban`,
          headers: {
              "x-api-key": dataUser.api_key,
              'Content-Type': 'application/json'
          },
          data: data
      };

      axios(config)
      .then(async function (response) {
       
        item.file = await response.urlFile

      })
      .catch(function (error) {
          console.log(error);
      });

      }
    })
    return jawaban;
  }

  // MEI 2023
  function getPesertaByIdDetail(id) {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/pesertaEvent/${id}/${ambilIdEvent}`,
      headers :{
        "x-api-key" : dataUser.token
      }
    };

    axios(config)
      .then(function (response) {
        const a = response.data;
        const b = a[0];

        setNamato(crypto.aesDecryptIdentitas(b.nama));
        setEmailto(crypto.aesDecryptIdentitas(b.email));
        setNoHPto(crypto.aesDecryptIdentitas(b.no_HP));
        setAlamatto(crypto.aesDecryptIdentitas(b.alamat));
        const date = new Date(b.tanggal_Lahir);
        const tgl = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
          if(b.tanggal_Lahir !== null){
            setTanggalLahirto(tgl);
          }else{
            setTanggalLahirto("---")
          }
        
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  // MEI 2023
  function getPesertaByIdDetailUpdate(id) {

    const config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/pesertaEvent/${id}/${ambilIdEvent}`,
      headers :{
        "x-api-key" : dataUser.token
      }
    };

    axios(config)
      .then(function (response) {
        const loginaw = JSON.parse(response.data[0].log)
        setCoba(loginaw);
        const a = response.data;
        const b = a[0];
        setPelamarById(b);
        const soal = getUrlVideoSoal(JSON.parse(b.soal))
        const soalnJawaban = getUrlVideoJawaban(soal)
        setInputFields(soalnJawaban);
       
        const tgl = new Date(b.tanggal_Submit);
        const tahun = tgl.getFullYear();
        const bulan = tgl.getMonth()+1 > 9 ? tgl.getMonth()+1 : '0'+(tgl.getMonth()+1)
        const hari = tgl.getDate() > 9 ? tgl.getDate() : '0'+tgl.getDate()
        setTanggalSubmit(`${tahun}-${bulan}-${hari}`);
        setBatasWaktu(b.batas_Waktu);

        setAmanahSlider(b.aspek_Nilai_Amanah)
        setKompetenSlider(b.aspek_Nilai_Kompeten)
        setHarmonisSlider(b.aspek_Nilai_Harmonis)
        setLoyalSlider(b.aspek_Nilai_Loyal)
        setAdaptifSlider(b.aspek_Nilai_Adaptif)
        setKolaboratifSlider(b.aspek_Nilai_Kolaboratif)
        setImpactSlider(b.appearance_Nilai_Impact)
        setOralSlider(b.appearance_Nilai_OralCom)

        setKomentarAmanah(b.aspek_Komentar_Amanah)
        setKomentarKompeten(b.aspek_Komentar_Kompeten)
        setKomentarHarmonis(b.aspek_Komentar_Harmonis)
        setKomentarLoyal(b.aspek_Komentar_Loyal)
        setKomentarAdaptif(b.aspek_Komentar_Adaptif)
        setKomentarKolaboratif(b.aspek_Komentar_Kolaboratif)
        setKomentarImpact(b.appearance_Komentar_Impact)
        setKomentarOral(b.appearance_Komentar_OralCom)

        setAspekStrength(b.aspek_KomentarUmum_Strength)
        setAspekWeakness(b.aspek_KomentarUmum_Weakness)
        setAppearanceStrength(b.appearance_KomentarUmum_Strength)
        setAppearanceWeakness(b.appearance_KomentarUmum_Weakness)
        onNext();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleChangeNote = (event, index) => {
    const ganti = [...inputFields]
    ganti[index].note = event.target.value
    setInputFields(ganti)
  }

  const handleText = (event, index) => {
    const ganti = [...inputFields];
    ganti[index].note = event.target.value;
    setInputFields(ganti);
  };

  const updateNote = () => {
    const data = JSON.stringify({
      id: ambilId,
      id_Event: ambilIdEvent,
      tanggal_Submit: tanggalSubmit,
      batas_Waktu: batasWaktu,
      soal: JSON.stringify(inputFields),
    });

    const config = {
      method: "patch",
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/peserta/jawab`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        const data = response;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  // --------- MODAL LIHAT VIDEO ---------- //
  const [modalLihatVideo, setModalLihatVideo] = useState(false);
  function openModalLihatVideo() {
    setModalLihatVideo(!modalLihatVideo);
  }

  // --------- MODAL JWT ---------- //
  const [modalJWT, setModalJWT] = useState(false);
  // ----------- CHECK JWT --------- //
  const checkJWT = () => {
    const config = {
      method: 'post',
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/checkJwt`,
      headers: {
        'x-api-key': dataUser.token,
      }
    };

    axios(config)
      .then(function (response) {
        if (response.status === 403) {
          setModalJWT(true)
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  function openModalJWT() {
    setModalJWT(!modalJWT);
  }

  const selectDataInterview = (id_event) =>{
    checkJWT();
    setAmbilIdEvent(id_event);
    sessionStorage.setItem("pilihEvent", id_event);
    changeGetAllEventPeserta(dataUser.api_key, id_event);
  }

  // MEI 2023
  const [modalDetailPelamar, setModalDetailPelamar] = useState(false);
  const openModalDetailPelamar = () => {
    setModalDetailPelamar(!modalDetailPelamar);
  };

  return (
    <React.Fragment>
      <div className="page-content">

        {/* Halaman Utama */}
        {step === 0 && (
          <>
            <div>
              <h1 style={{ color: '#2A4365' }}>Lihat Hasil Jawaban Interview Pelamar</h1>
            </div>

            {/* Menu */}
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', flexDirection: 'column', marginTop: '35px', marginBottom: '30px' }}>

                <p style={{ margin: '0', fontWeight: '700', fontSize: '16px', color: '#4A5568' }}>Pilih Data Interview</p>
                <select style={{ width: '320px', marginBottom: '10px' }} value={ambilIdEvent} onChange={(event) => { selectDataInterview(event.target.value) }} className="form-select" aria-label="Default select example">
                  <option value="0" selected="selected" hidden="hidden">Choose here</option>
                  {eventList.map((item) => (
                    <>
                      <option value={item.id}>{item.judul_Interview}</option>
                    </>
                  ))}
                </select>

                <div style={{ width: '360px', margin: '10px 0px' }} className="search-box">
                  <input type="text" className="form-control search" placeholder="Search..." onChange={(e) => setKata(e.target.value)} />
                  <i style={{ height: '40px' }} className="ri-search-line search-icon"></i>
                </div>

              </div>

              <div style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'column', alignItems: 'flex-end', marginBottom: '30px', marginTop: '35px', width: '50%' }}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', gap: '20px' }}>
                  <Button style={{ padding: '0.5rem', width: '100px', height: '40px', fontWeight: '700', backgroundColor: 'white', color: '#F56565', border: 'none' }} onClick={() => { getIds(); openModalDelete(); checkJWT(); }} >Hapus Data</Button>
                  {/* <p style={{margin:'0px'}}>|</p>
              <Button style={{ padding: '0.5rem', width: '140px', height: '40px', fontWeight: '700', backgroundColor: 'white', color: '#4299E1', border: 'none'}} onClick={() => {getIds(); downloadPeserta(); checkJWT();}}>Download</Button> */}
                </div>
              </div>

            </div>

            {/* Tabel */}
            <div className="table-responsive">
              <Table className="align-middle table-nowrap mb-0">
                <thead style={{ backgroundColor: '#FED7D7', color: '#E53E3E' }}>
                  <tr>
                    <th>
                      <input
                        type="checkbox"
                        onChange={e => {
                          let value = e.target.checked;
                          setSelectPeserta(
                            selectPeserta.map(d => {
                              d.select = value;
                              return d;
                            })
                          );
                        }}
                      />
                    </th>
                    <th scope="col">No</th>
                    <th scope="col">Nama</th>
                    <th scope="col">Kode</th>
                    <th scope="col">Tanggal Submit</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {selectPeserta.filter(item => item.nama.toLowerCase().includes(kata.toLowerCase())).map((item, index) => (
                    <tr key={index}>
                      <td>
                        <input
                          type="checkbox"
                          checked={item.select}
                          onChange={e => {
                            let value = e.target.checked;
                            setSelectPeserta(
                              selectPeserta.map(sd => {
                                if (sd.id === item.id) {
                                  sd.select = value;
                                }
                                return sd;
                              })
                            );
                          }}
                        />
                      </td>
                      <td>{1 + index}</td>
                      <td>{item.nama}</td>
                      <td>{item.kode}</td>
                      <td>
                        {new Date(item.tanggal_Submit).getFullYear() +
                          "-" +
                          (new Date(item.tanggal_Submit).getMonth() + 1 > 9
                            ? new Date(item.tanggal_Submit).getMonth() + 1
                            : "0" + (new Date(item.tanggal_Submit).getMonth() + 1)) +
                          "-" +
                          (new Date(item.tanggal_Submit).getDate() > 9
                            ? new Date(item.tanggal_Submit).getDate()
                            : "0" + new Date(item.tanggal_Submit).getDate())}
                      </td>
                      {item.statusNilai === "selesai" && (
                        <>
                          <td>
                            <span style={{ width: "100px", color: "#38A169", padding: "5px 15px", borderRadius: "5px", backgroundColor: "#f0fff4", fontWeight: "600"}}>
                              Telah Dinilai
                            </span>
                          </td>
                          <td>
                            <div className="d-flex gap-2">
                              <div className="edit">
                                <Button color="info" style={{ display: "flex", alignItems: "center", gap: "10px"}} 
                                onClick={() => { getPesertaByIdDetail(item.id); openModalDetailPelamar();}}
                                >
                                  <i className="ri-eye-fill"></i> Lihat
                                </Button>
                              </div>
                              <div>
                                <Button color="success" outline
                                  onClick={() => { getPesertaByIdDetailUpdate(item.id); getPesertaByIdDownload(item.id); setAmbilId(item.id);getJudulInterview(dataUser.token); setDisablerSelesai(true);}}
                                >
                                  Lihat Penilaian
                                </Button>
                              </div>
                            </div>
                          </td>
                        </>
                      )}
                      {item.statusNilai === null && item.aspek_Nilai_Amanah === null || item.aspek_Komentar_Amanah === null || item.aspek_Nilai_Kompeten === null || item.aspek_Komentar_Kompeten === null || item.aspek_Nilai_Harmonis === null || item.aspek_Komentar_Harmonis === null || item.aspek_Nilai_Loyal === null || item.aspek_Komentar_Loyal === null || item.aspek_Nilai_Adaptif === null || item.aspek_Komentar_Adaptif === null || item.aspek_Nilai_Kolaboratif === null || item.aspek_Komentar_Kolaboratif === null || item.appearance_Nilai_Impact === null || item.appearance_Komentar_Impact === null || item.appearance_Nilai_OralCom === null ||  item.appearance_Komentar_OralCom === null ? ( // eslint-disable-line
                        <>
                        {item.statusNilai === null && (
                        <>
                          <td>
                            <span style={{ width: "100px", color: "#D69E2E", padding: "5px 15px", borderRadius: "5px", backgroundColor: "#FEFCBF", fontWeight: "600"}}>
                              Belum Dinilai
                            </span>
                          </td>
                          <td>
                            <div className="d-flex gap-2">
                              <div className="edit">
                                <Button color="info" style={{ display: "flex", alignItems: "center", gap: "10px"}} 
                                onClick={() => { getPesertaByIdDetail(item.id); openModalDetailPelamar();}}
                                >
                                  <i className="ri-eye-fill"></i> Lihat
                                </Button>
                              </div>
                              <div>
                                <Button color="success"
                                onClick={() => { getPesertaById(item.id); setAmbilId(item.id);}} 
                                >
                                  Beri Nilai
                                </Button>
                              </div>
                            </div>
                          </td>
                        </>                        
                        )}                        
                        </>
                      ) : (
                        <>
                        {item.statusNilai === null && (
                          <>
                          <td>
                            <span style={{ width: "100px", color: "#4299E1", padding: "5px 15px", borderRadius: "5px", backgroundColor: "#EBF8FF", fontWeight: "600"}}>
                              Draft
                            </span>
                          </td>
                          <td>
                            <div className="d-flex gap-2">
                              <div className="edit">
                                <Button color="info" style={{ display: "flex", alignItems: "center", gap: "10px"}} 
                                onClick={() => { getPesertaByIdDetail(item.id); openModalDetailPelamar();}}
                                >
                                  <i className="ri-eye-fill"></i> Lihat
                                </Button>
                              </div>
                              <div>
                                <Button color="success" onClick={() => { getPesertaByIdDetailUpdate(item.id); setAmbilId(item.id);}}>
                                  Update Nilai
                                </Button>
                              </div>
                            </div>
                          </td>                          
                          </>
                        )}
                        </>
                      )}
                      {/* <td><Button onClick={() => { getPesertaById(item.id); setAmbilId(item.id); getJudulInterview(); checkJWT(); }} className="btn btn-info" style={{ fontSize: '14px' }}>Lihat</Button></td> */}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </>
        )}

        {/* Halaman Question */}
        {step === 1 && (
          <>
            <p>
             <span style={{cursor: "pointer"}}
              onClick={() => {
                onPrevious(); 
                setTanggalSubmit('');
                setBatasWaktu('');
                setDisablerSelesai(false);
                setAmanahSlider(0);
                setKompetenSlider(0);
                setHarmonisSlider(0);
                setLoyalSlider(0);
                setAdaptifSlider(0);
                setKolaboratifSlider(0);
                setImpactSlider(0);
                setOralSlider(0);
        
                setKomentarAmanah('');
                setKomentarKompeten('');
                setKomentarHarmonis('');
                setKomentarLoyal('');
                setKomentarAdaptif('');
                setKomentarKolaboratif('');
                setKomentarImpact('');
                setKomentarOral('');
        
                setAspekStrength('');
                setAspekWeakness('');
                setAppearanceStrength('');
                setAppearanceWeakness('');
              }}
              > 
                Beranda 
              </span> / 
              <span style={{ color: "#4299E1" }}>Detail Pelamar</span>
            </p>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <h2 style={{ fontWeight: "700", fontSize: "30px", color: "#2A4365"}}>
                  {crypto.aesDecryptIdentitas(pelamarById.nama)}
                </h2>
                <p style={{ color: "#718096", fontSize: "16px", fontWeight: "400"}}>
                  Tanggal Submit: 
                  <span>
                    {new Date(pelamarById.tanggal_Submit).getFullYear() +
                      "-" +
                      (new Date(pelamarById.tanggal_Submit).getMonth() + 1 > 9
                        ? new Date(pelamarById.tanggal_Submit).getMonth() + 1
                        : "0" + (new Date(pelamarById.tanggal_Submit).getMonth() + 1)) +
                      "-" +
                      (new Date(pelamarById.tanggal_Submit).getDate() > 9
                        ? new Date(pelamarById.tanggal_Submit).getDate()
                        : "0" + new Date(pelamarById.tanggal_Submit).getDate())}
                  </span>
                </p>
                <p style={{ color: "#718096", fontSize: "16px", fontWeight: "400"}}>
                  Status:
                    {pelamarById.statusNilai === "selesai" && (
                      <>
                      <span style={{ backgroundColor: "#f0fff4", color: "#38A169", marginLeft: "5px", borderRadius: "5px", padding: "5px 20px", fontWeight: "600"}}>
                        Telah Dinilai
                      </span>
                      </>
                    )}
                    {pelamarById.statusNilai === null && pelamarById.aspek_Nilai_Amanah === null || pelamarById.aspek_Komentar_Amanah === null || pelamarById.aspek_Nilai_Kompeten === null || pelamarById.aspek_Komentar_Kompeten === null || pelamarById.aspek_Nilai_Harmonis === null || pelamarById.aspek_Komentar_Harmonis === null || pelamarById.aspek_Nilai_Loyal === null || pelamarById.aspek_Komentar_Loyal === null || pelamarById.aspek_Nilai_Adaptif === null || pelamarById.aspek_Komentar_Adaptif === null || pelamarById.aspek_Nilai_Kolaboratif === null || pelamarById.aspek_Komentar_Kolaboratif === null || pelamarById.appearance_Nilai_Impact === null || pelamarById.appearance_Komentar_Impact === null || pelamarById.appearance_Nilai_OralCom === null ||  pelamarById.appearance_Komentar_OralCom === null ? ( // eslint-disable-line
                      <>
                      {pelamarById.statusNilai === null && (
                        <>
                          <span style={{backgroundColor: "#FEFCBF", color: "#D69E2E", marginLeft: "5px", borderRadius: "5px", padding: "5px 20px", fontWeight: "600"}}>
                            Belum Dinilai
                          </span>                        
                        </>
                      )}
                      </>
                    ):(
                      <>
                      {pelamarById.statusNilai === null && (
                        <>
                          <span style={{ backgroundColor: "#EBF8FF", color: "#4299E1", marginLeft: "5px", borderRadius: "5px", padding: "5px 20px", fontWeight: "600"}}>
                            Draft
                          </span>
                          <span></span>
                        </>
                      )}
                      </>
                    )}
                </p>
                
                {coba === null ? (
                  <>
                    <span style={{ color: "#718096", fontSize: "16px", fontWeight: "500"}}>
                      Belum Update Sistem
                    </span>
                  </>
                ) : (
                  <>
                   {coba.map((item, index) => (
                    <> 
                    <p style={{ color: "#718096", fontSize: "16px", fontWeight: "500", margin: '0px'}}>
                      {item}
                    </p>
                    </>
                   ))}
                  </>
                )}
                
              </div>
            </div>
            <hr />

            <Row>
              <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                <Row style={{ display: "flex", justifyContent: "space-between", marginTop: "30px", }}>
                  <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} style={{ marginBottom: "15px" }}>
                    <h3>Penilaian Interview</h3>
                  </Col>
                </Row>

                <Row style={{ justifyContent: "center" }}>
                  <div className="progress-nav mb-5 mt-5" style={{ width: "70%" }}>
                    <Progress value={progressbarvalue} style={{ height: "1px", top: "30%" }}/>
                    <Nav className="nav-pills progress-bar-tab custom-nav" role="tablist">
                      <NavItem>
                        <div>
                          <NavLink to="#" id="pills-gen-info-tab"
                            className={classnames(
                              {
                                active: activeTab === 1,
                                done: activeTab <= 4 && activeTab >= 0,
                              },
                              "rounded-pill"
                            )}
                            onClick={() => {
                              toggleTab(1, 0);
                              penilaianStatusAKHLAK();
                            }}
                            tag="button"
                            style={{ width: "3rem", height: "3rem" }}
                          >
                            1
                          </NavLink>
                          <p style={{ marginTop: "10px", marginBottom: "0px", position: "absolute", left: "-50px", color: "#319795", fontSize: "18px", fontWeight: "600", }} >
                            Detail Pertanyaan
                          </p>
                        </div>
                      </NavItem>
                      <NavItem>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                          <NavLink
                            to="#"
                            id="pills-gen-info-tab"
                            className={classnames(
                              {
                                active: activeTab === 2,
                                done: activeTab <= 4 && activeTab > 1,
                              },
                              "rounded-pill"
                            )}
                            onClick={() => {
                              toggleTab(2, 50);
                              penilaianStatusAKHLAK();
                            }}
                            tag="button"
                            style={{ width: "3rem", height: "3rem" }}
                          >
                            2
                          </NavLink>
                          <p style={{ marginTop: "10px", marginBottom: "0px", color: "#319795", fontSize: "18px", fontWeight: "600"}}>
                            Aspek AKHLAK
                          </p>
                        </div>
                      </NavItem>
                      <NavItem>
                        <div>
                          <NavLink
                            to="#"
                            id="pills-gen-info-tab"
                            className={classnames(
                              {
                                active: activeTab === 3,
                                done: activeTab <= 4 && activeTab > 2,
                              },
                              "rounded-pill"
                            )}
                            onClick={() => {
                              toggleTab(3, 100);
                              penilaianStatusNEW();
                            }}
                            tag="button"
                            style={{ width: "3rem", height: "3rem" }}
                          >
                            3
                          </NavLink>
                          <p style={{ marginTop: "10px", marginBottom: "0px", position: "absolute", right: "-50px", color: "#319795", fontSize: "18px", fontWeight: "600"}}>
                            Appearance New
                          </p>
                        </div>
                      </NavItem>
                    </Nav>
                  </div>

                  <TabContent activeTab={activeTab}>
                    <TabPane tabId={1}>
                      <div>
                        <Card style={{ border: "1px solid #e5e5e5", marginTop: "2rem"}}>
                          <CardBody>
                            <Row style={{ rowGap: "20px" }}>
                              {inputFields.map((item, index) => (
                                  <>
                                  {item.type === 'text&text' && (
                                    <>
                                      <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                                        <div style={{padding: '10px'}}>
                                          <Label className="form-label">Pertanyaan {index+1}</Label>
                                          <Input  style={{borderRadius: '8px'}} value={item.tanya} type='text' className="form-control" /> 
                                          <p style={{marginBottom: '0px', marginTop: '5px',fontWeight: '500', fontSize: '12px', color: '#718096'}}>Type: <span style={{fontWeight: '700'}}>Text</span></p>
                                        </div>
                                      </Col>
                                      <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                                        <div style={{padding: '10px'}}>
                                          <Label className="form-label">Jawaban</Label>
                                          <textarea value={item.jawab} style={{ padding: '10px 10px 80px', borderRadius: '8px' }} rows="6" className="form-control"></textarea> 
                                          <p style={{marginBottom: '0px', marginTop: '5px',fontWeight: '500', fontSize: '12px', color: '#718096'}}>Type: <span style={{fontWeight: '700'}}>Text</span></p>     
                                        </div>
                                      </Col>
                                    </>
                                  )}
                                  {item.type === 'text&video' && (
                                    <>
                                      <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                                        <div style={{padding: '10px'}}>
                                          <Label className="form-label">Pertanyaan {index+1}</Label>
                                          <Input style={{borderRadius: '8px'}} value={item.tanya} type='text' className="form-control" /> 
                                          <p style={{marginBottom: '0px', marginTop: '5px',fontWeight: '500', fontSize: '12px', color: '#718096'}}>Type: <span style={{fontWeight: '700'}}>Text</span></p>     
                                        </div>
                                      </Col>
                                      <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                                        <div style={{padding: '10px'}}>
                                          <Label className="form-label">Jawaban</Label>
                                          <div style={{display: 'flex', flexDirection: 'column'}}>
                                            <Button color="success" style={{width: '225px' , fontWeight: '700'}} onClick={() => {setNomor(index); openModalTranskrip();}} >Lihat Video</Button>
                                            <p style={{marginBottom: '0px', marginTop: '5px',fontWeight: '500', fontSize: '12px', color: '#718096'}}>Type: <span style={{fontWeight: '700'}}>Video</span></p>
                                          </div>
                                        </div>
                                      </Col>
                                    </>
                                  )}
                                  {item.type === 'video&video' && (
                                    <>
                                      <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                                        <div style={{padding: '10px', display: 'flex', flexDirection: 'column'}}>
                                          <Label className="form-label">Pertanyaan {index+1}</Label>
                                          <Button color="success" style={{fontWeight: '700'}} onClick={() => {setNomor(index); openModalLihatVideo();}} >Lihat Video</Button>
                                          <p style={{marginBottom: '0px', marginTop: '5px',fontWeight: '500', fontSize: '12px', color: '#718096'}}>Type: <span style={{fontWeight: '700'}}>Video</span></p>    
                                        </div>
                                      </Col>
                                      <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                                        <div style={{padding: '10px'}}>
                                          <Label className="form-label">Jawaban</Label>
                                          <div style={{display: 'flex', flexDirection: 'column'}}>
                                            <Button color="success" style={{width: '225px' , fontWeight: '700'}} onClick={() => {setNomor(index); openModalTranskrip();}} >Lihat Video</Button>
                                            <p style={{marginBottom: '0px', marginTop: '5px',fontWeight: '500', fontSize: '12px', color: '#718096'}}>Type: <span style={{fontWeight: '700'}}>Video</span></p>
                                          </div>
                                        </div>
                                      </Col>
                                    </>
                                  )}
                                  {item.type === 'pengantar' && (
                                    <>
                                      <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                                        <div style={{padding: '10px'}}>
                                          <Label className="form-label">Pengantar</Label>
                                          <Input style={{borderRadius: '8px'}} value={item.tanya} type='text' className="form-control" />  
                                          <p style={{marginBottom: '0px', marginTop: '5px',fontWeight: '500', fontSize: '12px', color: '#718096'}}>Type: <span style={{fontWeight: '700'}}>Pengantar</span></p>    
                                        </div>
                                      </Col>
                                      <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                                        <div style={{padding: '10px'}}>
                                          <Label className="form-label">{"."}</Label>
                                          <div>
                                            <Button color="success" style={{width: '300px', fontWeight: '700'}} onClick={()=> {setNomor(index); openModalLihatVideo();}}>Lihat Video</Button>     
                                          </div>
                                        </div>
                                      </Col>
                                    </>
                                  )}
                                  </>
                                )
                              )}
                            </Row>
                          </CardBody>
                        </Card>
                      </div>
                      <div style={{ marginTop: "30px", display: "flex", justifyContent: "flex-end", }}>
                        <button style={{ padding: "10px 45px", fontWeight: "700" }} type="button" className="btn btn-success"
                          onClick={() => {
                            toggleTab(activeTab + 1, 50);
                            penilaianStatusAKHLAK();
                          }}
                        >
                          Selanjutnya
                        </button>
                      </div>
                    </TabPane>

                    <TabPane tabId={2}>
                      <div>
                        <div style={{width: "440px", boxShadow: "0px 2px 5px #0000001a, 0px 0px 2px #00000033", padding: "15px 0px 15px 25px", borderRadius: "5px"}}>
                          <p style={{ fontSize: "18px", fontWeight: "700", color: "#2D3748", margin: "0"}}>
                            Hasil Penilaian:
                            {hasilPenilaianAKHLAK === "Belum Ada Hasil" && (
                              <>
                                <span style={{ backgroundColor: "#EDF2F7", color: "#A0AEC0", marginLeft: "5px", borderRadius: "5px", padding: "5px 20px"}}>
                                  Belum Ada Hasil
                                </span>
                              </>
                            )}
                            {hasilPenilaianAKHLAK === "Not Ready At This Time" && (
                              <>
                                <span style={{ backgroundColor: "#FFF5F5", color: "#E53E3E", marginLeft: "5px", borderRadius: "5px",  padding: "5px 20px"}}>
                                  Not Ready At This Time
                                </span>
                              </>
                            )}
                            {hasilPenilaianAKHLAK === "Ready With Development" && (
                              <>
                                <span style={{ backgroundColor: "#FEFCBF", color: "#D69E2E", marginLeft: "5px", borderRadius: "5px", padding: "5px 20px"}}>
                                  Ready With Development
                                </span>
                              </>
                            )}
                            {hasilPenilaianAKHLAK === "Ready Now" && (
                              <>
                                <span style={{ backgroundColor: "#F0FFF4", color: "#38A169", marginLeft: "5px", borderRadius: "5px", padding: "5px 20px"}} >
                                  Ready Now
                                </span>
                              </>
                            )}
                          </p>
                        </div>
                        <Card style={{border: "1px solid #e5e5e5", marginTop: "2rem"}}>
                          <CardBody style={{ padding: "1.5rem 1.5rem" }}>
                            <Row style={{ rowGap: "20px" }}>
                              <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                                <Card style={{ border: "1px solid #38B2AC", backgroundColor: "#F7FAFC", borderRadius: "8px" }} >
                                  <CardBody>
                                    <p style={{ color: "#2D3748", fontSize: "18px", fontWeight: "700"}}>
                                      {" "}
                                      1. Amanah{" "}
                                    </p>
                                    <p style={{ color: "#2D3748", fontSize: "18px", fontWeight: "400"}}>
                                      {" "}
                                      Memegang teguh kepercayaan yang diberikan{" "}
                                    </p>

                                    <div className="live-preview">
                                      <Accordion id="default-accordion-example">
                                        <AccordionItem>
                                          <h2 className="accordion-header" id="headingOne" >
                                            <button className={classnames( "accordion-button", {collapsed: !colAmanah})} type="button" onClick={kriteriaAmanah} style={{cursor: "pointer", backgroundColor: "#F7FAFC", color: "#38B2AC", border: "1px solid #38B2AC", boxShadow: "none", fontWeight: "700", fontSize: '18px'}} >
                                              Kriteria
                                            </button>
                                          </h2>

                                          <Collapse isOpen={colAmanah} className="accordion-collapse" id="collapseOne" >
                                            <div style={{ border: "1px solid #38B2AC", borderTop: "none"}} className="accordion-body" >
                                              <ul style={{ paddingLeft: "1rem", margin: "0", fontSize: '18px' }} >
                                                <li>
                                                  Memenuhi janji dan komitmen.
                                                </li>
                                                <li>
                                                  Bertanggung jawab atas tugas,
                                                  keputusan, dan tindakan yang
                                                  dilakukan.
                                                </li>
                                                <li>
                                                  Berpegang teguh kepada nilai
                                                  moral dan etika.
                                                </li>
                                              </ul>
                                            </div>
                                          </Collapse>
                                        </AccordionItem>
                                      </Accordion>
                                    </div>
                                  </CardBody>
                                </Card>
                              </Col>
                              <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                                <Label className="form-label" style={{fontSize: '18px', margin: '0'}}>Penilaian <span style={{color: '#E53E3E'}}>*</span></Label>
                                <div style={{ display: "flex", alignItems: "center", gap: "30px"}}>
                                  <div style={{ width: "80%" }}>
                                    <Slider
                                      value={amanahSlider}
                                      step={1}
                                      min={0}
                                      max={5}
                                      orientation="horizontal"
                                      onChange={(value) => {
                                        setAmanahSlider(value);
                                        penilaianStatusAKHLAK();
                                      }}
                                    />
                                  </div>
                                  <div>
                                    <div style={{ border: "1px solid #e5e5e5", borderRadius: "5px", width: "38px", height: "38px", padding: "5px", textAlign: "center", fontSize: '18px', fontWeight: '600'}}>
                                      {amanahSlider}
                                    </div>
                                  </div>
                                </div>

                                <Label className="form-label" style={{fontSize: '18px'}}>Komentar </Label>
                                <textarea style={{ padding: "10px 10px 80px", borderRadius: "8px", fontSize: '18px'}} rows="6" className="form-control" value={komentarAmanah} onChange={(event) => {setKomentarAmanah(event.target.value)}} maxLength={500}/>
                        
                              </Col>

                              <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                                <Card style={{ border: "1px solid #38B2AC", backgroundColor: "#F7FAFC", borderRadius: "8px" }} >
                                  <CardBody>
                                    <p style={{ color: "#2D3748", fontSize: "18px", fontWeight: "700"}}>
                                      {" "}
                                      2. Kompeten{" "}
                                    </p>
                                    <p style={{ color: "#2D3748", fontSize: "18px", fontWeight: "400"}}>
                                      {" "}
                                      Terus belajar dan mengembangkan
                                      kapabilitas{" "}
                                    </p>

                                    <div className="live-preview">
                                      <Accordion id="default-accordion-example">
                                        <AccordionItem>
                                          <h2 className="accordion-header" id="headingOne" >
                                            <button className={classnames( "accordion-button", {collapsed: !colKompeten})} type="button" onClick={kriteriaKompeten} style={{cursor: "pointer", backgroundColor: "#F7FAFC", color: "#38B2AC", border: "1px solid #38B2AC", boxShadow: "none", fontWeight: "700", fontSize: '18px'}} >
                                              Kriteria
                                            </button>
                                          </h2>

                                          <Collapse isOpen={colKompeten} className="accordion-collapse" id="collapseOne" >
                                            <div style={{ border: "1px solid #38B2AC", borderTop: "none"}} className="accordion-body" >
                                              <ul style={{ paddingLeft: "1rem", margin: "0", fontSize: '18px' }} >
                                                <li>
                                                  Meningkatkan kompetensi diri
                                                  untuk menjawab tantangan yang
                                                  selalu berubah.
                                                </li>
                                                <li>
                                                  Membantu orang lain belajar
                                                </li>
                                                <li>
                                                  Menyelesaikan tugas dengan
                                                  kualitas baik
                                                </li>
                                              </ul>
                                            </div>
                                          </Collapse>
                                        </AccordionItem>
                                      </Accordion>
                                    </div>
                                  </CardBody>
                                </Card>
                              </Col>
                              <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                                <Label className="form-label" style={{fontSize: '18px', margin: '0'}}>Penilaian <span style={{color: '#E53E3E'}}>*</span></Label>
                                <div style={{ display: "flex", alignItems: "center", gap: "30px"}}>
                                  <div style={{ width: "80%" }}>
                                    <Slider
                                      value={kompetenSlider}
                                      step={1}
                                      min={0}
                                      max={5}
                                      orientation="horizontal"
                                      onChange={(value) => {
                                        setKompetenSlider(value);
                                        penilaianStatusAKHLAK();
                                      }}
                                    />
                                  </div>
                                  <div>
                                    <div style={{ border: "1px solid #e5e5e5", borderRadius: "5px", width: "38px", height: "38px", padding: "5px", textAlign: "center", fontSize: '18px', fontWeight: '600'}}>
                                      {kompetenSlider}
                                    </div>
                                  </div>
                                </div>

                                <Label className="form-label" style={{fontSize: '18px'}}>Komentar </Label>
                                <textarea style={{ padding: "10px 10px 80px", borderRadius: "8px", fontSize: '18px'}} rows="6" className="form-control" value={komentarKompeten} onChange={(event) => {setKomentarKompeten(event.target.value)}} maxLength={500}
                                />
                              </Col>

                              <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                                <Card style={{ border: "1px solid #38B2AC", backgroundColor: "#F7FAFC", borderRadius: "8px" }} >
                                  <CardBody>
                                    <p style={{ color: "#2D3748", fontSize: "18px", fontWeight: "700"}}>
                                      {" "}
                                      3. Harmonis{" "}
                                    </p>
                                    <p style={{ color: "#2D3748", fontSize: "18px", fontWeight: "400"}}>
                                      {" "}
                                      Saling peduli dan menghargai perbedaan{" "}
                                    </p>

                                    <div className="live-preview">
                                      <Accordion id="default-accordion-example">
                                        <AccordionItem>
                                          <h2 className="accordion-header" id="headingOne" >
                                            <button className={classnames( "accordion-button", {collapsed: !colHarmonis})} type="button" onClick={kriteriaHarmonis} style={{cursor: "pointer", backgroundColor: "#F7FAFC", color: "#38B2AC", border: "1px solid #38B2AC", boxShadow: "none", fontWeight: "700", fontSize: '18px'}} >
                                              Kriteria
                                            </button>
                                          </h2>

                                          <Collapse isOpen={colHarmonis} className="accordion-collapse" id="collapseOne" >
                                            <div style={{ border: "1px solid #38B2AC", borderTop: "none"}} className="accordion-body" >
                                              <ul style={{ paddingLeft: "1rem", margin: "0", fontSize: '18px' }} >
                                                <li>
                                                  Menghargai setiap orang apapun
                                                  latar belakangnya.
                                                </li>
                                                <li>
                                                  Suka menolong orang lain.
                                                </li>
                                                <li>
                                                  Membangun lingkungan kerja
                                                  yang kondusif.
                                                </li>
                                              </ul>
                                            </div>
                                          </Collapse>
                                        </AccordionItem>
                                      </Accordion>
                                    </div>
                                  </CardBody>
                                </Card>
                              </Col>
                              <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                                <Label className="form-label" style={{fontSize: '18px', margin: '0'}}>Penilaian <span style={{color: '#E53E3E'}}>*</span></Label>
                                <div style={{ display: "flex", alignItems: "center", gap: "30px"}}>
                                  <div style={{ width: "80%" }}>
                                    <Slider
                                      value={harmonisSlider}
                                      step={1}
                                      min={0}
                                      max={5}
                                      orientation="horizontal"
                                      onChange={(value) => {
                                        setHarmonisSlider(value);
                                        penilaianStatusAKHLAK();
                                      }}
                                    />
                                  </div>
                                  <div>
                                    <div style={{ border: "1px solid #e5e5e5", borderRadius: "5px", width: "38px", height: "38px", padding: "5px", textAlign: "center", fontSize: '18px', fontWeight: '600'}}>
                                      {harmonisSlider}
                                    </div>
                                  </div>
                                </div>

                                <Label className="form-label" style={{fontSize: '18px'}}>Komentar </Label>
                                <textarea style={{ padding: "10px 10px 80px", borderRadius: "8px", fontSize: '18px'}} rows="6" className="form-control" onChange={(event) => { setKomentarHarmonis(event.target.value); }} value={komentarHarmonis} maxLength={500}
                                />
                              
                              </Col>

                              <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                                <Card style={{ border: "1px solid #38B2AC", backgroundColor: "#F7FAFC", borderRadius: "8px" }} >
                                  <CardBody>
                                    <p style={{ color: "#2D3748", fontSize: "18px", fontWeight: "700"}}>
                                      {" "}
                                      4. Loyal{" "}
                                    </p>
                                    <p style={{ color: "#2D3748", fontSize: "18px", fontWeight: "400"}}>
                                      {" "}
                                      Berbedikasi dan mengutamakan kepentingan
                                      bangsa dan negara{" "}
                                    </p>

                                    <div className="live-preview">
                                      <Accordion id="default-accordion-example">
                                        <AccordionItem>
                                          <h2 className="accordion-header" id="headingOne" >
                                            <button className={classnames( "accordion-button", {collapsed: !colLoyal})} type="button" onClick={kriteriaLoyal} style={{cursor: "pointer", backgroundColor: "#F7FAFC", color: "#38B2AC", border: "1px solid #38B2AC", boxShadow: "none", fontWeight: "700", fontSize: '18px'}} >
                                              Kriteria
                                            </button>
                                          </h2>

                                          <Collapse isOpen={colLoyal} className="accordion-collapse" id="collapseOne" >
                                            <div style={{ border: "1px solid #38B2AC", borderTop: "none"}} className="accordion-body" >
                                              <ul style={{ paddingLeft: "1rem", margin: "0", fontSize: '18px' }} >
                                                <li>
                                                  Menjaga nama baik sesama
                                                  karyawan, pimpinan, BUMN, dan
                                                  Negara
                                                </li>
                                                <li>
                                                  Rela berkorban untuk mencapai
                                                  tujuan yang lebih besar.
                                                </li>
                                                <li>
                                                  Patuh kepada pimpinan
                                                  sepanjang tidak bertentangan
                                                  dengan hukum dan etika.
                                                </li>
                                              </ul>
                                            </div>
                                          </Collapse>
                                        </AccordionItem>
                                      </Accordion>
                                    </div>
                                  </CardBody>
                                </Card>
                              </Col>
                              <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                                <Label className="form-label" style={{fontSize: '18px', margin: '0'}}>Penilaian <span style={{color: '#E53E3E'}}>*</span></Label>
                                <div style={{ display: "flex", alignItems: "center", gap: "30px"}}>
                                  <div style={{ width: "80%" }}>
                                    <Slider value={loyalSlider}
                                      step={1}
                                      min={0}
                                      max={5}
                                      orientation="horizontal"
                                      onChange={(value) => {
                                        setLoyalSlider(value);
                                        penilaianStatusAKHLAK();
                                      }}
                                    />
                                  </div>
                                  <div>
                                    <div style={{ border: "1px solid #e5e5e5", borderRadius: "5px", width: "38px", height: "38px", padding: "5px", textAlign: "center", fontSize: '18px', fontWeight: '600'}}>
                                      {loyalSlider}
                                    </div>
                                  </div>
                                </div>

                                <Label className="form-label" style={{fontSize: '18px'}}>Komentar </Label>
                                <textarea style={{ padding: "10px 10px 80px", borderRadius: "8px", fontSize: '18px'}}
                                  rows="6"
                                  className="form-control"
                                  onChange={(event) => {
                                    setKomentarLoyal(event.target.value);
                                  }}
                                  value={komentarLoyal}
                                  maxLength={500}
                                ></textarea>
                              
                              </Col>

                              <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                                <Card style={{ border: "1px solid #38B2AC", backgroundColor: "#F7FAFC", borderRadius: "8px" }} >
                                  <CardBody>
                                    <p style={{ color: "#2D3748", fontSize: "18px", fontWeight: "700"}}>
                                      {" "}
                                      5. Adaptif{" "}
                                    </p>
                                    <p style={{ color: "#2D3748", fontSize: "18px", fontWeight: "400"}}>
                                      {" "}
                                      Terus berinovasi dan antusias dalam
                                      menggerakkan ataupun menghadapi perubahan{" "}
                                    </p>

                                    <div className="live-preview">
                                      <Accordion id="default-accordion-example">
                                        <AccordionItem>
                                          <h2 className="accordion-header" id="headingOne" >
                                            <button className={classnames( "accordion-button", {collapsed: !colAdaptif})} type="button" onClick={kriteriaAdaptif} style={{cursor: "pointer", backgroundColor: "#F7FAFC", color: "#38B2AC", border: "1px solid #38B2AC", boxShadow: "none", fontWeight: "700", fontSize: '18px'}} >
                                              Kriteria
                                            </button>
                                          </h2>

                                          <Collapse isOpen={colAdaptif} className="accordion-collapse" id="collapseOne" >
                                            <div style={{ border: "1px solid #38B2AC", borderTop: "none"}} className="accordion-body" >
                                              <ul style={{ paddingLeft: "1rem", margin: "0", fontSize: '18px' }} >
                                                <li>
                                                  Cepat menyesuaikan diri untuk
                                                  menjadi lebih baik.
                                                </li>
                                                <li>
                                                  Terus-menerus melakukan
                                                  perbaikan mengikuti
                                                  perkembangan teknologi
                                                </li>
                                                <li>Bertindak proaktif</li>
                                              </ul>
                                            </div>
                                          </Collapse>
                                        </AccordionItem>
                                      </Accordion>
                                    </div>
                                  </CardBody>
                                </Card>
                              </Col>
                              <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                                <Label className="form-label" style={{fontSize: '18px', margin: '0'}}>Penilaian <span style={{color: '#E53E3E'}}>*</span></Label>
                                <div style={{ display: "flex", alignItems: "center", gap: "30px"}}>
                                  <div style={{ width: "80%" }}>
                                    <Slider
                                      value={adaptifSlider}
                                      step={1}
                                      min={0}
                                      max={5}
                                      orientation="horizontal"
                                      onChange={(value) => {
                                        setAdaptifSlider(value);
                                        penilaianStatusAKHLAK();
                                      }}
                                    />
                                  </div>
                                  <div>
                                    <div style={{ border: "1px solid #e5e5e5", borderRadius: "5px", width: "38px", height: "38px", padding: "5px", textAlign: "center", fontSize: '18px', fontWeight: '600'}}>
                                      {adaptifSlider}
                                    </div>
                                  </div>
                                </div>

                                <Label className="form-label" style={{fontSize: '18px'}}>Komentar </Label>
                                <textarea style={{ padding: "10px 10px 80px", borderRadius: "8px", fontSize: '18px'}}
                                  rows="6"
                                  className="form-control"
                                  onChange={(event) => {
                                    setKomentarAdaptif(event.target.value);
                                  }}
                                  value={komentarAdaptif}
                                  maxLength={500}
                                ></textarea>
                               
                              </Col>

                              <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                                <Card style={{ border: "1px solid #38B2AC", backgroundColor: "#F7FAFC", borderRadius: "8px" }} >
                                  <CardBody>
                                    <p style={{ color: "#2D3748", fontSize: "18px", fontWeight: "700"}}>
                                      {" "}
                                      6. Kolaboratif{" "}
                                    </p>
                                    <p style={{ color: "#2D3748", fontSize: "18px", fontWeight: "400"}}>
                                      {" "}
                                      Membangun kerja sama yang sinergis{" "}
                                    </p>

                                    <div className="live-preview">
                                      <Accordion id="default-accordion-example">
                                        <AccordionItem>
                                          <h2 className="accordion-header" id="headingOne" >
                                            <button className={classnames( "accordion-button", {collapsed: !colKolaboratif})} type="button" onClick={kriteriaKolaboratif} style={{cursor: "pointer", backgroundColor: "#F7FAFC", color: "#38B2AC", border: "1px solid #38B2AC", boxShadow: "none", fontWeight: "700", fontSize: '18px'}} >
                                              Kriteria
                                            </button>
                                          </h2>

                                          <Collapse isOpen={colKolaboratif} className="accordion-collapse" id="collapseOne" >
                                            <div style={{ border: "1px solid #38B2AC", borderTop: "none"}} className="accordion-body" >
                                              <ul style={{ paddingLeft: "1rem", margin: "0", fontSize: '18px' }} >
                                                <li>
                                                  Memberi kesempatan kepada
                                                  berbagai pihak untuk
                                                  berkontribusi
                                                </li>
                                                <li>
                                                  Terbuka dalam bekerja sama
                                                  untuk menghasilkan nilai
                                                  tambah.
                                                </li>
                                                <li>
                                                  Menggerakkan pemanfaatan
                                                  berbagai sumber daya untuk
                                                  tujuan bersama.
                                                </li>
                                              </ul>
                                            </div>
                                          </Collapse>
                                        </AccordionItem>
                                      </Accordion>
                                    </div>
                                  </CardBody>
                                </Card>
                              </Col>
                              <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                                <Label className="form-label" style={{fontSize: '18px', margin: '0'}}>Penilaian <span style={{color: '#E53E3E'}}>*</span></Label>
                                <div style={{ display: "flex", alignItems: "center", gap: "30px"}}>
                                  <div style={{ width: "80%" }}>
                                    <Slider
                                      value={kolaboratifSlider}
                                      step={1}
                                      min={0}
                                      max={5}
                                      orientation="horizontal"
                                      onChange={(value) => {
                                        setKolaboratifSlider(value);
                                        penilaianStatusAKHLAK();
                                      }}
                                    />
                                  </div>
                                  <div>
                                    <div style={{ border: "1px solid #e5e5e5", borderRadius: "5px", width: "38px", height: "38px", padding: "5px", textAlign: "center", fontSize: '18px', fontWeight: '600'}}>
                                      {kolaboratifSlider}
                                    </div>
                                  </div>
                                </div>

                                <Label className="form-label" style={{fontSize: '18px'}}>Komentar </Label>
                                <textarea style={{ padding: "10px 10px 80px", borderRadius: "8px", fontSize: '18px'}}
                                  rows="6"
                                  className="form-control"
                                  onChange={(event) => {
                                    setKomentarKolaboratif(event.target.value);
                                  }}
                                  value={komentarKolaboratif}
                                  maxLength={500}
                                ></textarea>
                               
                              </Col>

                              <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                <div style={{ backgroundColor: "#319795", borderRadius: "8px 8px 0 0", padding: "15px 0px"}}>
                                  <p style={{ color: "#ffffff", fontSize: "24px", fontWeight: "700", textAlign: "center", margin: "0"}}>
                                    Komentar Umum 
                                  </p>
                                </div>
                                <div style={{ display: "flex", border: "1px solid #319795", borderRadius: "0 0 8px 8px"}}>
                                  <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6} style={{ padding: "1.5rem" }}>
                                    <Label className="form-label" style={{fontSize: '18px'}}>
                                      Strength <span style={{color: '#E53E3E'}}>*</span>
                                    </Label>
                                    <textarea style={{ padding: "10px 10px 80px", borderRadius: "8px", fontSize: '18px' }} rows="6" className="form-control" placeholder="Tulis sesuatu..." onChange={(event) => {setAspekStrength(event.target.value)}} value={aspekStrength} maxLength={1500}
                                    />
                                    <span style={{color: '#E53E3E', fontStyle: 'italic'}}>* Wajib Diisi</span>
                                  </Col>
                                  <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6} style={{ padding: "1.5rem" }}>
                                    <Label className="form-label" style={{fontSize: '18px'}}>
                                      Weakness <span style={{color: '#E53E3E'}}>*</span>
                                    </Label>
                                    <textarea style={{ padding: "10px 10px 80px", borderRadius: "8px", fontSize: '18px' }} rows="6" className="form-control" placeholder="Tulis sesuatu..." onChange={(event) => { setAspekWeakness(event.target.value)}} value={aspekWeakness} maxLength={1500}
                                    />
                                    <span style={{color: '#E53E3E', fontStyle: 'italic'}}>* Wajib Diisi</span>
                                  </Col>
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </div>
                      <div style={{marginTop: "30px", display: "flex", justifyContent: "space-between"}}>
                        <button style={{ padding: "10px 45px", backgroundColor: "#ffffff", color: "#718096", border: "1px solid #A0AEC0", fontWeight: "700"}} type="button" className="btn btn-light "
                          onClick={() => {
                            toggleTab(activeTab - 1, 0);
                          }}
                        >
                          Kembali
                        </button>

                        <button style={{ padding: "10px 45px", fontWeight: "700" }} type="button" className="btn btn-success "
                          onClick={() => {
                            toggleTab(activeTab + 1, 100);
                            penilaianStatusNEW();
                          }}
                        >
                          Selanjutnya
                        </button>
                      </div>
                    </TabPane>

                    <TabPane tabId={3}>
                      <div>
                        <div style={{ width: "440px", boxShadow: "0px 2px 5px #0000001a, 0px 0px 2px #00000033", padding: "15px 0px 15px 25px", borderRadius: "5px"}}>
                          <p style={{ fontSize: "18px", fontWeight: "700", color: "#2D3748", margin: "0"}}>
                            Hasil Penilaian:
                            {hasilPenilaianNEW === "Belum Ada Hasil" && (
                              <>
                                <span style={{ backgroundColor: "#EDF2F7", color: "#A0AEC0", marginLeft: "5px", borderRadius: "5px", padding: "5px 20px"}}>
                                  Belum Ada Hasil
                                </span>
                              </>
                            )}
                            {hasilPenilaianNEW === "Not Ready At This Time" && (
                              <>
                                <span style={{ backgroundColor: "#FFF5F5", color: "#E53E3E", marginLeft: "5px", borderRadius: "5px", padding: "5px 20px"}}>
                                  Not Ready At This Time
                                </span>
                              </>
                            )}
                            {hasilPenilaianNEW === "Ready With Development" && (
                              <>
                                <span style={{ backgroundColor: "#FEFCBF", color: "#D69E2E", marginLeft: "5px", borderRadius: "5px", padding: "5px 20px"}}>
                                  Ready With Development
                                </span>
                              </>
                            )}
                            {hasilPenilaianNEW === "Ready Now" && (
                              <>
                                <span style={{ backgroundColor: "#F0FFF4", color: "#38A169", marginLeft: "5px", borderRadius: "5px", padding: "5px 20px"}}>
                                  Ready Now
                                </span>
                              </>
                            )}
                          </p>
                        </div>
                        <Card style={{ border: "1px solid #e5e5e5", marginTop: "2rem" }}>
                          <CardBody style={{ padding: "1.5rem 1.5rem" }}>
                            <Row style={{ rowGap: "20px" }}>
                              <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                                <Card style={{ border: "1px solid #38B2AC", backgroundColor: "#F7FAFC", borderRadius: "8px" }} >
                                  <CardBody>
                                    <p style={{ color: "#2D3748", fontSize: "18px", fontWeight: "700"}}>
                                      {" "}
                                      1. Impact{" "}
                                    </p>
                                    <p style={{ color: "#2D3748", fontSize: "18px", fontWeight: "400"}}>
                                      {" "}
                                      Menunjukkan kesan yang baik, mendapatkan
                                      perhatian, dan memiliki rasa percaya diri{" "}
                                    </p>


                                    <div className="live-preview">
                                      <Accordion id="default-accordion-example">
                                        <AccordionItem>
                                          <h2 className="accordion-header" id="headingOne" >
                                            <button className={classnames( "accordion-button", {collapsed: !colImpact})} type="button" onClick={kriteriaImpact} style={{cursor: "pointer", backgroundColor: "#F7FAFC", color: "#38B2AC", border: "1px solid #38B2AC", boxShadow: "none", fontWeight: "700", fontSize: '18px'}} >
                                              Kriteria
                                            </button>
                                          </h2>

                                          <Collapse isOpen={colImpact} className="accordion-collapse" id="collapseOne" >
                                            <div style={{ border: "1px solid #38B2AC", borderTop: "none"}} className="accordion-body" >
                                              <ul style={{ paddingLeft: "1rem", margin: "0", fontSize: '18px' }} >
                                                <li>
                                                  Menunjukkan penampilan yang
                                                  sesuai dengan situasi
                                                </li>
                                                <li>
                                                  Memperlihatkan kesan tubuh
                                                  yang proporsional
                                                </li>
                                                <li>Berpenampilan Menarik</li>
                                                <li>
                                                  Memperlihatkan kesan percaya
                                                  diri pada waktu berinteraksi
                                                </li>
                                                <li>
                                                  Menunjukkan kesan profesional
                                                  terhadap orang lain
                                                </li>
                                              </ul>
                                            </div>
                                          </Collapse>
                                        </AccordionItem>
                                      </Accordion>
                                    </div>
                                  </CardBody>
                                </Card>
                              </Col>
                              <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                                <Label className="form-label" style={{fontSize: '18px', margin: '0'}}>Penilaian <span style={{color: '#E53E3E'}}>*</span></Label>
                                <div style={{ display: "flex", alignItems: "center", gap: "30px"}}>
                                  <div style={{ width: "80%" }}>
                                    <Slider
                                      value={impactSlider}
                                      step={1}
                                      min={0}
                                      max={5}
                                      orientation="horizontal"
                                      onChange={(value) => {
                                        setImpactSlider(value);
                                        penilaianStatusNEW();
                                      }}
                                    />
                                  </div>
                                  <div>
                                    <div style={{ border: "1px solid #e5e5e5", borderRadius: "5px", width: "38px", height: "38px", padding: "5px", textAlign: "center", fontSize: '18px', fontWeight: '600'}}>
                                      {impactSlider}
                                    </div>
                                  </div>
                                </div>

                                <Label className="form-label" style={{fontSize: '18px'}}>Komentar </Label>
                                <textarea style={{ padding: "10px 10px 80px", borderRadius: "8px", fontSize: '18px'}}
                                  rows="6"
                                  className="form-control"
                                  onChange={(event) => {
                                    setKomentarImpact(event.target.value);
                                  }}
                                  value={komentarImpact}
                                  maxLength={500}
                                ></textarea>
                               
                               
                              </Col>

                              <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                                <Card style={{ border: "1px solid #38B2AC", backgroundColor: "#F7FAFC", borderRadius: "8px" }} >
                                  <CardBody>
                                    <p style={{ color: "#2D3748", fontSize: "18px", fontWeight: "700"}}>
                                      {" "}
                                      2. Oral Communication{" "}
                                    </p>
                                    <p style={{ color: "#2D3748", fontSize: "18px", fontWeight: "400"}}>
                                      {" "}
                                      Kemampuan untuk menerima dan memberikan
                                      informasi secara efektif{" "}
                                    </p>

                                    <div className="live-preview">
                                      <Accordion id="default-accordion-example">
                                        <AccordionItem>
                                          <h2 className="accordion-header" id="headingOne" >
                                            <button className={classnames( "accordion-button", {collapsed: !colOral})} type="button" onClick={kriteriaOral} style={{cursor: "pointer", backgroundColor: "#F7FAFC", color: "#38B2AC", border: "1px solid #38B2AC", boxShadow: "none", fontWeight: "700", fontSize: '18px'}} >
                                              Kriteria
                                            </button>
                                          </h2>

                                          <Collapse isOpen={colOral} className="accordion-collapse" id="collapseOne" >
                                            <div style={{ border: "1px solid #38B2AC", borderTop: "none"}} className="accordion-body" >
                                              <ul style={{ paddingLeft: "1rem", margin: "0", fontSize: '18px' }} >
                                                <li>
                                                  Berbicara dalam tempo, volume,
                                                  nada, dan tekanan suara yang
                                                  tepat
                                                </li>
                                                <li>
                                                  Menyampaikan informasi secara
                                                  sistematis dan terorganisir
                                                </li>
                                                <li>
                                                  Berbicara dengan jelas dan
                                                  singkat
                                                </li>
                                                <li>
                                                  Menggunakan komunikasi
                                                  nonverbal (kotak mata dan
                                                  bahasa tubuh) yang sesuai
                                                </li>
                                              </ul>
                                            </div>
                                          </Collapse>
                                        </AccordionItem>
                                      </Accordion>
                                    </div>
                                  </CardBody>
                                </Card>
                              </Col>
                              <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                                <Label className="form-label" style={{fontSize: '18px', margin: '0'}}>Penilaian <span style={{color: '#E53E3E'}}>*</span></Label>
                                <div style={{ display: "flex", alignItems: "center", gap: "30px"}}>
                                  <div style={{ width: "80%" }}>
                                    <Slider
                                      value={oralSlider}
                                      step={1}
                                      min={0}
                                      max={5}
                                      orientation="horizontal"
                                      onChange={(value) => {
                                        setOralSlider(value);
                                        penilaianStatusNEW();
                                      }}
                                    />
                                  </div>
                                  <div>
                                    <div style={{ border: "1px solid #e5e5e5", borderRadius: "5px", width: "38px", height: "38px", padding: "5px", textAlign: "center", fontSize: '18px', fontWeight: '600'}}>
                                      {oralSlider}
                                    </div>
                                  </div>
                                </div>

                                <Label className="form-label" style={{fontSize: '18px'}}>Komentar </Label>
                                <textarea style={{ padding: "10px 10px 80px", borderRadius: "8px", fontSize: '18px'}}
                                  rows="6"
                                  className="form-control"
                                  onChange={(event) => {
                                    setKomentarOral(event.target.value);
                                  }}
                                  value={komentarOral}
                                  maxLength={500}
                                ></textarea>
                                
                              </Col>

                              <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                <div style={{ backgroundColor: "#319795", borderRadius: "8px 8px 0 0", padding: "15px 0px"}}>
                                  <p style={{ color: "#ffffff", fontSize: "24px", fontWeight: "700", textAlign: "center", margin: "0"}}>
                                    Komentar Umum
                                  </p>
                                </div>
                                <div style={{ display: "flex", border: "1px solid #319795", borderRadius: "0 0 8px 8px"}}>
                                  <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6} style={{ padding: "1.5rem" }}>
                                    <Label className="form-label" style={{fontSize: '18px'}}>
                                      Strength <span style={{color: '#E53E3E'}}>*</span>
                                    </Label>
                                    <textarea style={{ padding: "10px 10px 80px", borderRadius: "8px", fontSize: '18px' }} rows="6" 
                                      className="form-control"
                                      placeholder="Tulis sesuatu..."
                                      onChange={(event) => {
                                        setAppearanceStrength(
                                          event.target.value
                                        );
                                      }}
                                      value={appearanceStrength}
                                      maxLength={1500}
                                    ></textarea>
                                    <span style={{color: '#E53E3E', fontStyle: 'italic'}}>* Wajib Diisi</span>
                                  </Col>
                                  <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6} style={{ padding: "1.5rem" }}>
                                    <Label className="form-label" style={{fontSize: '18px'}}>
                                      Weakness <span style={{color: '#E53E3E'}}>*</span>
                                    </Label>
                                    <textarea style={{ padding: "10px 10px 80px", borderRadius: "8px", fontSize: '18px' }} rows="6"  className="form-control" placeholder="Tulis sesuatu..."
                                    onChange={(event) => {
                                      setAppearanceWeakness(
                                        event.target.value
                                      );
                                    }}
                                    value={appearanceWeakness}
                                    maxLength={1500}
                                    ></textarea>
                                    <span style={{color: '#E53E3E', fontStyle: 'italic'}}>* Wajib Diisi</span>
                                  </Col>
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </div>
                      <div style={{ marginTop: "30px", display: "flex", justifyContent: "space-between"}}>
                        <button style={{ padding: "10px 45px", backgroundColor: "#ffffff", color: "#718096", border: "1px solid #A0AEC0", fontWeight: "700"}} type="button" className="btn btn-light "
                          onClick={() => {
                            toggleTab(activeTab - 1, 0);
                          }}
                        >
                          Kembali
                        </button>
                        <div style={{ display: "flex", justifyContent: "space-between", gap: "30px"}}>
                          <Button style={{ padding: "10px 35px", backgroundColor: "#4299E1", border: "1px solid #4299E1", fontWeight: "700"}} type="button" className="btn btn-info "
                            onClick={() => {
                              // createLogDraft()
                              setStatusNilai(null);
                              openModalDraft();
                            }}
                            // disabled={disablerSelesai}
                          >
                            Simpan Draft
                          </Button>
                          <Button style={{ padding: "10px 45px", fontWeight: "700" }} type="button" className="btn btn-success "
                            onClick={() => {
                              // createLog();
                              setStatusNilai("selesai");
                              openModalKirim();
                              // getTimenow();
                            }}
                            // disabled={disablerSelesai}
                          >
                            Kirim
                          </Button>
                        </div>
                      </div>
                    </TabPane>
                  </TabContent>
                </Row>
              </Col>
            </Row>
          </>
        )}

      </div>

      {/* ----------- MODAL DOWNLOAD ---------- */}
      <Modal size="xl" isOpen={modalDownload} toggle={() => { openModalDownload(); }} centered >
        <ModalBody >

          <div style={{ padding: '20px 30px' }} ref={ref}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
              <div>
                <p style={{ color: '#1A202C', fontWeight: '700', fontSize: '16px', marginBottom: '10px' }}>PT. TELEKOMUNIKASI INDONESIA, TBK</p>
                <p style={{ color: '#E53E3E', fontWeight: '700', fontSize: '16px', margin: '0' }}>Assessment Center Indonesia</p>
              </div>
              <img style={{ width: '170px', height: '113px' }} src={logoTelkom} />
            </div>
            <hr />
            <div style={{ textAlign: 'center', margin: '40px 0' }}>
              <p style={{ color: '#1A202C', fontWeight: '700', fontSize: '20px', margin: '0' }}>Lampiran Jawaban</p>
              <p style={{ color: '#319795', fontWeight: '700', fontSize: '20px' }}>{judulto}</p>
            </div>
            <div style={{ marginBottom: '20px' }}>
              <Row>
                <Col style={{
                  width:
                    '130px'
                }} xxl={1} xl={1} lg={1} md={1} sm={1} xs={1}>
                  <p style={{ fontSize: '14px', fontWeight: '500', color: '#1A202C' }}>Nama Pelamar</p>
                </Col>
                <Col style={{
                  width:
                    'max-content', fontSize: '14px', fontWeight: '500', color: '#1A202C'
                }} xxl={1} xl={1} lg={1} md={1} sm={1} xs={1}>
                  <p>:</p>
                </Col>
                <Col style={{ width: 'max-content' }} xxl={1} xl={1} lg={1} md={1} sm={1} xs={1}>
                  <p style={{ fontSize: '14px', fontWeight: '500', color: '#1A202C' }} >{namato}</p>
                </Col>
              </Row>
              <Row>
                <Col style={{
                  width:
                    '130px'
                }} xxl={1} xl={1} lg={1} md={1} sm={1} xs={1}>
                  <p style={{ fontSize: '14px', fontWeight: '500', color: '#1A202C' }}>Email</p>
                </Col>
                <Col style={{
                  width:
                    'max-content', fontSize: '14px', fontWeight: '500', color: '#1A202C'
                }} xxl={1} xl={1} lg={1} md={1} sm={1} xs={1}>
                  <p>:</p>
                </Col>
                <Col style={{ width: 'max-content' }} xxl={1} xl={1} lg={1} md={1} sm={1} xs={1}>
                  <p style={{ fontSize: '14px', fontWeight: '500', color: '#1A202C' }} >{emailto}</p>
                </Col>
              </Row>
              <Row>
                <Col style={{
                  width:
                    '130px'
                }} xxl={1} xl={1} lg={1} md={1} sm={1} xs={1}>
                  <p style={{ fontSize: '14px', fontWeight: '500', color: '#1A202C' }}>Kode</p>
                </Col>
                <Col style={{
                  width:
                    'max-content', fontSize: '14px', fontWeight: '500', color: '#1A202C'
                }} xxl={1} xl={1} lg={1} md={1} sm={1} xs={1}>
                  <p>:</p>
                </Col>
                <Col style={{ width: 'max-content' }} xxl={1} xl={1} lg={1} md={1} sm={1} xs={1}>
                  <p style={{ fontSize: '14px', fontWeight: '500', color: '#1A202C' }} >{kodeto}</p>
                </Col>
              </Row>
              <Row>
                <Col style={{
                  width:
                    '130px'
                }} xxl={1} xl={1} lg={1} md={1} sm={1} xs={1}>
                  <p style={{ fontSize: '14px', fontWeight: '500', color: '#1A202C' }}>Tanggal Submit</p>
                </Col>
                <Col style={{
                  width:
                    'max-content', fontSize: '14px', fontWeight: '500', color: '#1A202C'
                }} xxl={1} xl={1} lg={1} md={1} sm={1} xs={1}>
                  <p>:</p>
                </Col>
                <Col style={{ width: 'max-content' }} xxl={1} xl={1} lg={1} md={1} sm={1} xs={1}>
                  <p style={{ fontSize: '14px', fontWeight: '500', color: '#1A202C' }} >{submitto}</p>
                </Col>
              </Row>
            </div>

            <div className="table-responsive" style={{ borderRadius: '8px 8px 0 0', marginBottom: '50px' }}>
              <Table className="">
                <thead style={{ backgroundColor: '#319795', color: 'white' }}>
                  <tr>
                    <th style={{ width: "50px", textAlign: 'center' }} scope="col">No</th>
                    <th scope="col">Pertanyaan</th>
                    <th scope="col">Jawaban</th>
                  </tr>
                </thead>
                <tbody >
                  {inputFields.map((item, index) => (
                    <>
                      <tr style={{ borderRight: '1px solid #00000020', borderLeft: '1px solid #00000020' }} key={index}>
                        <td style={{ textAlign: 'center' }}>{index + 1}</td>
                        <td style={{ width: '30%' }} >
                          {(item.tanya).length > 300 ? `Soal Video` : item.tanya}
                        </td>
                        <td >{item.jawab}</td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </Table>
            </div>

            <hr />
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <p style={{ fontSize: '14px', fontWeight: '600', color: '#1A202C', marginBottom: '20px' }}>Diunduh pada : <span>{tanggalSekarang}</span></p>
            </div>
          </div>


        </ModalBody>
        <ModalFooter style={{ justifyContent: 'space-evenly' }}>
          <button style={{
            padding: '0.5rem 3.0rem', backgroundColor: "#ffffff",
            color: "#718096",
            border: "1px solid #A0AEC0",
            fontWeight: "700",
            borderRadius: "6px",
          }} type="button" className="btn btn-light" onClick={() => { openModalDownload(); checkJWT(); }}>Tutup</button>
          <button style={{
            padding: '0.5rem 3.0rem',

            fontWeight: "700",
            borderRadius: "6px",
          }} type="button" className="btn btn-success" onClick={() => { handlePrint(); checkJWT(); }} >Download</button>
        </ModalFooter>
      </Modal>

      {/* ---------- MODAL TRANSKRIP ----------- */}
      <Modal size="xl" isOpen={modalTranskrip} toggle={() => { openModalTranskrip(); }} centered>
        <ModalHeader className="modal-title" id="myModalLabel" toggle={() => { openModalTranskrip(); }}>
          Transkrip Suara
        </ModalHeader>
        {step === 1 && (
          <>
            {inputFields.map((item, index) => (
              <>
                {nomor === index && (
                  <>
                    <ModalBody style={{ display: "flex", flexDirection: 'column', alignItems: 'center' }}>
                      <div style={{ width: "50%", display: "flex", justifyContent: 'center' }}>
                        <video
                          style={{ width: "480px", borderRadius: "8px" }}
                          controls
                        >
                          <source src={item.file} type="video/mp4" />
                          Your browser does not support HTML5 video.
                        </video>
                      </div>
                      <div style={{ width: "85%", margin: '45px 0px' }}>
                        <p style={{ fontWeight: "700", color: "#4A5568", fontSize: "14px", marginBottom: '15px' }}>Speech To Text :</p>
                        <p>{item.jawab}</p>
                      </div>
                      <div style={{ width: "85%", }}>
                        <p style={{ fontWeight: "700", color: "#4A5568", fontSize: "14px", marginBottom: '15px' }}>Edit Speech to Text :</p>
                        <textarea style={{ width: '100%', height: '300px' }} value={item.note} onChange={event => { handleChangeNote(event, index) }} />
                      </div>
                    </ModalBody>
                  </>
                )}
              </>
            ))}
          </>
        )}
        <ModalFooter style={{ justifyContent: "center" }}>
          <Button style={{ padding: "0.5rem 3.0rem", backgroundColor: "#ffffff", color: "#718096", border: "1px solid #A0AEC0", fontWeight: "700", borderRadius: "6px", }} className="btn btn-light"
            onClick={() => {
              openModalTranskrip();
            }}
          >
            Tutup
          </Button>
          <Button style={{ padding: "0.5rem 3.0rem", borderRadius: "6px", }} color="success"
            onClick={() => {
              openModalTranskrip();
              updateNote()
            }}
          >
            Edit
          </Button>
        </ModalFooter>
      </Modal>

      {/* ---------- MODAL LIHAT VIDEO ----------- */}
      <Modal size="xl" isOpen={modalLihatVideo} toggle={() => { openModalLihatVideo(); }} centered>

        {step === 1 && (
          <>
            {inputFields.map((item, index) => (
              <>
                {nomor === index && (
                  <>
                    {item.type === "pengantar" && (
                      <>
                        <ModalHeader className="modal-title" id="myModalLabel" toggle={() => { openModalLihatVideo(); }}>
                          {item.tanya}
                        </ModalHeader>
                        <ModalBody style={{ display: "flex", justifyContent: 'center' }}>
                          <div style={{ width: "50%", display: "flex", justifyContent: 'center' }}>
                            <video
                              style={{ width: "480px", borderRadius: "8px" }}
                              controls
                            >
                              <source src={item.file} type="video/mp4" />
                              Your browser does not support HTML5 video.
                            </video>
                          </div>
                        </ModalBody>
                      </>
                    )}
                    {item.type === "video&video" && (
                      <>
                        <ModalHeader className="modal-title" id="myModalLabel" toggle={() => { openModalLihatVideo(); }}>
                          Video Soal
                        </ModalHeader>
                        <ModalBody style={{ display: "flex", justifyContent: 'center' }}>
                          <div style={{ width: "50%", display: "flex", justifyContent: 'center' }}>
                            <video
                              style={{ width: "480px", borderRadius: "8px" }}
                              controls
                            >
                              <source src={item.tanya} type="video/mp4" />
                              Your browser does not support HTML5 video.
                            </video>
                          </div>
                        </ModalBody>
                      </>
                    )}
                  </>
                )}
              </>
            ))}
          </>
        )}
        <ModalFooter style={{ justifyContent: "center" }}>
          <Button
            style={{
              padding: "0.5rem 3.0rem", backgroundColor: "#ffffff",
              color: "#718096",
              border: "1px solid #A0AEC0",
              fontWeight: "700",
              borderRadius: "6px",
            }}
            className="btn btn-light"
            onClick={() => {
              openModalLihatVideo();
            }}
          >
            Tutup
          </Button>
        </ModalFooter>
      </Modal>

      {/* ---------- MODAL CATATAN ----------- */}
      <Modal isOpen={modalCatatan} toggle={() => { openModalCatatan() }} centered >

        <ModalHeader className="modal-title" id="myModalLabel" toggle={() => { openModalCatatan() }}>
          <p style={{ margin: '0px', color: '#2A4365', fontWeight: '700', fontSize: '20px' }}></p>Catatan Jawaban Video
        </ModalHeader>

        {step === 1 && (
          <>
            {inputFields.map((item, index) => (
              <>
                {nomor === index && (
                  <>
                    <ModalBody>
                      <Label className="form-label">Beri Catatan</Label>
                      <textarea style={{ padding: '10px 10px 80px' }} rows="6" className="form-control" value={item.note} onChange={(event) => handleText(event, index)} maxLength={250}></textarea>
                    </ModalBody>
                  </>
                )}
              </>
            ))}
          </>
        )}

        <ModalFooter style={{ justifyContent: 'space-between' }}>
          <Button style={{
            padding: '0.5rem 3.0rem', backgroundColor: "#ffffff",
            color: "#718096",
            border: "1px solid #A0AEC0",
            fontWeight: "700",
            borderRadius: "6px",
          }} className="btn btn-light" onClick={() => { openModalCatatan(); checkJWT(); }}>Tutup</Button>
          <Button style={{
            padding: '0.5rem 3.0rem',
            fontWeight: "700",
            borderRadius: "6px",
          }} className="btn btn-success" onClick={() => { openModalCatatan(); checkJWT(); }} >Simpan</Button>
        </ModalFooter>

      </Modal>

      {/* ------- MODAL DELETE ------- */}
      <Modal isOpen={modalDelete} toggle={() => { openModalDelete(); }} centered >

        <ModalBody >

          {ids.length !== 0 ? (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', overflow: 'auto' }}>
              <img src={deletePhoto} />
              <h5 style={{ textAlign: 'center', marginTop: '30px' }}>Yakin Hapus Data?</h5>
              <p style={{ padding: '0 70px', textAlign: 'center' }}>Apakah anda yakin untuk menghapus data ini?</p>
            </div>
          ) : (
            <> <h5 style={{ textAlign: 'center', marginTop: '30px' }}>Tidak ada data yang dipilih</h5>
            </>
          )}

        </ModalBody>

        <ModalFooter style={{ justifyContent: 'space-evenly' }}>
          <Button style={{
            padding: '0.5rem 2.0rem', backgroundColor: "#ffffff",
            color: "#718096",
            border: "1px solid #A0AEC0",
            fontWeight: "700",
            borderRadius: "6px",
          }} className="btn btn-light" onClick={() => { setIds([]); setModalDelete(false); checkJWT(); }} >Batal</Button>

          {ids.length !== 0 &&
            <Button style={{
              padding: '0.5rem 2.0rem',
              fontWeight: "700",
              borderRadius: "6px",
            }} onClick={() => { deletePeserta(); setModalDelete(false); checkJWT(); }} className="btn btn-success" >Yakin</Button>

          }

        </ModalFooter>

      </Modal>

      {/* ---------- MODAL SUCCESS DELETE ---------- */}
      <Modal size="md" isOpen={modalSuccessDelete} toggle={() => { openModalSuccessDelete() }} centered>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '90px 10px' }}>
          <img src={success} />
          <h5 style={{ textAlign: 'center', margin: '20px' }}>Data Peserta Berhasil Dihapus</h5>
        </div>
      </Modal>

      {/* ---------- MODAL JWT -------- */}
      <Modal isOpen={modalJWT} centered >
        <ModalBody style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <img src={Timeout} />
          <h5 style={{ textAlign: 'center', marginTop: '30px', fontSize: '24px', fontWeight: '700', color: '#2A4365' }}>Maaf, Silahkan Login Kembali</h5>
          <p style={{ padding: '0 45px', textAlign: 'center', fontSize: '16px', color: '#4A5568', fontWeight: '400' }}>Sesi Anda sudah kadaluarsa, Jangan khawatir... Click tombol di bawah ini untuk login kembali :)</p>
        </ModalBody>
        <ModalFooter style={{ justifyContent: 'center' }}>
          <Button style={{ padding: '0.5rem 3.0rem' }} className="btn btn-success" onClick={() => { window.localStorage.clear(); window.sessionStorage.clear(); window.location.reload(); window.location.href = "/login-admin"; }} >Login</Button>
        </ModalFooter>
      </Modal>

      {/* MEI 2023 ---------- MODAL DETAIL PELAMAR ----------- */}
      <Modal size="sm" isOpen={modalDetailPelamar} toggle={() => {openModalDetailPelamar();}} centered>
        <ModalHeader className="modal-title" id="myModalLabel" toggle={() => { openModalDetailPelamar()}}>
          <p style={{ margin: "0px", color: "#2A4365", fontSize: "20px", fontWeight: "700"}}>
            Detail Pelamar
          </p>
        </ModalHeader>

        <ModalBody>
          <p style={{ fontWeight: "700", color: "#4A5568", fontSize: "14px", margin: "0"}}>
            Nama
          </p>
          <p style={{ fontWeight: "500", color: "#718096" }}>{namato}</p>

          <p style={{ fontWeight: "700", color: "#4A5568", fontSize: "14px", margin: "0"}}>
            Email
          </p>
          <p style={{ fontWeight: "500", color: "#718096" }}>{emailto}</p>

          <p style={{ fontWeight: "700", color: "#4A5568", fontSize: "14px", margin: "0"}}>
            No HP
          </p>
          {noHPto ===""? (  
          <p style={{ fontWeight: "500", color: "#718096" }}>---</p> ): 
          (   <p style={{ fontWeight: "500", color: "#718096" }}>{noHPto}</p>)}
        
          <p style={{ fontWeight: "700", color: "#4A5568", fontSize: "14px", margin: "0"}}>
            Alamat
          </p>
          {alamatto ===""? (  
          <p style={{ fontWeight: "500", color: "#718096" }}>---</p> ): 
          (   <p style={{ fontWeight: "500", color: "#718096" }}>{alamatto}</p>)}
        
          <p style={{ fontWeight: "700", color: "#4A5568", fontSize: "14px", margin: "0"}}>
            Tanggal Lahir
          </p>
          {tanggalLahirto ===""? (  
          <p style={{ fontWeight: "500", color: "#718096" }}>---</p> ): 
          (   <p style={{ fontWeight: "500", color: "#718096" }}>{tanggalLahirto}</p>)}
        
        </ModalBody>
      </Modal>

      {/* ---------- MODAL SELESAI PENILAIAN ----------- */}
      <Modal isOpen={modalKirim} toggle={() => { openModalKirim()}} centered>
        <ModalBody style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "40px", }} >
          <img src={DraftPenilaian} alt="DraftPenilaian"/>
          <h5 style={{ textAlign: "center", marginTop: "30px", color: "#2A4365", fontSize: "24px", fontWeight: "700", }}>
            Yakin Kirim Data?
          </h5>
          <p style={{ padding: "0 70px", textAlign: "center", margin: "0px", fontSize: "16px", fontWeight: "400", color: "#4A5568", }}>
            Anda tidak dapat memberikan penilaian kembali saat semuanya sudah selesai di nilai
          </p>
        </ModalBody>
        <ModalFooter style={{ justifyContent: "space-evenly" }}>
          <Button style={{ padding: "0.5rem 3.0rem", backgroundColor: "#ffffff", color: "#718096", border: "1px solid #A0AEC0", fontWeight: "700",
              borderRadius: "6px", }} className="btn btn-light" onClick={() => {openModalKirim();}}>
            Batal
          </Button>
          <Button style={{ padding: "0.5rem 3.0rem", fontWeight: "700",  borderRadius: "6px" }} className="btn btn-success" onClick={() => { penilaianPeserta();}}>
            Yakin
          </Button>
        </ModalFooter>
      </Modal>

      {/* ---------- MODAL DRAFT ----------- */}
      <Modal isOpen={modalDraft} toggle={() => {openModalDraft()}} centered>
        <ModalBody style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "40px"}}>
          <img src={FotoModal} alt="FotoModal" />
          <h5 style={{ textAlign: "center", marginTop: "30px", color: "#2A4365", fontSize: "24px", fontWeight: "700"}}>
            Anda Ingin Simpan Draft Penilaian?
          </h5>
          <p style={{ padding: "0 70px", textAlign: "center", margin: "0px", fontSize: "16px", fontWeight: "400", color: "#4A5568"}}>
            Anda dapat mengirim data penilaian hasil interview kembali
          </p>
        </ModalBody>
        <ModalFooter style={{ justifyContent: "space-evenly" }}>
          <Button style={{ padding: "0.5rem 3.0rem", backgroundColor: "#ffffff", color: "#718096", border: "1px solid #A0AEC0", fontWeight: "700", borderRadius: "6px"}} className="btn btn-light"
            onClick={() => {
              openModalDraft();
            }}
          >
            Batal
          </Button>
          <Button style={{ padding: "0.5rem 3.0rem", fontWeight: "700", borderRadius: "6px", }} className="btn btn-success"
            onClick={() => {
              penilaianPeserta();
            }}
          >
            Yakin 
          </Button>
        </ModalFooter>
      </Modal>

      {/* ------- MODAL Nilai ERROR ------- */}
      {/* <Modal isOpen={errorMenilai} toggle={() => {openModalErrorMenilai();}} centered > */}
      <Modal isOpen={errorMenilai} toggle={() => {}} centered >

        <ModalBody style={{display: 'flex', flexDirection: 'column', alignItems: 'center', overflow: 'auto'}}>

            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>  
              <img src={soalSalah} style={{width: '170px'}} alt="soalSalah"/>
              <h5 style={{textAlign: 'center', padding: '0 70px'}}>Maaf, {errorKonten} </h5>
              <p style={{padding: '0 70px', textAlign: 'center', margin:'0'}}>Silahkan check kembali...</p>
            </div> 

        </ModalBody>

        <ModalFooter style={{justifyContent: 'space-evenly'}}>
            
          <Button style={{ padding: '0.5rem 2.0rem'}} onClick={() => {setErrorMenilai(false)}} className="btn btn-success" >Baik</Button>
          
        </ModalFooter>

      </Modal>

      <Modal size="md" isOpen={modalSuccess} toggle={() => { openModalSuccess() }} centered>
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', margin: '90px 10px' }}>
          <img src={success} style={{ textAlign: 'center' }} />
          <h5 style={{ textAlign: 'center', margin: '20px' }}>Data Nilai Berhasil Diubah</h5>
        </div>
      </Modal>


    </React.Fragment>
  );
};

export default I5;
