import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Col, Container, Input, Label, Row, Button } from 'reactstrap';
import AuthSlider from '../authCarousel';

import logoTelkom from "../../../assets/images/telkom/logoACI.png";

const CoverSignIn = () => {
    document.title = "Cover SignIn";

    const [changeSidebar, setChangeSidebar] = React.useState(false);

    const handleChangePerserta = () => {
        setChangeSidebar(true)
        localStorage.setItem("item1", true);
    };

    return (
        <React.Fragment>

            {/* dihapus auth-bg-cover */}
            <div className="auth-page-wrapper py-5 d-flex justify-content-center align-items-center min-vh-100">
                <div className="bg-overlay"></div>

                <div className="auth-page-content overflow-hidden pt-lg-5">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <Card className="overflow-hidden">
                                    <Row className="g-0">
                                        <AuthSlider />

                                        <Col lg={6}>
                                            <div style={{ paddingRight: '3rem', paddingLeft: '3rem' }}>
                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <img src={logoTelkom} alt="" width="30%" />
                                                </div>

                                                <Col lg={10} style={{ marginTop: '125px', padding: '25px', boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.08)', borderRadius: '8px' }}>
                                                    <form action="/p1">

                                                        <div className="mb-3">
                                                            <Label htmlFor="kodeInterview" className="form-label">Kode Interview</Label>
                                                            <Input type="text" className="form-control" id="kodeInterview" placeholder="Masukkan Kode Interview" required />
                                                        </div>

                                                        <div className="mt-4">
                                                            <Button color="success" className="w-100" onClick={() => { handleChangePerserta() }} type="submit">Masuk</Button>
                                                        </div>
                                                    </form>
                                                </Col>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </React.Fragment>
    );
};

export default CoverSignIn;