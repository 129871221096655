import React, { useState, useEffect } from "react";
import {
  Alert,
  Col,
  Container,
  Row,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Table,
  Label,
  Card,
} from "reactstrap";
import Cleave from "cleave.js/react";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import FotoModal from "../../assets/images/telkom/modal.png";
import success from "../../assets/images/telkom/success.svg";
import Refresh from "../../assets/images/telkom/refresh.svg";
import Result from "../../assets/images/telkom/result.svg";
import Loading from "../../assets/images/telkom/loadingred.gif";
import Template from "../../assets/images/telkom/templatePelamar.xlsx";
import axios from "axios";
import deletePhoto from "../../assets/images/telkom/delete.png";

import Timeout from '../../assets/images/telkom/timeout.svg';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import crypto from "../Setting/crypto";

const I4 = () => {
  document.title = "Data Pelamar | On Process";

  // ---------- GET ALL EVENT ------------ //
  const [dataUser, setDataUser] = useState({});

  // ---------- GET ALL EVENT ------------ //
  const [eventList, setEventList] = useState([]);

  // ---------- GET ALL ASSESOR ---------- //
  const [allAssesor, setAllAssessor] = useState([]);

  // ---------- GET ALL EVENT PELAMAR ------------- //
  const [ambilIdEvent, setAmbilIdEvent] = useState(sessionStorage.getItem("pilihEvent"));
  const [pelamar, setPelamar] = useState([]);

  // ---------- TAMBAH PELAMAR ----------- //
  const [nama, setNama] = useState("");
  const [errorNama, setErrorNama] = useState("");
  const [email, setEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [noHP, setNoHP] = useState("");
  const [errorNoHP, setErrorNoHP] = useState("");
  const [alamat, setAlamat] = useState("");
  const [errorAlamat, setErrorAlamat] = useState("");
  const [tanggalLahir, setTanggalLahir] = useState(null);
  const [errorTanggalLahir, setErrorTanggalLahir] = useState("");
  const [assesor, setAssesor] = useState("");
  const [errorAssesor, setErrorAssesor] = useState("");
  const [kode, setKode] = useState("");
  const [errorKode, setErrorKode] = useState("");
  const [errorAdd, setErrorAdd] = useState(true);
  const [errorEmailSama, setErrorEmailSama] = useState(true);
  const [errorKodeSama, setErrorKodeSama] = useState(true);

  // --------- MODAL ---------- //
  const [modalTambahPelamar, setModalTambahPelamar] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [modalUploadData, setModalUploadData] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [modalResult, setModalResult] = useState(false);
  const [modalResendKode, setModalResendKode] = useState(false);

  //--------- SELECT FILE MULTIPLE PELAMAR ---------- //
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [countSuccess, setCountSuccess] = useState(0);
  const [countDuplicate, setCountDuplicate] = useState(0);

  // -------- Search -------- //
  const [kata, setKata] = useState("");

  // delete
  const [selectPeserta, setSelectPeserta] = useState([]);
  const [ids, setIds] = useState([]);

  const [judulto, setJudulto] = useState('');

  // ---------- GET ALL EVENT ------------ //
  useEffect(() => {
    // const user = JSON.parse(localStorage.getItem("user"));
    const user = JSON.parse(sessionStorage.getItem("user"));
  
    if (user) {
      setDataUser(user.results);
      defaultGetAllEventPeserta(user.results.api_key)
      getAllEventInterview(user.results.api_key);
      getAllAssesor(user.results.api_key);

    } else {
      window.location.href = "/login-admin";
    }
  }, []);

  const getAllEventInterview = (api_key) => {
  
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/eventlist`,
      headers: { 
        'x-api-key': api_key
      }
    };

    axios(config)
      .then(function (response) {
        // console.log(response)
        if(response.status == 403){
          setEventList([])
          // checkJWT()
        } else {
          setEventList(JSON.parse(JSON.stringify(response.data)));
        }


      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getIds = () => {
    selectPeserta.forEach((d) => {
      if (d.select) {
        setIds(d.id);
      }
    });
  };


  // export
  const downloadPeserta = () => {
    let arrayids = [];

    selectPeserta.forEach(d => {
      if (d.select) {
        arrayids.push(d.id);
      }
    });
    if (arrayids.length === 0) {
      openModalExport()
    }

    const data = JSON.stringify({
      "ids": arrayids
    });

    const config = {
      method: 'post',
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/downloadPeserta`,
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': dataUser.api_key
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        const data = response.data
        let temp = []
        var no = 1;
        data.forEach(d => {
          let test = {
            "No": no,
            "Nama": d.nama,
            "Email": d.email,
            "Kode Peserta": d.kode,
            "Link": process.env.REACT_APP_BASE_ENDPOINT_PELAMAR +"/login-perserta"
          }
          temp.push(test)
          no++;
        })

        const ws = XLSX.utils.json_to_sheet(temp);
        const wb = { Sheets: { 'Data Peserta': ws }, SheetNames: ['Data Peserta'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const dataa = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
        FileSaver.saveAs(dataa, `${judulto}.xlsx`);

        setTimeout(() => {
          defaultGetAllEventPeserta(dataUser.api_key)
        }, 4000)
      })
      .catch(function (error) {
        console.log(error);
      });

  }

  //delete
  const deletePeserta = () => {
    let arrayids = [];

    selectPeserta.forEach((d) => {
      if (d.select) {
        //setIds(d.id)
        arrayids.push(d.id);
      }
    });

    const data = JSON.stringify({
      ids: arrayids,
    });

    const config = {
      method: "delete",
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/peserta/remove`,
      headers: {
        "Content-Type": "application/json",
        'x-api-key': dataUser.api_key
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setTimeout(() => {
          setModalSuccessDelete(true)
          defaultGetAllEventPeserta(dataUser.api_key);
        }, 4000);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // ----------- GET ALL ASSESOR ----------- //
  const getAllAssesor = (api_key) => {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/assesor`,
      headers: { 
        'x-api-key': api_key
      }
    };

    axios(config)
      .then(function (response) {
        // console.log(response)

        if(response.status == 403){
          setAllAssessor([])
        } else {
          setAllAssessor(response.data);
        }
        
        
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // ---------- GET ALL EVENT PELAMAR ------------- //

  const defaultGetAllEventPeserta = (api_key) => {

    const config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/peserta/${ambilIdEvent}`,
      headers: { 
        'x-api-key': api_key
      }
    };

    axios(config)
      .then(function (response) {
        // console.log(response)

        if(response.status == 403){
          setSelectPeserta([])
          checkJWT()
        } else {

          setSelectPeserta(
            response.data.map((peserta) => {
              return {
                select: false,
                id: peserta.id,
                id_Event: peserta.id_Event,
                nama: crypto.aesDecryptIdentitas(peserta.nama),
                tanggal_Lahir: peserta.tanggal_Lahir,
                tanggal_Daftar: peserta.tanggal_Daftar,
                tanggal_Submit: peserta.tanggal_Submit,
                assesor: crypto.aesDecryptIdentitas(peserta.assesor),
                status: peserta.status,
                kode: peserta.kode,
              };
            })
          );

        }

      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const changeGetAllEventPeserta = (api_key, id_event) => {

    const config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/peserta/${id_event}`,
      headers: { 
        'x-api-key': api_key
      }
    };

    axios(config)
      .then(function (response) {
        setSelectPeserta(
          response.data.map((peserta) => {
            return {
              select: false,
              id: peserta.id,
              id_Event: peserta.id_Event,
              nama: crypto.aesDecryptIdentitas(peserta.nama),
              tanggal_Lahir: peserta.tanggal_Lahir,
              tanggal_Daftar: peserta.tanggal_Daftar,
              tanggal_Submit: peserta.tanggal_Submit,
              assesor: crypto.aesDecryptIdentitas(peserta.assesor),
              status: peserta.status,
              kode: peserta.kode,
            };
          })
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  

  const [namato, setNamato] = useState("");
  const [emailto, setEmailto] = useState("");
  const [noHPto, setNoHPto] = useState("");
  const [alamatto, setAlamatto] = useState("");
  const [tanggalLahirto, setTanggalLahirto] = useState("");
  const [assesorto, setAssesorto] = useState("");
  const [kodeto, setKodeto] = useState("");

  function getPesertaByIdDetail(id) {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/pesertaEvent/${id}/${ambilIdEvent}`,
      headers: { 
        'x-api-key': dataUser.token
      },
    };

    axios(config)
      .then(function (response) {
        const a = response.data;
        const b = a[0];

        setNamato(crypto.aesDecryptIdentitas(b.nama));
        setEmailto(crypto.aesDecryptIdentitas(b.email));
        setNoHPto(crypto.aesDecryptIdentitas(b.no_HP));
        setAlamatto(crypto.aesDecryptIdentitas(b.alamat));

        var date = new Date(b.tanggal_Lahir);
        var tgl =
          date.getFullYear() +
          "-" +
          (date.getMonth() + 1 > 9
            ? date.getMonth() + 1
            : "0" + (date.getMonth() + 1)) +
          "-" +
          (date.getDate() > 9 ? date.getDate() : "0" + date.getDate());
        if (b.tanggal_Lahir !== null) {
          setTanggalLahirto(tgl);

        }
        else {
          setTanggalLahirto("");
        }

        setAssesorto(crypto.aesDecryptIdentitas(b.assesor));
        setKodeto(b.kode);
      })

      .catch(function (error) {
        console.log(error);
      });
  }

  // ---------- TAMBAH PELAMAR ----------- //

  const generate = () => {
    random_kode(7);
  };

  function random_kode(string_length) {
    var random_string = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < string_length; i++) {
      random_string += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    setKode(random_string);
  }

  const changeNama = (event) => {
    const value = event.target.value;
    setNama(value);
    if (!value) {
      setErrorNama("Maaf, Nama tidak boleh kosong!");
    } else {
      setErrorNama("");
    }
  };

  const changeEmail = (event) => {
    const value = event.target.value;
    setEmail(value);
    if (!value) {
      setErrorEmail("Maaf, Email tidak boleh kosong!");
    } else {
      setErrorEmail("");
    }
  };

  const changeNoHP = (event) => {
    const value = event.target.value;
    setNoHP(value);
    if (!value) {
      setErrorNoHP("Maaf, Nomer HP tidak boleh kosong!");
    } else {
      setErrorNoHP("");
    }
  };

  const changeAlamat = (event) => {
    const value = event.target.value;
    setAlamat(value);
    if (!value) {
      setErrorAlamat("Maaf, Alamat tidak boleh kosong!");
    } else {
      setErrorAlamat("");
    }
  };

  const changeTanggalLahir = (event) => {
    const value = event.target.value;
    setTanggalLahir(value);
    if (!value) {
      setErrorTanggalLahir("Maaf, Tanggal Lahir tidak boleh kosong!");
    } else {
      setErrorTanggalLahir("");
    }
  };

  const changeAssesor = (event) => {
    const value = event.target.value;
    setAssesor(value);
    if (!value) {
      setErrorAssesor("Pilih Assesor Anda");
    } else {
      setErrorAssesor("");
    }
  };

  const changeKode = (event) => {
    const value = event.target.value;
    setKode(value);
    if (!value) {
      setErrorKode("Maaf, Kode tidak boleh kosong!");
    } else {
      setErrorKode("");
    }
  };

  const addPelamar = () => {
    setErrorAdd(true);
    setErrorEmailSama(true);
    const tanggalNow = new Date();
    const tahun = tanggalNow.getFullYear();
    const bulan = tanggalNow.getMonth() + 1;
    const hari = tanggalNow.getDate();

    if (nama !== "" && email !== "" && assesor !== "" && kode !== "") {
      const data = JSON.stringify({
        id_Event: ambilIdEvent,
        nama: nama,
        email: email,
        no_HP: noHP,
        alamat: alamat,
        tanggal_Lahir: tanggalLahir,
        tanggal_Daftar: `${tahun}-${bulan}-${hari}`,
        assesor: assesor,
        kode: kode,
      });

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/peserta/register`,
        headers: {
          'x-api-key': dataUser.api_key
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
        
          if (response.status === 403) {
            setErrorKodeSama(false);
            setTimeout(() => {
              setErrorKodeSama(true);
            }, 5000);
          } else if (response.status === 405) {
            setErrorEmailSama(false);
            setTimeout(() => {
              setErrorEmailSama(true);
            }, 5000);
          } else {
            setModalTambahPelamar(false);
            setModalSuccess(true);
            setTimeout(() => {
              setModalSuccess(false);
              defaultGetAllEventPeserta(dataUser.api_key);
            }, 4000);
          }
        })
        .catch(function (error) {
          setErrorAdd(false);
          setTimeout(() => {
            setErrorAdd(true);
          }, 5000);
        });
    } else {
      setErrorAdd(false);
      setTimeout(() => {
        setErrorAdd(true);
      }, 5000);
    }
  };

  const addMultiplePelamar = () => {
    setModalLoading(true);

    let data = new FormData();
    data.append("file", selectedFiles[0]);
    data.append("eventId", ambilIdEvent);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/uploadMultiplePelamarWithEmail`,
      headers: {
        "x-api-key": dataUser.token,
        'Content-Type': 'multipart/form-data'
      },
      data: data,
    };

    axios.request(config)
      .then(function (response) {
        console.log(response)
        setModalLoading(false);
        setCountSuccess(response.totalEmailSuccess);
        setCountDuplicate(response.totalEmailDuplicate);
        setModalResult(true);
        defaultGetAllEventPeserta(dataUser.api_key);
      })
      .catch(function (error) {
        setModalLoading(false);
        // console.log(error);
      });
  };

  // ---------- ON NEXT --------- //
  const [step, setStep] = useState(0);

  const onChange = (nextStep) => {
    setStep(nextStep < 0 ? 0 : nextStep > 4 ? 4 : nextStep);
  };

  const onNext = () => onChange(step + 1);
  const onPrevious = () => onChange(step - 1);
  // ~~~~~~~~ ON NEXT ~~~~~~~~~ //

  // --------- MODAL DELETE ---------- //
  const [modalDelete, setModalDelete] = useState(false);
  function openModalDelete() {
    setModalDelete(!modalDelete);
  }


  // --------- MODAL EXPORT ---------- //
  const [modalExport, setModalExport] = useState(false);
  function openModalExport() {
    setModalExport(!modalExport);
  }

  // --------- MODAL TAMBAH PELAMAR ---------- //
  function openModalTambahPelamar() {
    setModalTambahPelamar(!modalTambahPelamar);
  }

  // --------- MODAL UPLOAD & UPLOAD ---------- //
  function openModalUploadData() {
    setModalUploadData(!modalUploadData);
  }

  // --------- MODAL UPLOAD & UPLOAD ---------- //
  function openModalResendKode() {
    modalResendKode(!modalResendKode);
  }

  // --------- MODAL SUCCESS ---------- //
  function openModalSuccess() {
    setModalSuccess(!modalSuccess);
  }

  // -------- MODAL LOADING --------- //
  function openModalLoading() {
    setModalLoading(!modalLoading);
  }

  // -------- MODAL RESULT --------- //
  function openModalResult() {
    setModalResult(!modalResult);
  }

  // --------- MODAL SUCCESS DELETE---------- //
  const [modalSuccessDelete, setModalSuccessDelete] = useState(false);
  function openModalSuccessDelete() {
    setModalSuccessDelete(!modalSuccessDelete);
  }

  function handleAcceptedFiles(files) {
    // files.map((file) =>
    //   Object.assign(file, {
    //     preview: URL.createObjectURL(file),
    //     formattedSize: formatBytes(file.size),
    //   })
    // );
    setSelectedFiles(files);
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const [modalDetailPelamar, setModalDetailPelamar] = useState(false);
  const openModalDetailPelamar = () => {
    setModalDetailPelamar(!modalDetailPelamar);
  };

  // --------- MODAL JWT ---------- //
  const [modalJWT, setModalJWT] = useState(false);
  // ----------- CHECK JWT --------- //
  const checkJWT = () => {
    const config = {
      method: 'post',
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/checkJwt`,
      headers: {
        'x-api-key': dataUser.token,
      }
    };

    axios(config)
      .then(function (response) {
        if (response.status === 403) {
          setModalJWT(true)
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  function openModalJWT() {
    setModalJWT(!modalJWT);
  }

  const selectDataInterview = (id_event) =>{
    checkJWT();

    setAmbilIdEvent(id_event);
    sessionStorage.setItem("pilihEvent", id_event);

    changeGetAllEventPeserta(dataUser.api_key, id_event);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div>
          <h1 style={{ color: "#2A4365" }}>Kelola Kode Interview Pelamar</h1>
        </div>

        {/* Menu */}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex", flexDirection: "column", marginTop: "35px", marginBottom: "30px", }}>
            <p style={{ margin: "0", fontWeight: "700", fontSize: "16px", color: "#4A5568", }}>
              Pilih Data Interview
            </p>

            <select style={{ width: "320px", marginBottom: "10px" }} 
            value={ambilIdEvent} 
            onChange={(event) => { selectDataInterview(event.target.value) }} 
            className="form-select" aria-label="Default select example"
            >
              <option value="0" selected="selected" hidden="hidden">
                Choose here
              </option>
              {eventList.map((item) => (

                <>
                  <option value={item.id} >{item.judul_Interview}</option>
                </>
              ))}
            </select>

            <div style={{ width: "360px", margin: "10px 0px" }} className="search-box">
              <input type="text" className="form-control search" placeholder="Search..." onChange={(e) => setKata(e.target.value)} />
              <i style={{ height: "40px" }} className="ri-search-line search-icon"></i>
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "column", alignItems: "flex-end", marginBottom: "30px", marginTop: "35px", width: "50%" }}>
            {/* <Button style={{ padding: '0.5rem 3.0rem'}} onClick={() => {openModalDelete()}} color='danger'>Hapus Data</Button> */}
            <div style={{ display: "flex", marginTop: "10px", gap: "20px" }}>
              <Button style={{ padding: "0.5rem", width: "150px", height: "40px" }} color="success"
                onClick={() => {
                  openModalTambahPelamar();
                  setNama("");
                  setEmail("");
                  setNoHP("");
                  setAlamat("");
                  setTanggalLahir(null);
                  setAssesor("");
                  setKode("");
                  checkJWT();
                }}
                disabled={ambilIdEvent === null ? true : false}
              >
                Tambah Pelamar
              </Button>
              <Button style={{ padding: "0.5rem", width: "150px", height: "40px" }} color="info"
                onClick={() => {
                  openModalUploadData();
                  checkJWT()
                  setSelectedFiles([])
                }}
                disabled={ambilIdEvent === null ? true : false}
              >
                Upload Data
              </Button>
            </div>
            <div style={{ display: "flex", alignItems: "center", marginBottom: "10px", gap: "20px" }}>
              <Button style={{ padding: "0.5rem", width: "100px", height: "40px", fontWeight: "700", backgroundColor: "white", color: "#F56565", border: "none" }}
                onClick={() => {
                  getIds();
                  openModalDelete();
                  checkJWT()
                }}
              >
                Hapus Data
              </Button>
              <p style={{ margin: "0px" }}>|</p>
              {/* <Button style={{ padding: "0.5rem", width: "140px", height: "40px", fontWeight: "700", backgroundColor: "white", color: "#4299E1", border: "none"}}
                onClick={() => {
                  getIds();
                  openModalResendKode();
                  checkJWT();
                }}
              >
                Kirim Ulang Kode
              </Button>
              <p style={{ margin: "0px" }}>|</p> */}

              <Button style={{ padding: "0.5rem", width: "140px", height: "40px", fontWeight: "700", backgroundColor: "white", color: "#4299E1", border: "none" }}
                onClick={() => {
                  getIds();
                  downloadPeserta();
                  checkJWT();
                }}
              >
                Export
              </Button>
            </div>
          </div>
        </div>

        {/* Tabel */}
        <div className="table-responsive">
          <Table className="align-middle table-nowrap mb-0">
            <thead style={{ backgroundColor: "#FED7D7", color: "#E53E3E" }}>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      let value = e.target.checked;
                      setSelectPeserta(
                        selectPeserta.map((d) => {
                          d.select = value;
                          return d;
                        })
                      );
                    }}
                  />
                </th>
                <th scope="col">No</th>
                <th style={{ width: "300px" }} scope="col">
                  Nama
                </th>
                <th style={{ width: "180px" }} scope="col">
                  Tanggal Lahir
                </th>
                <th style={{ width: "200px" }} scope="col">
                  Status
                </th>
                <th style={{ width: "200px" }} scope="col">
                  Assessor
                </th>
                <th style={{ width: "200px" }} scope="col">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {selectPeserta.filter(
                (item) =>
                  item.nama.toLowerCase().includes(kata.toLowerCase()) ||
                  item.assesor.toLowerCase().includes(kata.toLowerCase())
              ).map((item, index) => (
                  <tr key={index}>
                    <td>
                      <input
                        type="checkbox"
                        checked={item.select}
                        onChange={(e) => {
                          let value = e.target.checked;
                          setSelectPeserta(
                            selectPeserta.map((sd) => {
                              if (sd.id === item.id) {
                                sd.select = value;
                              }
                              return sd;
                            })
                          );
                        }}
                      />
                    </td>
                    <td>{1 + index}</td>
                    <td>{item.nama}</td>
                    <td>
                      {item.tanggal_Lahir == null
                        ? "- - -"
                        : new Date(item.tanggal_Lahir).getFullYear() +
                        "-" +
                        (new Date(item.tanggal_Lahir).getMonth() + 1 > 9
                          ? new Date(item.tanggal_Lahir).getMonth() + 1
                          : "0" +
                          (new Date(item.tanggal_Lahir).getMonth() + 1)) +
                        "-" +
                        (new Date(item.tanggal_Lahir).getDate() > 9
                          ? new Date(item.tanggal_Lahir).getDate()
                          : "0" + new Date(item.tanggal_Lahir).getDate())}
                    </td>
                    {item.status === "selesai" && (
                      <>
                        <td>
                          <p style={{ margin: "0px", fontSize: "10px", width: "74px", fontWeight: "600", padding: "5px 10px", backgroundColor: "#f0fff4", color: "#38A169", borderRadius: "8px", textAlign: "center", }}>
                            Hadir
                          </p>
                        </td>
                      </>
                    )}
                    {item.status === "on progress" && (
                      <>
                        <td>
                          <p style={{ margin: "0px", fontSize: "10px", width: "74px", fontWeight: "600", padding: "5px 10px", backgroundColor: "#f0fff4", color: "#38A169", borderRadius: "8px", textAlign: "center", }}>
                            Hadir
                          </p>
                        </td>
                      </>
                    )}
                    {item.status === null && (
                      <>
                        <td>
                          <p style={{ margin: "0px", fontSize: "10px", width: "74px", fontWeight: "600", padding: "5px 10px", backgroundColor: "#FFF5F5", color: "#E53E3E", borderRadius: "8px", textAlign: "center" }}>
                            Tidak Hadir
                          </p>
                        </td>
                      </>
                    )}

                    <td>{item.assesor}</td>
                    <td>
                      <div className="d-flex gap-2">
                        <div className="edit">
                          <Button color="info" style={{ display: "flex", alignItems: "center", gap: "10px" }}
                            onClick={() => {
                              getPesertaByIdDetail(item.id);
                              openModalDetailPelamar();
                              checkJWT()
                            }}
                          >
                            <i className="ri-eye-fill"></i> Lihat
                          </Button>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      </div>

      {/* ------- MODAL DELETE ------- */}
      <Modal isOpen={modalDelete} toggle={() => { openModalDelete() }} centered>
        <ModalBody >
          {ids.length !== 0 ? (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", overflow: "auto" }}>
              <img src={deletePhoto} />
              <h5 style={{ textAlign: "center", marginTop: "30px" }}>
                Yakin Hapus Data?
              </h5>
              <p style={{ padding: "0 70px", textAlign: "center" }}>
                Apakah anda yakin untuk menghapus data ini?
              </p>
            </div>
          ) : (
            <>
              {" "}
              <h5 style={{ textAlign: "center", marginTop: "30px" }}>
                Tidak ada data yang dipilih
              </h5>
            </>
          )}
        </ModalBody>

        <ModalFooter style={{ justifyContent: "space-evenly" }}>
          <Button style={{ padding: "0.5rem 2.0rem", backgroundColor: "#ffffff", color: "#718096", border: "1px solid #A0AEC0", fontWeight: "700", borderRadius: "6px" }} className="btn btn-light" onClick={() => { setIds([]); setModalDelete(false); checkJWT(); }}>
            Batal
          </Button>

          {ids.length !== 0 && (
            <Button style={{ padding: "0.5rem 2.0rem", fontWeight: "700", borderRadius: "6px" }} onClick={() => { deletePeserta(); setModalDelete(false); checkJWT() }} className="btn btn-success">
              Hapus
            </Button>
          )}
        </ModalFooter>
      </Modal>

      {/* ---------- MODAL SUCCESS DELETE ---------- */}
      <Modal size="md" isOpen={modalSuccessDelete} toggle={() => { openModalSuccessDelete() }} centered>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '90px 10px' }}>
          <img src={success} />
          <h5 style={{ textAlign: 'center', margin: '20px' }}>Data Peserta Berhasil Dihapus</h5>
        </div>
      </Modal>

      {/* ---------- MODAL Tambah Pelamar -------- */}
      <Modal size="sm" isOpen={modalTambahPelamar} toggle={() => { openModalTambahPelamar() }} centered>
        <ModalHeader className="modal-title" id="myModalLabel" toggle={() => { openModalTambahPelamar() }}>
          Tambah Pelamar
        </ModalHeader>

        <ModalBody style={{ display: "flex", flexDirection: "column", alignItems: "center", overflow: "auto", height: "380px" }}>
          <Row style={{ alignItems: "center" }}>

            <Alert hidden={errorAdd} color="warning" style={{ textAlign: "center", backgroundColor: "#FFF3B5", color: "#D88E00", borderColor: "#FFF3B5", margin: "10px 0px", transition: "0.5s", }}>
              Something wrong, please check again
            </Alert>
            <Alert hidden={errorEmailSama} color="warning" style={{ textAlign: "center", backgroundColor: "#FFF3B5", color: "#D88E00", borderColor: "#FFF3B5", margin: "10px 0px", transition: "0.5s", }}>
              Email sudah di gunakan
            </Alert>
            <Alert hidden={errorKodeSama} color="warning" style={{ textAlign: "center", backgroundColor: "#FFF3B5", color: "#D88E00", borderColor: "#FFF3B5", margin: "10px 0px", transition: "0.5s" }} >
              Kode sudah di gunakan
            </Alert>

            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{ marginBottom: "15px" }} >
              <div>
                <Label htmlFor="nama" className="form-label">
                  Nama <span style={{ color: "#E53E3E" }}>*</span>
                </Label>
                <Input type="text" value={nama} onChange={changeNama} className="form-control" id="nama" maxLength={100} />
                {errorNama && (
                  <p style={{ margin: "0", color: "#F56565", fontSize: "10px", position: "absolute" }}>
                    {errorNama}
                  </p>
                )}
              </div>
            </Col>

            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{ marginBottom: "15px" }}>
              <div>
                <Label htmlFor="email" className="form-label">
                  Email <span style={{ color: "#E53E3E" }}>*</span>
                </Label>
                <Input type="text" value={email} onChange={changeEmail} className="form-control" id="email" maxLength={60} />
                {errorEmail && (
                  <p style={{ margin: "0", color: "#F56565", fontSize: "10px", position: "absolute" }}>
                    {errorEmail}
                  </p>
                )}
              </div>
            </Col>

            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{ marginBottom: "15px" }}>
              <div>
                <Label htmlFor="hp" className="form-label">
                  No. HP
                </Label>
                <Input type="text" value={noHP} onChange={changeNoHP} className="form-control" id="hp" maxLength={30} />
              </div>
            </Col>

            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{ marginBottom: "15px" }}>
              <div>
                <Label htmlFor="alamat" className="form-label">
                  Alamat
                </Label>
                <Input
                  type="text"
                  value={alamat}
                  onChange={changeAlamat}
                  className="form-control"
                  id="alamat" maxLength={255}
                />
              </div>
            </Col>

            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{ marginBottom: "15px" }}>
              <div>
                <Label htmlFor="lahir" className="form-label">
                  Tanggal Lahir
                </Label>
                <Cleave
                  placeholder="YYYY-MM-DD"
                  options={{
                    date: true,
                    delimiter: "-",
                    datePattern: ["Y", "m", "d"],
                  }}
                  value={tanggalLahir}
                  onChange={changeTanggalLahir}
                  className="form-control"
                />
              </div>
            </Col>

            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{ marginBottom: "15px" }}>
              <div>
                <Label className="form-label">
                  Assessor <span style={{ color: "#E53E3E" }}>*</span>
                </Label>
                <select
                  style={{ width: "240px" }}
                  value={assesor}
                  onChange={changeAssesor}
                  className="form-select"
                  aria-label="Default select example"
                >
                  <option value="" selected="selected" hidden="hidden">
                    Choose here
                  </option>
                  {allAssesor.map((item) => (
                    <>
                      <option value={crypto.aesDecryptIdentitas(item.nama)}>{crypto.aesDecryptIdentitas(item.nama)}</option>
                    </>
                  ))}
                </select>
                {errorAssesor && (
                  <p style={{ margin: "0", color: "#F56565", fontSize: "10px", position: "absolute" }}>
                    {errorAssesor}
                  </p>
                )}
              </div>
            </Col>

            <Col xxl={8} xl={8} lg={8} md={8} sm={8} xs={8} style={{ marginBottom: "24px" }}>
              <div>
                <Label htmlFor="kode" className="form-label">
                  Kode <span style={{ color: "#E53E3E" }}>*</span>
                </Label>
                <Input type="text" value={kode} onChange={changeKode} className="form-control" id="kode" disabled />
              </div>
            </Col>

            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
              <Button
                onClick={generate}
                style={{ backgroundColor: "#ffffff", borderColor: "#ced4da" }}
              >
                <img src={Refresh} />
              </Button>
            </Col>
            <span style={{ color: "#E53E3E", fontStyle: "italic" }}>
              * Wajib Diisi
            </span>

            <Alert hidden={errorAdd} color="warning" style={{ textAlign: "center", backgroundColor: "#FFF3B5", color: "#D88E00", borderColor: "#FFF3B5", margin: "10px 0px", transition: "0.5s", }}>
              Something wrong, please check again
            </Alert>
            <Alert hidden={errorEmailSama} color="warning" style={{ textAlign: "center", backgroundColor: "#FFF3B5", color: "#D88E00", borderColor: "#FFF3B5", margin: "10px 0px", transition: "0.5s", }}>
              Email sudah di gunakan
            </Alert>
            <Alert hidden={errorKodeSama} color="warning" style={{ textAlign: "center", backgroundColor: "#FFF3B5", color: "#D88E00", borderColor: "#FFF3B5", margin: "10px 0px", transition: "0.5s", }}>
              Kode sudah di gunakan
            </Alert>

          </Row>
        </ModalBody>

        <ModalFooter style={{ justifyContent: "space-evenly" }}>
          <Button style={{ padding: "0.5rem 2.0rem", backgroundColor: "#ffffff", color: "#718096", border: "1px solid #A0AEC0", fontWeight: "700", borderRadius: "6px" }} className="btn btn-light" onClick={() => { setModalTambahPelamar(false); checkJWT() }}>
            Batal
          </Button>
          <Button
            style={{
              padding: "0.5rem 2.0rem",
              fontWeight: "700",
              borderRadius: "6px",
            }}
            onClick={() => {
              addPelamar();
              checkJWT();
            }}
            className="btn btn-success"
          >
            Tambah
          </Button>
        </ModalFooter>
      </Modal>

      {/* ---------- MODAL UPLOAD DATA -------- */}
      <Modal size="md" isOpen={modalUploadData} toggle={() => { openModalUploadData(); }} centered >
        <ModalHeader className="modal-title" id="myModalLabel" toggle={() => { openModalUploadData() }}>
          Upload Data
        </ModalHeader>

        <ModalBody
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{ marginBottom: "15px" }}>
            <div>
              <Label htmlFor="nama" className="form-label">
                Pilih Data
              </Label>
              <Dropzone
                onDrop={(acceptedFiles) => {
                  handleAcceptedFiles(acceptedFiles);
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <div
                      style={{ padding: "15px" }}
                      className="dropzone dz-clickable"
                      {...getRootProps()}
                    >
                      <input {...getInputProps()} accept={'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}/>
                      <div className="dz-message needsclick">
                        <div className="mb-3">
                          <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                        </div>
                        <h4>Drop files here or click to upload.</h4>
                      </div>
                    </div>
                )}
              </Dropzone>
              <div className="list-unstyled mb-0" id="file-previews">
                {selectedFiles.map((f, i) => {
                  return (
                    <Card
                      className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                      key={i + "-file"}
                    >
                      <div className="p-2">
                        <Row className="align-items-center">
                          <Col className="col-auto">
                              <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB0UlEQVR4nO2W2UsCURTG588aBCEIegkqCHrqH6iQFgRJKAx6qLdCCvLBmjYizSChh7JVqIekhVJpsUxaRi03lCmXTmR4QoPI5N6ucD84MHPOy/c79xvuCAIXF1mJUhsQqqGqBWiw9tKDEAkAnIUvoGlJTweCBICcCtKDEAkBUIMQCQLINCBIA8ikIWgAyCQhaAHIpCBoAsgkIGgDyCUQTAKUUwIHkPgJAI9QJeIRkniEKtNvN2g+WQXv022+Nv2HRbMexxjOPGE/NFr17N0DLcsGyOSyUFCXw4gzz5Mf+/bLPXYvMot3C42ehq7zPe3GOPaUzGtZ2xdpA9QtaCH+kkTDmrVRcIdv8N10ZGf/V8LoWkLD4VQMn0OpKNTMdbIPoJ7RQCAehFINOKf+dCMLtAE+auTAUmQ+oiRANd1eHQCq6Q64itx/OwHd1kR1AAzvz6PpZFrB57tECNSzGrYBaue74VmJo2n9tgl80a/TMLpsbAPMudfRrC/6kM99/64Ze6m0AvWLOjYBmm19kM5mirZf+Cb8sUfsL5/vsgmwEzhGk9exz+0XZgbnJM5ybzloXRlkD0AkVAIHkPgJAI9QJeIRkniEKhOPkPTPEeLiEn7UO0W1O39XWcpJAAAAAElFTkSuQmCC"
                                height="36"
                                alt={f.name}
                              />
                          </Col>
                          <Col>
                            <Link
                              to="#"
                              className="text-muted font-weight-bold"
                            >
                              {f.name}
                            </Link>
                            <p className="mb-0">
                              <strong>{f.size}</strong>
                            </p>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  );
                })}
              </div>
            </div>
          </Col>

          <Col
            xxl={12}
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{ marginBottom: "15px", color: "#A0AEC0" }}
          >
            <p>
              Hanya dapat mengunggah berkas dengan format .xlsx Ukuran maksimum
              berkas 200 mb
            </p>
          </Col>

          <Col
            style={{ textDecorationLine: "underline", cursor: "pointer" }}
            xxl={12}
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
          >
            <a
              href={Template}
              style={{ fontSize: "14px ", color: "#4299E1" }}
              download
            >
              Lihat Template
            </a>
          </Col>
        </ModalBody>

        <ModalFooter style={{ justifyContent: "space-evenly" }}>
          <Button
            style={{
              padding: "0.5rem 2.0rem",
              backgroundColor: "#ffffff",
              color: "#718096",
              border: "1px solid #A0AEC0",
              fontWeight: "700",
              borderRadius: "6px",
            }}
            className="btn btn-light"
            onClick={() => {
              setModalUploadData(false);
              checkJWT();
            }}
          >
            Batal
          </Button>
          <Button
            style={{
              padding: "0.5rem 2.0rem",
              fontWeight: "700",
              borderRadius: "6px",
            }}
            className="btn btn-success"
            onClick={() => {
              addMultiplePelamar();
              openModalUploadData();
              checkJWT()
            }}
          >
            Upload
          </Button>
        </ModalFooter>
      </Modal>

      {/* ---------- MODAL DETAIL PELAMAR ----------- */}
      <Modal
        size="sm"
        isOpen={modalDetailPelamar}
        toggle={() => {
          openModalDetailPelamar();
        }}
        centered
      >
        <ModalHeader
          className="modal-title"
          id="myModalLabel"
          toggle={() => {
            openModalDetailPelamar();
          }}
        >
          <p
            style={{
              margin: "0px",
              color: "#2A4365",
              fontSize: "20px",
              fontWeight: "700",
            }}
          >
            Detail Pelamar
          </p>
        </ModalHeader>

        <ModalBody>
          <p
            style={{
              fontWeight: "700",
              color: "#4A5568",
              fontSize: "14px",
              margin: "0",
            }}
          >
            Nama
          </p>
          <p style={{ fontWeight: "500", color: "#718096" }}>{namato}</p>

          <p
            style={{
              fontWeight: "700",
              color: "#4A5568",
              fontSize: "14px",
              margin: "0",
            }}
          >
            Email
          </p>
          <p style={{ fontWeight: "500", color: "#718096" }}>{emailto}</p>

          <p
            style={{
              fontWeight: "700",
              color: "#4A5568",
              fontSize: "14px",
              margin: "0",
            }}
          >
            No HP
          </p>
          {noHPto === "" ?
            <p style={{ fontWeight: "500", color: "#718096" }}>---</p> :

            <p style={{ fontWeight: "500", color: "#718096" }}>{noHPto}</p>
          }


          <p
            style={{
              fontWeight: "700",
              color: "#4A5568",
              fontSize: "14px",
              margin: "0",
            }}
          >
            Alamat
          </p>
          {alamatto === "" ?
            <p style={{ fontWeight: "500", color: "#718096" }}>---</p> :

            <p style={{ fontWeight: "500", color: "#718096" }}>{alamatto}</p>
          }


          <p
            style={{
              fontWeight: "700",
              color: "#4A5568",
              fontSize: "14px",
              margin: "0",
            }}
          >
            Tanggal Lahir
          </p>
          {tanggalLahirto === "" ?
            <p style={{ fontWeight: "500", color: "#718096" }}>---</p> :

            <p style={{ fontWeight: "500", color: "#718096" }}>{tanggalLahirto}</p>
          }


          <p
            style={{
              fontWeight: "700",
              color: "#4A5568",
              fontSize: "14px",
              margin: "0",
            }}
          >
            Assessor
          </p>
          <p style={{ fontWeight: "500", color: "#718096" }}>{assesorto}</p>
          <p
            style={{
              fontWeight: "700",
              color: "#4A5568",
              fontSize: "14px",
              margin: "0",
            }}
          >
            Kode
          </p>
          <p style={{ fontWeight: "500", color: "#718096" }}>{kodeto}</p>
        </ModalBody>
      </Modal>

      {/* ---------- MODAL SUCCESS ---------- */}
      <Modal size="md" isOpen={modalSuccess} toggle={() => { openModalSuccess() }} centered>
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', margin: '90px 10px' }}>
          <img src={success} style={{ textAlign: 'center' }} />
          <h5 style={{ textAlign: 'center', margin: '20px' }}>Data Peserta Berhasil Ditambahkan</h5>
        </div>
      </Modal>

      {/* ------- MODAL RESEND ------- */}
      <Modal
        isOpen={modalResendKode}
        toggle={() => {
          openModalResendKode();
        }}
        centered
      >
        <ModalBody style={{ display: "flex", flexDirection: "column", alignItems: "center", overflow: "auto" }}>
          {ids.length !== 0 ? (
            <div>
              <img src={deletePhoto} />
              <h5 style={{ textAlign: "center", marginTop: "30px" }}>
                Kirim Ulang Kode?
              </h5>
              <p style={{ padding: "0 70px", textAlign: "center" }}>
                Kode akan dikirimkan ulang kepada pelamar yang telah Anda
                pilih/checklist
              </p>
            </div>
          ) : (
            <>
              {" "}
              <h5 style={{ textAlign: "center", marginTop: "30px" }}>
                Tidak ada data yang dipilih
              </h5>
            </>
          )}
        </ModalBody>

        <ModalFooter style={{ justifyContent: "space-evenly" }}>
          <Button style={{ padding: "0.5rem 2.0rem", backgroundColor: "#ffffff", color: "#718096", border: "1px solid #A0AEC0", fontWeight: "700", borderRadius: "6px" }} className="btn btn-light"
            onClick={() => {
              setIds([]);
              setModalResendKode(false);
              checkJWT();
            }}
          >
            Batal
          </Button>

          {ids.length !== 0 && (
            <Button style={{ padding: "0.5rem 2.0rem", fontWeight: "700", borderRadius: "6px" }}
              onClick={() => {
                setModalResendKode(false);
                checkJWT();
              }}
              className="btn btn-success"
            >
              Kirim
            </Button>
          )}
        </ModalFooter>
      </Modal>

      {/* ------- MODAL LOADING ------- */}
      <Modal isOpen={modalLoading} toggle={() => { }} centered>
        <ModalBody style={{ display: "flex", flexDirection: "column", alignItems: "center", overflow: "auto" }}>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <img src={Loading} style={{ width: "170px" }} />
            <h5 style={{ textAlign: "center", padding: "0 70px" }}>
              Tunggu Sebentar
            </h5>
            {/* <p style={{padding: '0 70px', textAlign: 'center', margin:'0'}}>Silahkan check kembali...</p> */}
          </div>
        </ModalBody>

        <ModalFooter style={{ justifyContent: "space-evenly" }}>
          {/* <Button style={{ padding: '0.5rem 2.0rem'}} onClick={() => {setErrorSoal(false)}} className="btn btn-success" >Baik</Button> */}
        </ModalFooter>
      </Modal>

      {/* ------- MODAL RESULT ------- */}
      <Modal isOpen={modalResult}
        toggle={() => {
          openModalResult();
        }}
        centered
      >
        <ModalBody style={{ display: "flex", flexDirection: "column", alignItems: "center", overflow: "auto" }}>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <img src={Result} style={{ width: "170px" }} />
            <h5 style={{ textAlign: "center", padding: "0 70px", fontWeight: "700", fontSize: "22px", color: "#2A4365" }}>
              Selesai Multiple Upload...
            </h5>
            <p style={{ padding: "0 70px", textAlign: "center", margin: "0" }}>
              Berhasil di tambahkan sebanyak :{" "}
              <span style={{ color: "#38A169", fontWeight: "600" }}>
                {countSuccess}
              </span>
            </p>
            <p style={{ padding: "0 70px", textAlign: "center", margin: "0" }}>
              Gagal di tambahkan sebanyak :{" "}
              <span style={{ color: "#E53E3E", fontWeight: "600" }}>
                {countDuplicate}
              </span>
            </p>
          </div>
        </ModalBody>

        <ModalFooter style={{ justifyContent: "space-evenly" }}>
          <Button
            style={{ padding: "0.5rem 2.0rem" }}
            onClick={() => {
              setModalResult(false);
              defaultGetAllEventPeserta(dataUser.api_key);
              checkJWT();
            }}
            className="btn btn-success"
          >
            Oke
          </Button>
        </ModalFooter>
      </Modal>

      {/* ------- MODAL EXPORT ------- */}
      <Modal isOpen={modalExport} toggle={() => { openModalExport() }} centered>
        <ModalBody style={{ display: "flex", flexDirection: "column", alignItems: "center", overflow: "auto" }}>
          {" "}
          <h5 style={{ textAlign: "center", marginTop: "30px" }}>
            Tidak ada data yang dipilih
          </h5>
        </ModalBody>

        <ModalFooter style={{ justifyContent: "space-evenly" }}>
          <Button style={{ padding: "0.5rem 2.0rem", backgroundColor: "#ffffff", color: "#718096", border: "1px solid #A0AEC0", fontWeight: "700", borderRadius: "6px" }} className="btn btn-light" onClick={() => { setIds([]); setModalExport(false); checkJWT(); }}>
            Tutup
          </Button>

        </ModalFooter>
      </Modal>


      {/* ---------- MODAL JWT -------- */}
      <Modal isOpen={modalJWT} centered >
        <ModalBody style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <img src={Timeout} />
          <h5 style={{ textAlign: 'center', marginTop: '30px', fontSize: '24px', fontWeight: '700', color: '#2A4365' }}>Maaf, Silahkan Login Kembali</h5>
          <p style={{ padding: '0 45px', textAlign: 'center', fontSize: '16px', color: '#4A5568', fontWeight: '400' }}>Sesi Anda sudah kadaluarsa, Jangan khawatir... Click tombol di bawah ini untuk login kembali :)</p>
        </ModalBody>
        <ModalFooter style={{ justifyContent: 'center' }}>
          <Button style={{ padding: '0.5rem 3.0rem' }} className="btn btn-success" onClick={() => { window.localStorage.clear(); window.sessionStorage.clear(); window.location.reload(); window.location.href = "/login-admin"; }} >Login</Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default I4;
