import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Alert, Card, Col, Container, Input, Label, Row, Button, Modal, ModalBody } from 'reactstrap';
import axios from 'axios';
// import ReCAPTCHA from 'react-google-recaptcha'

import logoTelkom from "../../../assets/images/telkom/logoACI.png";
import Welcome from "../../../assets/images/telkom/welcomeNew.png";

const CoverSignInterview = () => {
    document.title = "Login Admin";
    // ---- Buat ngecheck, sudah pernah login, bakalan di lempar ke Dashboard ---- //
    if (localStorage.getItem('user') != null) {
        window.localStorage.clear()
    }
    if (sessionStorage.getItem('user') != null) {
        window.location.href = '/i1';
    }

    // ---- Input Data Login ---- //
    const [email, setEmail] = useState('');
    const [errorEmail, setErrorEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorPassword, setErrorPassword] = useState('');

    // --- Alert Error --- //
    const [errorSubmit, setErrorSubmit] = useState(true);

    // -- Tombol Show Password -- //
    const [showPass, setShowPass] = useState(false);

    // --------- MODAL ----------- //
    // const [modalReCAPTCHA, setModalReCAPTCHA] = useState(false);

    // ReCAPTCHA
    // const captchaRef = useRef(null)

    // ---- onChange Input Data ---- //
    const changeEmail = (event) => {
        const value = event.target.value
        setEmail(value)
        if (!value) {
            setErrorEmail('Maaf, email tidak boleh kosong!')
        } else {
            setErrorEmail('')
        }
    }
    const changePassword = (event) => {
        const value = event.target.value
        setPassword(value)
        if (!value) {
            setErrorPassword('Maaf, password tidak boleh kosong!')
        } else {
            setErrorPassword('')
        }
    }

    // -- Tombol Show Password -- //
    const shower = () => {
        setShowPass(!showPass);
    }

    const [modalLimitLogin, setModalLimitLogin] = useState(false)
    // -- Fungsi Login -- //
    const loginButton = () => {

        // if (captchaRef.current.getValue() !== "") {
        const data = JSON.stringify({
            email: email,
            password: password,
        });

        const config = {
            method: 'post',
            url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/login`,
            headers: {
                'Content-Type': 'application/json',
            },
            data: data,
        };

        axios(config).then((response) => {
            console.log(response.results.token)
            // localStorage.setItem('user', JSON.stringify(response))
            sessionStorage.setItem('user', JSON.stringify(response))
            window.location.href = '/i1'
        }).catch((error) => {
            console.log("hai", error.response.data.error)

            if (error.response.data.error == "Request failed with status code 429") {
                setModalLimitLogin(true)
            } else {
                setErrorSubmit(false);
                setTimeout(() => {
                    setErrorSubmit(true)
                }, 5000)
            }
        })
        // } else {
        //     setModalReCAPTCHA(true)
        // }

    }


    return (
        <React.Fragment>
            <Row style={{ margin: '0px', height: '100vh', alignItems: 'center', fontFamily: 'Nunito' }}>
                {/* Ini Welcome! Lets Get Started */}
                <Col md={12} lg={6} xl={6} style={{ height: '100%', padding: '0px' }}>
                    <img src={Welcome} style={{ height: '-webkit-fill-available' }} />
                </Col>
                <Col md={12} lg={6} xl={6} style={{ padding: '0px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                    <div style={{ display: 'flex', justifyContent: 'center', marginRight: '50px' }}>
                        <img src={logoTelkom} />
                    </div>

                    <div style={{ marginTop: '50px', padding: '25px', boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.08)', borderRadius: '8px', width: '65%', marginRight: '50px' }}>

                        {/* ---- HEADER Masuk ---- */}
                        <p style={{ fontWeight: '700', fontSize: '30px', color: '#F56565', textAlign: 'center' }}>Masuk</p>
                        <Alert hidden={errorSubmit} color="warning" style={{ textAlign: 'center', backgroundColor: '#FFF3B5', color: '#D88E00', borderColor: '#FFF3B5', margin: '10px 0px', transition: '0.5s' }}>
                            The Email and/or password you specified are not correct.
                        </Alert>

                        {/* ---- Masukin Data Email ---- */}
                        <div className="mb-3" >
                            <Label style={{ fontSize: '14px' }} htmlFor="email" className="form-label">Email</Label>
                            <Input style={{ fontSize: '14px' }} type="text" value={email} onChange={changeEmail} className="form-control" id="email" placeholder="Enter Email" maxLength={60} required />
                            {errorEmail && (
                                <p style={{ margin: '0', color: '#F56565', fontSize: '10px', position: 'absolute' }}>{errorEmail}</p>
                            )}
                        </div>

                        {/* ---- Masukin Data Password ---- */}
                        <div className="mb-3">
                            <Label style={{ fontSize: '14px' }} className="form-label" htmlFor="password-input">Password</Label>
                            <div className="position-relative auth-pass-inputgroup">
                                <Input style={{ fontSize: '14px' }} type={showPass ? "text" : "password"} value={password} onChange={changePassword} className="form-control pe-5" placeholder="Enter password" id="password-input" required />
                                <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" onClick={shower} type="button" id="password-addon"><i className="ri-eye-fill align-middle"></i></button>
                            </div>
                            {errorPassword && (
                                <p style={{ margin: '0', color: '#F56565', fontSize: '10px', position: 'absolute' }}>{errorPassword}</p>
                            )}
                        </div>

                        {/* ---- Submit Login ---- */}
                        {/* <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} ref={captchaRef} /> */}
                        <div className="mt-4">
                            <Button style={{ fontSize: '14px' }} color="success" className="w-100" onClick={loginButton}>Masuk</Button>
                        </div>
                        <p style={{ marginTop: '20px', fontSize: '14px' }}>
                            Lupa kata sandi ? {" "}
                            <Link style={{ fontWeight: '700', fontSize: '18px', color: '#F56565', cursor: 'pointer' }} to='/forgot-password'>
                                Reset
                            </Link>
                        </p>
                    </div>
                </Col>
            </Row>

            {/* ------- MODAL CAPTCHA ------- */}
            {/* <Modal isOpen={modalReCAPTCHA} toggle={() => { }} centered>
                <ModalBody style={{ display: "flex", flexDirection: "column", alignItems: "center", overflow: "auto" }}>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <h5 style={{ textAlign: "center", padding: "0 70px", margin: '50px 0px' }}>
                            reCAPTCHA dulu kawan
                        </h5>
                        <Button color="success" onClick={() => { setModalReCAPTCHA(false) }}>Oke Deh</Button>
                    </div>
                </ModalBody>
            </Modal> */}
            <Modal isOpen={modalLimitLogin} toggle={() => { }} centered>
                <ModalBody style={{ display: "flex", flexDirection: "column", alignItems: "center", overflow: "auto" }}>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <h5 style={{ textAlign: "center", padding: "0 50px", margin: '50px 0px' }}>
                            Terlalu banyak melakukan percobaan,<br />Mohon tunggu beberapa menit
                        </h5>
                        <Button color="success" onClick={() => { setModalLimitLogin(false) }}>Oke Deh</Button>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default CoverSignInterview;