import React, { useState, useEffect } from "react";
import Webcam from 'react-webcam';
import {Alert, Col, Container, Row , Button, Input, Modal, ModalHeader, ModalBody, ModalFooter,Label, Table, Card} from "reactstrap";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import FotoModal from '../../assets/images/telkom/modal.png';
import deletePhoto from '../../assets/images/telkom/delete.png';
import Cleave from "cleave.js/react";
import axios from 'axios';
import success from '../../assets/images/telkom/success.svg';
import Result from '../../assets/images/telkom/result.svg';
import Loading from '../../assets/images/telkom/loadingred.gif';
import Template from "../../assets/images/telkom/templateAssessor.xlsx";

import Timeout from '../../assets/images/telkom/timeout.svg';

import crypto from "../Setting/crypto";


const P3 = () => {
  document.title = "Data Assesor";

  // ---------- GET ALL EVENT ------------ //
  const [dataUser, setDataUser] = useState({});

  const [assesor, setAssesor] = useState();
  const [errorEmailSama, setErrorEmailSama] = useState(true);
  const [id, setId] = useState(0);
  const [ids, setIds] = useState([]);

  const [nama, setNama] = useState('');
  const [errorNama, setErrorNama] = useState('');
  const [email, setEmail] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const [noHP, setNoHP] = useState('');
  const [alamat, setAlamat] = useState('');
  const [tanggalLahir, setTanggalLahir] = useState(null);
  const [errorAdd, setErrorAdd] = useState(true);
  const [showPass, setShowPass] = useState(false);
 // const [isEdit, setIsEdit] = useState();
  const [selectAssessor, setSelectAssessor] = useState([]);
  const [countSuccess, setCountSuccess] = useState(0);
  const [countDuplicate, setCountDuplicate] = useState(0);

  // --------- MODAL ---------- //
  const [modalSuccess, setModalSuccess] = useState(false);
  const [modalSuccessEdit, setModalSuccessEdit] = useState(false);
  const [modalSuccessDelete, setModalSuccessDelete] = useState(false);
  const [modalUploadData, setModalUploadData] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uji, setUji] = useState([]);
  const [modalLoading, setModalLoading] = useState(false);
  const [modalResult, setModalResult] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  
  // -------- Search -------- //
  const [kata, setKata] = useState('')


  useEffect(() => {
    // const user = JSON.parse(localStorage.getItem("user"));
    const user = JSON.parse(sessionStorage.getItem("user"));

    if (user) {
      setDataUser(user.results);
      getAllAssesor(user.results.api_key);
    } else {
      window.location.href = "/login-admin";
    }
  }, [])

  const getAllAssesor = (api_key) => {
    const config = {
      method: 'get',
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/assesor`,
      headers: { 
        'x-api-key': api_key
      }
    };
    
    axios(config)
    .then(function (response) {
      setSelectAssessor(
        response.data.map(assesor => {
          return {
            select: false,
            id: assesor.id,
            nama: crypto.aesDecryptIdentitas(assesor.nama),
            email: crypto.aesDecryptIdentitas(assesor.email),
            noHp: crypto.aesDecryptIdentitas(assesor.no_hp),
            alamat: crypto.aesDecryptIdentitas(assesor.alamat),
            tglLahir: assesor.tgl_lahir
          };
        }))

      // setAssesorList(JSON.parse(JSON.stringify(response.data)))
    })
    .catch(function (error) {
      console.log(error);
      console.log(error.response)
    });
  }

  const getAssesorById = (id) => {
    const config = {
      method: 'get',
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/getAssesor/${id}`,
      headers: { 
        'x-api-key': dataUser.api_key
      }
    };
    
    axios(config)
    .then(function (response) {
      const assesors = response.data[0]
      setAssesor(assesors)
     
    //  if (isEdit === true){

      //  getAssesorById(id)
        setNama(crypto.aesDecryptIdentitas(assesors.nama))
        setEmail(crypto.aesDecryptIdentitas(assesors.email))
        setNoHP(crypto.aesDecryptIdentitas(assesors.no_hp))
        setAlamat(crypto.aesDecryptIdentitas(assesors.alamat))
        if(assesors.tgl_lahir !==null){
        var date = new Date (assesors.tgl_lahir)

        var tgl = date.getFullYear()+'-'+((date.getMonth()+1) > 9 ? (date.getMonth()+1) : ('0'+(date.getMonth()+1)) )+'-'+ (date.getDate() > 9 ? date.getDate() : ('0'+date.getDate()))
        setTanggalLahir(tgl)
  
        }
        else{
          setTanggalLahir(null)
        }

    })
    .catch(function (error) {
      console.log(error);
    });
  }
  const changeNama = (event) => {
    const value = event.target.value
    setNama(value)
    if(!value) {
      setErrorNama("Maaf, Nama tidak boleh kosong!")
    } else {
      setErrorNama('')
    }
  }
  const changeEmail = (event) => {
    const value = event.target.value
    setEmail(value)
    if(!value) {
      setErrorEmail("Maaf, Email tidak boleh kosong!")
    } else {
      setErrorEmail('')
    }
  }
  const changePassword= (event) => {
    const value = event.target.value
    setPassword(value)
    // if(!value) {
    //     setErrorPassword('Maaf, password tidak boleh kosong!')
    // }else{
    //     setErrorPassword('')
    // }
  }
  const shower = () => {
    setShowPass(!showPass);
  }
  const changeNoHP = (event) => {
    const value = event.target.value
    setNoHP(value)
  }
  const changeAlamat = (event) => {
    const value = event.target.value
    setAlamat(value)
  }
  const changeTanggalLahir = (event) => {
    const value = event.target.value
    setTanggalLahir(value)
  }

  const addAssesor = () => {
    setErrorAdd(true);
    setErrorEmailSama(true);

    if (nama !== '' && email !== '' && password !== '' ) {
      const data = JSON.stringify({
        "nama": nama,
        "email": email,
        "password": password,
        "noHp": noHP,
        "alamat": alamat,
        "tglLahir": tanggalLahir,
      });
    
      const config = {
        method: 'post',
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/addAssesor`,
        headers: { 
          'x-api-key': dataUser.api_key,
          'Content-Type': 'application/json'
        },
        data :  data,
      };
    
      axios(config)
      .then(function (response) {

        if (response.status === 401){
          setErrorEmailSama(false)
          setTimeout(() => {
            setErrorEmailSama(true);
          }, 5000)
        } else if (response.status === 403){
          setModalJWT(true)
        } else {
          setModalTambahAssesor(false);
          setModalSuccess(true)
          setTimeout(() => {
            setModalSuccess(false)
            getAllAssesor(dataUser.api_key)
          }, 4000)
        }

      })
      .catch(function (error) {
        console.log(error);
        setErrorAdd(false);
        setTimeout(() => {
          setErrorAdd(true);
        }, 5000)
      });
    } else {
      setErrorAdd(false);
      setTimeout(() => {
        setErrorAdd(true);
      }, 5000)
    }

  }

  const ifOpen =()=>{
    setNama("")
    setEmail("")
    setPassword("")
    setNoHP("")
    setAlamat("")
    setTanggalLahir(null)
  }

  const editAssesor = () => {
    setErrorAdd(true);
    setErrorEmailSama(true);
  
    if (id !== 0 && nama !== '' && email !== '') {
      const data = JSON.stringify({
        "id" : id ,
        "nama": nama,
        "email": email,
        "noHp": noHP,
        "alamat": alamat,
        "tglLahir": tanggalLahir,
      });
   
      const config = {
        method: 'patch',
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/editAssesor`,
        headers: { 
          'x-api-key': dataUser.api_key,
          'Content-Type': 'application/json'
        },
        data :data
      };
    
    axios(config)
    .then(function (response) {

     if (response.code === 403){
        setErrorEmailSama(false)
        setTimeout(() => {
          setErrorEmailSama(true);
        }, 5000)
      } else {
        setModalTambahAssesor(false);
        setModalSuccessEdit(true);
        setTimeout(() => {
          setModalSuccessEdit(false)
          getAllAssesor(dataUser.api_key)
        }, 4000)
      }

    })
    .catch(function (error) {
      setErrorAdd(false);
      setTimeout(() => {
        setErrorAdd(true);
      }, 5000)
    });
  } else {
    setErrorAdd(false);
    setTimeout(() => {
      setErrorAdd(true);
    }, 5000)
  }

}

  const getIds = () =>{
    selectAssessor.forEach(d => {
      if (d.select) {
        setIds(d.id)
      }
    });
  }

  const deleteAssesor = () => {
    let arrayids = [];
    
    selectAssessor.forEach(d => {
      if (d.select) {
        arrayids.push(d.id);
      }
    });
    
    const data = JSON.stringify({
      "ids": arrayids
     });

    const config = {
      method: 'delete',
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/delAssesor`,
      headers: { 
        'x-api-key': dataUser.token, 
        'Content-Type': 'application/json'
      },
      data: data
    };
    
    axios(config)
    .then(function (response) {
      setTimeout(() => {
        setModalSuccessDelete(true)
        getAllAssesor(dataUser.api_key)
      }, 4000)
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  // ---------- ON NEXT --------- //
  const [step, setStep] = useState(0);
  const onChange = (nextStep) => {
    setStep(nextStep < 0 ? 0 : nextStep > 4 ? 4 : nextStep);
  };
  const onNext = () => onChange(step + 1);
  const onPrevious = () => onChange(step - 1);

  // --------- MODAL ---------- //
  const [modal_list, setmodal_list] = useState(false);
  function tog_list() {
      setmodal_list(!modal_list);
  }

  // --------- MODAL TAMBAH ASSESSOR ---------- //
  const [modalTambahAssesor, setModalTambahAssesor] = useState(false);
  function openModalTambahAssesor() {
    //console.log (isEdit);
    setModalTambahAssesor(!modalTambahAssesor);
  //  setIsEdit(false);
  }

  const [modalEditAssesor, setModalEditAssesor] = useState(false);
  function openModalEditAssesor() {
   // console.log (isEdit);
   // 
    setModalEditAssesor(!modalEditAssesor);
  //  setIsEdit(false);
  }

  const [modalAssesor, setModalAssesor] = useState(false);
  function openModalAssesor() {
   // setIsEdit(true);
    setModalAssesor(!modalAssesor);
  }

  // --------- MODAL SUCCESS ---------- //
  function openModalSuccess() {
    setModalSuccess(!modalSuccess);
  }

  // --------- MODAL SUCCESS ---------- //
  function openModalSuccessEdit() {
    setModalSuccessEdit(!modalSuccessEdit);
  }

  // --------- MODAL DELETE ---------- //
  function openModalDelete() {
    setModalDelete(!modalDelete);
    if(modalDelete){
      setIds([])
    }
  }

  // --------- MODAL SUCCESS DELETE ---------- //
  function openModalSuccessDelete() {
    setModalSuccessDelete(!modalSuccessDelete);
  }

  // --------- MODAL UPLOAD & UPLOAD ---------- //
  function openModalUploadData() {
    setModalUploadData(!modalUploadData);
  }

  // -------- MODAL LOADING --------- //
  function openModalLoading() {
    setModalLoading(!modalLoading);
  }

  // -------- MODAL RESULT --------- //
  function openModalResult() {
    setModalResult(!modalResult);
  }

  // ---- MULTIPLE UPLOAD ASSESSORR -- //
  const addMultipleAssessor = () => {
    setModalLoading(true)
    let data = new FormData();
    // data.append("file", uji);
    data.append("file", selectedFiles[0])
    // console.log(uji);
    console.log(selectedFiles[0])

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/uploadMultipleAssesor`,
      headers: {
        "x-api-key": dataUser.token,
        'Content-Type': 'multipart/form-data'
      },
      data: data,
    };

    axios.request(config)
      .then(function (response) {
        console.log(response)
        setModalLoading(false)
        setCountSuccess(response.totalEmailSuccess)
        setCountDuplicate(response.totalEmailDuplicate)
        setModalResult(true)
        getAllAssesor(dataUser.api_key);
      })
      .catch(function (error) {
        setModalLoading(false)
        // console.log(error);
        // console.log(error.response)
      });
  };

  function handleAcceptedFiles(files) {
    // console.log(files)
    // console.log(files[0])

    // files.map((file) =>
    //   Object.assign(file, {
    //     preview: URL.createObjectURL(file),
    //     size: formatBytes(file.size),
    //     // originalname: file.name,
    //     // mimetype: file.type,
    //     // path: file.path,
    //     // size: file.size,
    //   })
    // );
    setSelectedFiles(files);
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  // --------- MODAL JWT ---------- //
  const [modalJWT, setModalJWT] = useState(false);
  // ----------- CHECK JWT --------- //
  const checkJWT = () => {
    const config = {
      method: 'post',
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/checkJwt`,
      headers: { 
        'x-api-key': dataUser.token,
      }
    };
    
    axios(config)
    .then(function (response) {
      if(response.status === 403){
        setModalJWT(true)
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }
  function openModalJWT() {
    setModalJWT(!modalJWT);
  }

  return (
    <React.Fragment>
      <div className="page-content">

        <div>
          <h1 style={{color: '#2A4365'}}>Kelola Data Assessor</h1>
        </div>

        {/* Menu */}
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <div style={{display: 'flex', flexDirection: 'column', marginTop: '35px', marginBottom: '30px'}}>
            <div style={{width: '360px', margin: '10px 0px'}} className="search-box">
              <input style={{fontSize: '14px'}} type="text" className="form-control search" placeholder="Search..." onChange={(e) => setKata(e.target.value)} />
              <i style={{height: '100%', fontSize: '14px'}} className="ri-search-line search-icon"></i>
            </div>            
          </div>

          <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column', alignItems: 'flex-end', marginBottom: '30px', marginTop: '35px', width: '50%', gap: '20px'}}>
            <div style={{display: 'flex', marginTop:'10px', gap: '20px'}}>
              <Button style={{ padding: '0.5rem', width: '150px', height: '40px', fontSize: '14px'}} onClick={() => {ifOpen(); openModalTambahAssesor(); checkJWT()}} color='success'>
                Tambah Assessor
              </Button>
              <Button style={{ padding: "0.5rem", width: "150px", height: "40px", fontSize: '14px'}} onClick={() => { openModalUploadData(); setSelectedFiles([]); checkJWT()}} color="info">
                Upload Data
              </Button>     
            </div>
            <div style={{display: 'flex', alignItems: 'center', marginBottom:'10px', gap: '20px'}}>
              <Button style={{ padding: '0.5rem', width: '100px', height: '40px', fontWeight: '700', backgroundColor: 'white', color: '#F56565', border: 'none', fontSize: '14px'}} onClick={() => {getIds(); openModalDelete(); checkJWT()}} >Hapus Data</Button>
            </div>
          </div>
        </div>

        {/* Tabel */}
        <div className="table-responsive">
          <Table style={{fontSize: '14px'}} className="align-middle table-nowrap mb-0">
              <thead style={{backgroundColor: '#FED7D7', color: '#E53E3E'}}>
                <tr>
                  <th>
                  <input
                        type="checkbox"
                        onChange={e => {
                          let value = e.target.checked;
                          setSelectAssessor(
                            selectAssessor.map(d => {
                              d.select = value;
                              return d;
                            })
                          );
                        }}
                      />
                  </th>
                  <th scope="col">No</th>
                  <th style={{width: '300px'}} scope="col">Nama</th>
                  <th scope="col">Email</th>
                  <th style={{width: '200px'}} scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
              {selectAssessor.filter(item => item.nama.toLowerCase().includes(kata.toLowerCase()) || item.email.toLowerCase().includes(kata.toLowerCase())).map((item, index) => (
                <tr key={index}>
                  <td>
                    <input
                      type="checkbox"
                      checked={item.select}
                      onChange={e => {
                      let value = e.target.checked;
                      setSelectAssessor(
                        selectAssessor.map(sd => {
                          if (sd.id === item.id) {
                            sd.select = value;
                          }
                          return sd;
                        })
                      );
                      }}
                    />
                  </td>
                  <td>{1+index}</td>
                  <td>{item.nama}</td>
                  <td>{item.email}</td>
                  <td>
                        <div className="d-flex gap-2">
                          <div className="edit">
                            <button className="btn btn-sm btn-info edit-item-btn"
                            data-bs-toggle="modal" data-bs-target="#showModal" onClick={() => { getAssesorById(item.id); openModalAssesor(); checkJWT() }}>Lihat</button>
                          </div>
                          <div className="remove">
                            <button className="btn btn-sm btn-success remove-item-btn" data-bs-toggle="modal" data-bs-target="#deleteRecordModal" onClick={() => {  setId(item.id); getAssesorById(item.id);openModalEditAssesor(); checkJWT()}} >Edit</button>
                          </div>
                        </div>
                  </td>
                 </tr>
              ))}
              </tbody>
          </Table>
        </div>

      </div>

      {/* ---------- MODAL Delete Assesor -------- */}
      <Modal isOpen={modalDelete} toggle={() => {openModalDelete();}} centered >
        <ModalBody>
       
          {ids.length !== 0 ? (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', overflow: 'auto'}}>  
              <img src={deletePhoto} alt='delete' />
              <h5 style={{textAlign: 'center', marginTop: '30px'}}>Yakin Hapus Data?</h5>
              <p style={{padding: '0 70px', textAlign: 'center'}}>Apakah anda yakin untuk menghapus data ini?</p>
            </div> 
          ) : (
            <> <h5 style={{textAlign: 'center', marginTop: '30px'}}>Tidak ada data yang dipilih</h5>
            </>
          )}
       
        </ModalBody>

        <ModalFooter style={{justifyContent: 'space-evenly'}}>
            <Button style={{ padding: '0.5rem 2.0rem',  backgroundColor: "#ffffff", color: "#718096", border: "1px solid #A0AEC0", fontWeight: "700", borderRadius: '6px'}} className="btn btn-light"onClick={() => {setIds([]);setModalDelete(false); checkJWT()}} >Batal</Button>
            
            {ids.length !== 0 && 
            <Button style={{ padding: '0.5rem 2.0rem', fontWeight: "700", borderRadius: '6px'}} onClick={() => {deleteAssesor(); setModalDelete(false); checkJWT()}} className="btn btn-success">Hapus</Button>
            }
        </ModalFooter>

      </Modal>

       {/* ---------- MODAL SUCCESS DELETE ---------- */}
       <Modal size="md" isOpen={modalSuccessDelete} toggle={() => {openModalSuccessDelete()}} centered>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '90px 10px'}}>
          <img src={success} />
          <h5 style={{textAlign: 'center', margin: '20px'}}>Data Assessor Berhasil Dihapus</h5>
        </div>
      </Modal>

      {/* ---------- MODAL Detail Assesor -------- */}
      <Modal size="sm" isOpen={modalAssesor} toggle={() => {openModalAssesor();}} centered >
           
        <ModalBody style={{display: 'flex', flexDirection: 'column', alignItems: 'start', height: '400px', overflow: 'auto'}}>

        <h4 style={{ marginBottom: '20px'}}>Detail Assessor</h4>
        <p style={{ marginBottom: '1px'}}>Nama</p>
        <p className="text-secondary">{nama}</p>

        <p style={{ marginBottom: '1px'}}>Email</p>
        <p className="text-secondary">{email}</p>
       
        <p style={{ marginBottom: '1px'}}>No HP</p>
        {noHP !== '' ? (
          <p className="text-secondary">{noHP}</p>
        ):(
          <p className="text-secondary">---</p>
        )}
      
        <p style={{ marginBottom: '1px'}}>Alamat</p>
        {alamat !== '' ? (
          <p className="text-secondary">{alamat}</p>
        ):(
          <p className="text-secondary">---</p>
        )}
       
        <p style={{ marginBottom: '1px'}}>Tanggal Lahir</p>
        {tanggalLahir !== null ? (
          <p className="text-secondary">{tanggalLahir}</p>
        ):(
          <p className="text-secondary">---</p>
        )}
     
        </ModalBody>

        <ModalFooter style={{justifyContent: 'space-evenly'}}>
          <Button style={{ padding: '0.5rem 2.0rem',  backgroundColor: "#ffffff", color: "#718096", border: "1px solid #A0AEC0", fontWeight: "700", borderRadius: '6px'}} className="btn btn-light"onClick={() => {setModalAssesor(false); checkJWT()}} >Tutup</Button>
          {/* <Button style={{ padding: '0.5rem 2.0rem'}} onClick={() => {deleteAssesor(); setModalDelete(false)}} className="btn btn-success" >Yakin</Button> */}
        </ModalFooter>

      </Modal>

      {/* ---------- MODAL UPLOAD DATA -------- */}
      <Modal size="md" isOpen={modalUploadData} toggle={() => { openModalUploadData(); }}centered>
        <ModalHeader className="modal-title" id="myModalLabel" toggle={() => { openModalUploadData(); }}>
          Upload Data
        </ModalHeader>

        <ModalBody style={{ display: "flex", flexDirection: "column", alignItems: "center",}}>
          <Row>
            <Alert hidden={errorAdd} color="warning" style={{ textAlign: 'center', backgroundColor: '#FFF3B5', color: '#D88E00', borderColor: '#FFF3B5', margin: '10px 0px', transition: '0.5s'}}>
              Something wrong, please check again
            </Alert>

            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{ marginBottom: "15px" }}>
              <div>
                <Label htmlFor="nama" className="form-label">
                  Pilih Data
                </Label>
                <Dropzone
                  onDrop={(acceptedFiles) => {
                    handleAcceptedFiles(acceptedFiles);
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div
                      style={{ padding: "15px" }}
                      className="dropzone dz-clickable"
                      {...getRootProps()}
                    >
                      <input {...getInputProps()} accept={'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}/>
                      <div className="dz-message needsclick">
                        <div className="mb-3">
                          <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                        </div>
                        <h4>Drop files here or click to upload.</h4>
                      </div>
                    </div>
                  )}
                </Dropzone>
                <div className="list-unstyled mb-0" id="file-previews">
                  {selectedFiles.map((f, i) => {
                    return (
                      <Card
                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                        key={i + "-file"}
                      >
                        <div className="p-2">
                          <Row className="align-items-center">
                            <Col className="col-auto">
                              <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB0UlEQVR4nO2W2UsCURTG588aBCEIegkqCHrqH6iQFgRJKAx6qLdCCvLBmjYizSChh7JVqIekhVJpsUxaRi03lCmXTmR4QoPI5N6ucD84MHPOy/c79xvuCAIXF1mJUhsQqqGqBWiw9tKDEAkAnIUvoGlJTweCBICcCtKDEAkBUIMQCQLINCBIA8ikIWgAyCQhaAHIpCBoAsgkIGgDyCUQTAKUUwIHkPgJAI9QJeIRkniEKtNvN2g+WQXv022+Nv2HRbMexxjOPGE/NFr17N0DLcsGyOSyUFCXw4gzz5Mf+/bLPXYvMot3C42ehq7zPe3GOPaUzGtZ2xdpA9QtaCH+kkTDmrVRcIdv8N10ZGf/V8LoWkLD4VQMn0OpKNTMdbIPoJ7RQCAehFINOKf+dCMLtAE+auTAUmQ+oiRANd1eHQCq6Q64itx/OwHd1kR1AAzvz6PpZFrB57tECNSzGrYBaue74VmJo2n9tgl80a/TMLpsbAPMudfRrC/6kM99/64Ze6m0AvWLOjYBmm19kM5mirZf+Cb8sUfsL5/vsgmwEzhGk9exz+0XZgbnJM5ybzloXRlkD0AkVAIHkPgJAI9QJeIRkniEKhOPkPTPEeLiEn7UO0W1O39XWcpJAAAAAElFTkSuQmCC"
                                height="36"
                                alt={f.name}
                              />
                            </Col>
                            <Col>
                              <Link
                                to="#"
                                className="text-muted font-weight-bold"
                              >
                                {f.name}
                              </Link>
                              <p className="mb-0">
                                <strong>{f.size}</strong>
                              </p>
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    );
                  })}
                </div>
              </div>
            </Col>

            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}style={{ marginBottom: "15px", color: "#A0AEC0" }}>
              <p>
                Hanya dapat mengunggah berkas dengan format .xlsx Ukuran maksimum berkas 50 mb
              </p>
            </Col>

            <Col style={{ textDecorationLine: "underline", cursor: "pointer" }} xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
              <a href={Template} style={{ fontSize: "14px ", color: "#4299E1" }} download>
                Lihat Template
              </a>
            </Col>
          </Row>
        </ModalBody>

        <ModalFooter style={{ justifyContent: "space-evenly" }}>
          <Button style={{ padding: '0.5rem 2.0rem',  backgroundColor: "#ffffff", color: "#718096", border: "1px solid #A0AEC0", fontWeight: "700", borderRadius: '6px'}} className="btn btn-light" onClick= {()=>{setModalUploadData(false); checkJWT()}}>
            Batal
            
          </Button>
          <Button style={{ padding: "0.5rem 2.0rem" ,  fontWeight: "700", borderRadius: '6px'}} className="btn btn-success" onClick={() => {addMultipleAssessor(); setModalUploadData(false); checkJWT()}}>
            Upload
          </Button>
        </ModalFooter>
      </Modal>

      {/* ---------- MODAL EDIT ASSESSOR -------- */}      
      <Modal size="sm" isOpen={modalEditAssesor} toggle={() => {openModalEditAssesor();}} centered >
     
        <ModalHeader className="modal-title" id="myModalLabel" toggle={() => {openModalEditAssesor()}}>
          Edit Data Assesor
        </ModalHeader>

        <ModalBody style={{display: 'flex', flexDirection: 'column', alignItems: 'center', overflow: 'auto', height: '380px'}}>

          <Row style={{alignItems: 'center'}}>
            <Alert hidden={errorAdd}  color="warning" style={{ textAlign: 'center', backgroundColor: '#FFF3B5', color: '#D88E00', borderColor: '#FFF3B5', margin: '10px 0px', transition: '0.5s'}}>
              Something wrong, please check again
            </Alert>
            <Alert hidden={errorEmailSama}  color="warning" style={{ textAlign: 'center', backgroundColor: '#FFF3B5', color: '#D88E00', borderColor: '#FFF3B5', margin: '10px 0px', transition: '0.5s'}}>
              Email sudah di gunakan
            </Alert>
            
            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{marginBottom:'15px'}}>
              <div>
                <Label htmlFor="nama" className="form-label">Nama</Label>
                <Input type='text' value={nama} onChange={changeNama} className="form-control" id='nama' maxLength={100}/>
              </div>
            </Col>

            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{marginBottom:'15px'}}>
              <div>
                <Label htmlFor="email" className="form-label">Email</Label>
                <Input type='text' value={email} onChange={changeEmail} className="form-control" id='email' maxLength={60} />
                {errorEmail &&  (
                <p style={{margin: '0', color: '#F56565', fontSize: '10px', position: 'absolute'}}>{errorEmail}</p>
            )}
              </div>
            </Col>
          
            

            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{marginBottom:'15px'}}>
              <div>
                <Label htmlFor="hp" className="form-label">No. HP</Label>
                <Input type='text' value={noHP} onChange={changeNoHP} className="form-control" id='hp' maxLength={15} />
              </div>
            </Col>

            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{marginBottom:'15px'}}>
              <div>
                <Label htmlFor="alamat" className="form-label">Alamat</Label>
                <Input type='text' value={alamat} onChange={changeAlamat} className="form-control" id='alamat' maxLength={100} />
              </div>
            </Col>

            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{marginBottom:'15px'}}>
              <div>
                <Label htmlFor="lahir" className="form-label">Tanggal Lahir</Label>
                <Cleave
                  placeholder="YYYY-MM-DD"
                  options={{
                    date: true,
                    delimiter: '-',
                     datePattern: ['Y', 'm', 'd']
                  }}
                  value={tanggalLahir} 
                  onChange={changeTanggalLahir} 
                  className="form-control" 
                />
              </div>
            </Col>
            <Alert hidden={errorAdd}  color="warning" style={{ textAlign: 'center', backgroundColor: '#FFF3B5', color: '#D88E00', borderColor: '#FFF3B5', margin: '10px 0px', transition: '0.5s'}}>
              Something wrong, please check again
            </Alert>
            <Alert hidden={errorEmailSama}  color="warning" style={{ textAlign: 'center', backgroundColor: '#FFF3B5', color: '#D88E00', borderColor: '#FFF3B5', margin: '10px 0px', transition: '0.5s'}}>
              Email sudah di gunakan
            </Alert>
            
          </Row>

        </ModalBody>

        <ModalFooter style={{justifyContent: 'space-evenly'}}>
            <Button style={{ padding: '0.5rem 2.0rem', backgroundColor: "#ffffff", color: "#718096", border: "1px solid #A0AEC0", fontWeight: "700", borderRadius: '6px'}} className="btn btn-light" onClick={() => {setModalEditAssesor(false); checkJWT()}}>Batal</Button>
            <Button style={{ padding: '0.5rem 2.0rem', fontWeight: "700", borderRadius: '6px'}} onClick={() => {editAssesor(); checkJWT(); setModalEditAssesor(false); }} className="btn btn-success" >Edit</Button>
        </ModalFooter>

      </Modal>

      {/* ---------- MODAL SUCCESS EDIT ---------- */}
      <Modal size="md" isOpen={modalSuccessEdit} toggle={() => {openModalSuccessEdit()}} centered>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '90px 10px'}}>
        <img src={success} />
          <h5 style={{textAlign: 'center', margin: '20px'}}>Data Assessor Berhasil Diedit</h5>
        </div>
      </Modal>

      {/* ---------- MODAL Tambah Assesor -------- */}
      <Modal size="sm" isOpen={modalTambahAssesor} toggle={() => {openModalTambahAssesor();}} centered >
 
        <ModalHeader className="modal-title" id="myModalLabel" toggle={() => {openModalTambahAssesor()}}>
          Tambah Data Assesor
        </ModalHeader> 

        <ModalBody style={{display: 'flex', flexDirection: 'column', alignItems: 'center', overflow: 'auto', height: '380px'}}>

          <Row style={{alignItems: 'center'}}>
            <Alert hidden={errorAdd} color="warning" style={{ textAlign: 'center', backgroundColor: '#FFF3B5', color: '#D88E00', borderColor: '#FFF3B5', margin: '10px 0px', transition: '0.5s'}}>
              Something wrong, please check again
            </Alert>
            <Alert hidden={errorEmailSama} color="warning" style={{ textAlign: 'center', backgroundColor: '#FFF3B5', color: '#D88E00', borderColor: '#FFF3B5', margin: '10px 0px', transition: '0.5s'}}>
              Email sudah di gunakan
            </Alert>
            
            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{marginBottom:'15px'}}>
              <div>
                <Label htmlFor="nama" className="form-label">Nama <span style={{color: '#E53E3E'}}>*</span></Label>
                <Input type='text' value={nama} onChange={changeNama} className="form-control" id='nama' maxLength={100} />
                {errorNama &&(
                <p style={{margin: '0', color: '#F56565', fontSize: '10px', position: 'absolute'}}>{errorNama}</p>
              )}
              </div>
            </Col>

            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{marginBottom:'15px'}}>
              <div>
                <Label htmlFor="email" className="form-label">Email <span style={{color: '#E53E3E'}}>*</span></Label>
                <Input type='text' value={email} onChange={changeEmail} className="form-control" id='email' maxLength={60} />
                {errorEmail &&(
                <p style={{margin: '0', color: '#F56565', fontSize: '10px', position: 'absolute'}}>{errorEmail}</p>
             )}
              </div>
            </Col>
           <Col>  
              <div className="mb-2">
                <Label className="form-label" htmlFor="password-input">Password <span style={{color: '#E53E3E'}}>*</span></Label>
                <div className="position-relative auth-pass-inputgroup">
                    <Input value={password} onChange={changePassword} type={showPass ? "text" : "password"} className="form-control pe-5" placeholder="Enter password" id="password-input" pattern='^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,24}$' title="Password 8-24 character, setidaknya ada satu huruf kecil, huruf besar, angka dan simbol" required minLength={8} maxLength={24}/>
                    <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" onClick={shower} type="button" id="password-addon"><i className="ri-eye-fill align-middle"></i></button>
                </div>
                <p style={{margin: '0', color: '#ED8936', fontSize: '10px'}}>*Kata sandi minimal 8 karakter harus terdiri atas huruf kapital, angka, dan simbol.</p>
                {errorPassword && (
                    <p style={{margin: '0', color: '#F56565', fontSize: '10px', position: 'absolute'}}>{errorPassword}</p>
                )}
              </div>
            </Col> 
            
            

            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{marginBottom:'15px'}}>
              <div>
                <Label htmlFor="hp" className="form-label">No. HP</Label>
                <Input type='text' value={noHP} onChange={changeNoHP} className="form-control" id='hp' maxLength={15} />
              </div>
            </Col>

            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{marginBottom:'15px'}}>
              <div>
                <Label htmlFor="alamat" className="form-label">Alamat</Label>
                <Input type='text' value={alamat} onChange={changeAlamat} className="form-control" id='alamat' maxLength={100} />
              </div>
            </Col>

            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{marginBottom:'15px'}}>
              <div>
                <Label htmlFor="lahir" className="form-label">Tanggal Lahir</Label>
                <Cleave
                  placeholder="YYYY-MM-DD"
                  options={{
                    date: true,
                    delimiter: '-',
                    datePattern: ['Y', 'm', 'd']
                  }}
                  value={tanggalLahir} 
                  onChange={changeTanggalLahir} 
                  className="form-control" 
                />
              </div>
            </Col>
            <span style={{color: '#E53E3E', fontStyle: 'italic', fontSize: '10px'}}>* Wajib Diisi</span>


            <Alert hidden={errorAdd} color="warning" style={{ textAlign: 'center', backgroundColor: '#FFF3B5', color: '#D88E00', borderColor: '#FFF3B5', margin: '10px 0px', transition: '0.5s'}}>
              Something wrong, please check again
            </Alert>
            <Alert hidden={errorEmailSama} color="warning" style={{ textAlign: 'center', backgroundColor: '#FFF3B5', color: '#D88E00', borderColor: '#FFF3B5', margin: '10px 0px', transition: '0.5s'}}>
              Email sudah di gunakan
            </Alert>
            
          </Row>

        </ModalBody>

        <ModalFooter style={{justifyContent: 'space-evenly'}}>
            <Button style={{ padding: '0.5rem 2.0rem',  backgroundColor: "#ffffff", color: "#718096", border: "1px solid #A0AEC0", fontWeight: "700", borderRadius: '6px'}} className="btn btn-light" onClick={() => {setModalTambahAssesor(false); checkJWT()}}>Batal</Button>
            <Button style={{ padding: '0.5rem 2.0rem', fontWeight: "700", borderRadius: '6px'}} onClick={() => { addAssesor(); checkJWT()} }className="btn btn-success" >Tambah</Button>
        </ModalFooter>

      </Modal>

      {/* ---------- MODAL SUCCESS TAMBAH ---------- */}
      <Modal size="md" isOpen={modalSuccess} toggle={() => {openModalSuccess()}} centered>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '90px 10px'}}>
          <img src={success} />
          <h5 style={{textAlign: 'center', margin: '20px'}}>Data Assessor Berhasil Ditambahkan</h5>
        </div>
      </Modal>

      {/* ------- MODAL LOADING ------- */}
      <Modal isOpen={modalLoading} toggle={() => {}} centered >

        <ModalBody style={{display: 'flex', flexDirection: 'column', alignItems: 'center', overflow: 'auto'}}>
       
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>  
              <img src={Loading} style={{width: '170px'}} />
              <h5 style={{textAlign: 'center', padding: '0 70px'}}>Tunggu Sebentar</h5>
              {/* <p style={{padding: '0 70px', textAlign: 'center', margin:'0'}}>Silahkan check kembali...</p> */}
            </div> 
       
        </ModalBody>

        <ModalFooter style={{justifyContent: 'space-evenly'}}>
            
          {/* <Button style={{ padding: '0.5rem 2.0rem'}} onClick={() => {setErrorSoal(false)}} className="btn btn-success" >Baik</Button> */}
           
        </ModalFooter>

      </Modal>

      {/* ------- MODAL RESULT ------- */}
      <Modal isOpen={modalResult} toggle={() => {openModalResult()}} centered >

        <ModalBody style={{display: 'flex', flexDirection: 'column', alignItems: 'center', overflow: 'auto'}}>
       
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>  
            <img src={Result} style={{width: '170px'}} />
            <h5 style={{textAlign: 'center', padding: '0 70px', fontWeight: '700', fontSize: '22px', color: '#2A4365'}}>Selesai Multiple Upload...</h5>
            <p style={{padding: '0 70px', textAlign: 'center', margin:'0'}}>Berhasil ditambahkan sebanyak : <span style={{color: '#38A169', fontWeight: '600'}}>{countSuccess}</span></p>
            <p style={{padding: '0 70px', textAlign: 'center', margin:'0'}}>Gagal ditambahkan sebanyak : <span style={{color: '#E53E3E', fontWeight: '600'}}>{countDuplicate}</span></p>
          </div> 
       
        </ModalBody>
        <ModalFooter style={{justifyContent: 'space-evenly'}}>
          <Button style={{ padding: '0.5rem 2.0rem'}} onClick={() => {setModalResult(false); getAllAssesor(dataUser.api_key); checkJWT()}} className="btn btn-success" >Oke</Button>
        </ModalFooter>
      </Modal>

      {/* ---------- MODAL JWT -------- */}
      <Modal isOpen={modalJWT} centered >
        <ModalBody style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <img src={Timeout} />
          <h5 style={{ textAlign: 'center', marginTop: '30px', fontSize: '24px', fontWeight: '700', color: '#2A4365' }}>Maaf, Silahkan Login Kembali</h5>
          <p style={{ padding: '0 45px', textAlign: 'center', fontSize: '16px', color: '#4A5568', fontWeight: '400' }}>Sesi Anda sudah kadaluarsa, Jangan khawatir... Click tombol di bawah ini untuk login kembali :)</p>
        </ModalBody>
        <ModalFooter style={{ justifyContent: 'center' }}>
          <Button style={{ padding: '0.5rem 3.0rem' }} className="btn btn-success" onClick={() => { window.localStorage.clear(); window.sessionStorage.clear(); window.location.reload(); window.location.href = "/login-admin"; }} >Login</Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default P3;
