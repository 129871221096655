import React, { useState, useRef ,useEffect } from "react";
import Webcam from 'react-webcam';
import { useReactToPrint } from "react-to-print";
import { Col, Container, Row , Button, Input, Modal, ModalBody, ModalHeader, ModalFooter, Table, Label , Card, CardBody} from "reactstrap";
import logoTelkom from '../../assets/images/telkom/logoACI.png';
import axios from 'axios';
import deletePhoto from '../../assets/images/telkom/delete.png';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import success from '../../assets/images/telkom/success.svg';

import crypto from "../Setting/crypto";

const NILAI = () => {
  document.title = "Data Nilai";

  const [eventList, setEventList] = useState([]);
  const [ambilIdEvent, setAmbilIdEvent] = useState(sessionStorage.getItem("pilihEvent"));
  const [nilai, setNilaiAkhlak] = useState([]);
  const [nilaiApp, setNilaiApp] = useState([]);
  const [sumNilai, setSumNilai] = useState(0);
  const [pelamar, setPelamar] = useState([]);
  const [pelamarRN, setPelamarRN] = useState([]);
  const [pelamarNR, setPelamarNR] = useState([]);
  const [pelamarRWD, setPelamarRWD] = useState([]);
  
   // ---------- GET ALL EVENT ------------ //
  const [dataUser, setDataUser] = useState({});

  // --------- MODAL EXPORT ---------- //
  const [modalExport, setModalExport] = useState(false);
    function openModalExport() {
      setModalExport(!modalExport);
  }
 
  // -------- Search -------- //
  const [kata, setKata] = useState('')


  useEffect(() => {

    // const user = JSON.parse(localStorage.getItem("user"));
    const user = JSON.parse(sessionStorage.getItem("user"));
    if (user) {
      setDataUser(user.results);

      getAllEventInterview(user.results.api_key);
      defaultGetAllEventPeserta(user.results.api_key);
    } else {
      window.location.href = "/login-admin";
    }   
 
   }, [])

    // ------------ Print PDF ------------ //
  const ref = useRef();
  const handlePrint = useReactToPrint({
    content: () => ref.current,
  })
 
   const getAllEventInterview = (api_key) => {

     const config = {
       method: 'get',
       url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/eventlist`,
       headers: { 
        'x-api-key': api_key
      }
     };
     
     axios(config)
     .then(function (response) {
       setEventList(JSON.parse(JSON.stringify(response.data)));
     })
     .catch(function (error) {
       console.log(error);
     });  
   }
  const [rating1, setRating1] = useState(0);
  const [rating2, setRating2] = useState(0);
  const [rating3, setRating3] = useState(0);
  const [rating4, setRating4] = useState(0);
  const [rating5, setRating5] = useState(0);
  const [judulto, setJudulto] = useState('');
  const [namato, setNamato] = useState("");
  const [emailto, setEmailto] = useState("");
  const [kodeto, setKodeto] = useState("");
  const [submitto, setSubmitto] = useState('');
  const [tanggalSekarang, setTanggalSekarang] = useState();
  const [logto, setLogto] = useState([]);

  const getJudulInterview = () => {
    const config = {
      method: 'get',
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/pesertaJudul/${ambilIdEvent}`,
      headers: { 
        'x-api-key': dataUser.token
      },
    };

    axios(config)
      .then(function (response) {
        setJudulto(JSON.parse(JSON.stringify(response.data)).judul_Interview)
      })
      .catch(function (error) {
        console.log(error)
      })
  }

    const getPesertaById = (id) => {
      const config = {
        method: 'get',
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/pesertaEvent/${id}/${ambilIdEvent}`,
        headers: { 
          'x-api-key': dataUser.token
        },
      };

      axios(config)
        .then(function (response) {
          const data = response.data[0]

          // console.log(data.log)
          const coba = JSON.parse(data.log)
          // console.log(coba)
          // console.log(JSON.parse(data.log))

          penilaianStatusAKHLAK(data)
          penilaianStatusApp(data)
          setNamato(crypto.aesDecryptIdentitas(data.nama))
          setEmailto(crypto.aesDecryptIdentitas(data.email))
          setKodeto(data.kode)
          setLogto(coba)
          const now = new Date()
          const hariNow = now.getDate() > 9 ? now.getDate() : "0" + now.getDate();
          const bulanNow = now.getMonth() + 1 > 9 ? (now.getMonth()+1) : "0" + (now.getMonth()+1);
          const tahunNow = now.getFullYear()
  
          if(bulanNow == '01'){
            setTanggalSekarang(`${hariNow} Januari ${tahunNow}`)
          }
          if(bulanNow == '02'){
            setTanggalSekarang(`${hariNow} Februari ${tahunNow}`)
          }
          if(bulanNow == '03'){
            setTanggalSekarang(`${hariNow} Maret ${tahunNow}`)
          }
          if(bulanNow == '04'){
            setTanggalSekarang(`${hariNow} April ${tahunNow}`)
          }
          if(bulanNow == '05'){
            setTanggalSekarang(`${hariNow} Mei ${tahunNow}`)
          }
          if(bulanNow == '06'){
            setTanggalSekarang(`${hariNow} Juni ${tahunNow}`)
          }
          if(bulanNow == '07'){
            setTanggalSekarang(`${hariNow} Juli ${tahunNow}`)
          }
          if(bulanNow == '08'){
            setTanggalSekarang(`${hariNow} Agustus ${tahunNow}`)
          }
          if(bulanNow == '09'){
            setTanggalSekarang(`${hariNow} September ${tahunNow}`)
          }
          if(bulanNow == '10'){
            setTanggalSekarang(`${hariNow} Oktober ${tahunNow}`)
          }
          if(bulanNow == '11'){
            setTanggalSekarang(`${hariNow} November ${tahunNow}`)
          }
          if(bulanNow == '12'){
            setTanggalSekarang(`${hariNow} Desember ${tahunNow}`)
          }
  
          const tanggal = new Date(data.tanggal_Submit)
          const hari = tanggal.getDate() > 9 ? tanggal.getDate() : "0" + tanggal.getDate();
          const bulan = tanggal.getMonth() + 1 > 9 ? (tanggal.getMonth()+1) : "0" + (tanggal.getMonth()+1);
          const tahun = tanggal.getFullYear()
          console.log(bulan)
          
          if(bulan == '01'){
            setSubmitto(`${hari} Januari ${tahun}`)
          }
          if(bulan == '02'){
            setSubmitto(`${hari} Februari ${tahun}`)
          }
          if(bulan == '03'){
            setSubmitto(`${hari} Maret ${tahun}`)
          }
          if(bulan == '04'){
            setSubmitto(`${hari} April ${tahun}`)
          }
          if(bulan == '05'){
            setSubmitto(`${hari} Mei ${tahun}`)
          }
          if(bulan == '06'){
            setSubmitto(`${hari} Juni ${tahun}`)
          }
          if(bulan == '07'){
            setSubmitto(`${hari} Juli ${tahun}`)
          }
          if(bulan == '08'){
            setSubmitto(`${hari} Agustus ${tahun}`)
          }
          if(bulan == '09'){
            setSubmitto(`${hari} September ${tahun}`)
          }
          if(bulan == '10'){
            setSubmitto(`${hari} Oktober ${tahun}`)
          }
          if(bulan == '11'){
            setSubmitto(`${hari} November ${tahun}`)
          }
          if(bulan == '12'){
            setSubmitto(`${hari} Desember ${tahun}`)
          }
          const ratings = [
            data.aspek_Nilai_Amanah,
            data.aspek_Nilai_Kompeten,
            data.aspek_Nilai_Harmonis,
            data.aspek_Nilai_Loyal,
            data.aspek_Nilai_Adaptif,
            data.aspek_Nilai_Kolaboratif,
            data.appearance_Nilai_Impact,
            data.appearance_Nilai_OralCom
          ];
      
          setRating1( ratings.filter((rating) => rating === 1).length)
          setRating2( ratings.filter((rating) => rating === 2).length)
          setRating3( ratings.filter((rating) => rating === 3).length)
          setRating4( ratings.filter((rating) => rating === 4).length)
          setRating5( ratings.filter((rating) => rating === 5).length)
          
          setSumNilai(ratings.reduce((a, b) => a + b, 0));
          
          setNilaiAkhlak([
            { 
              jenis:"Amanah",
              nilai: data.aspek_Nilai_Amanah,
            },
            {
              jenis:"Kompeten",
              nilai: data.aspek_Nilai_Kompeten,
            },
            {
              jenis:"Harmonis",
              nilai: data.aspek_Nilai_Harmonis,
            },
            {
              jenis: "Loyal", 
              nilai: data.aspek_Nilai_Loyal,
            },
            {
              jenis: "Adaptif",
              nilai: data.aspek_Nilai_Adaptif,
            },
            {
              jenis: "Kolaboratif" ,
              nilai: data.aspek_Nilai_Kolaboratif,
            },
         
          ])

          setNilaiApp([
            {
              jenis: "Impact",
              nilai: data.appearance_Nilai_Impact,
            },
            {
              jenis: "OralCom",
              nilai: data.appearance_Nilai_OralCom,
            }
          ])
        
        
        })
        .catch(function (error) {
          console.log(error);
        });

    }
  const [hasilPenilaianAKHLAK, setHasilPenilaianAKHLAK] = useState("Belum Ada Hasil");
  
  const penilaianStatusAKHLAK = (data) => {

    const ratings = [
      data.aspek_Nilai_Amanah,
      data.aspek_Nilai_Kompeten,
      data.aspek_Nilai_Harmonis,
      data.aspek_Nilai_Loyal,
      data.aspek_Nilai_Adaptif,
      data.aspek_Nilai_Kolaboratif,
    ];

   
    if (ratings.filter((rating) => rating === 0).length === 6) {
      setHasilPenilaianAKHLAK(`Belum Ada Hasil`);
    } else {
      if (
       // ratings.filter((rating) => rating === 0).length > 0 ||
        ratings.filter((rating) => rating === 1).length > 0 ||
        ratings.filter((rating) => rating === 2).length >= 3
      ) {
        setHasilPenilaianAKHLAK(`Not Ready At This Time`);
        data.statusNilai = `Not Ready At This Time`
        data.no = 1
        setPelamarNR(oldData => [...oldData, data])
      } else if (
        ratings.filter((rating) => rating === 3).length >= 3 ||
        ratings.filter((rating) => rating === 2).length >0
      ) {
        setHasilPenilaianAKHLAK(`Ready With Development`);
        data.statusNilai = `Ready With Development`
        data.no = 2
        
        setPelamarRWD(oldData => [...oldData, data])
      } 
      else {
        setHasilPenilaianAKHLAK(`Ready Now`);
        data.statusNilai = `Ready Now`
        data.no = 3
        
        setPelamarRN(oldData => [...oldData, data])
      }
    }
  }; 


  const [hasilPenilaianApp, setHasilPenilaianApp ] = useState("Belum Ada Hasil");
  
  const penilaianStatusApp = (data) => {

    const ratings = [
   
      data.appearance_Nilai_Impact,
      data.appearance_Nilai_OralCom
    ];

   
    if (ratings.filter((rating) => rating === 0).length === 6) {
      setHasilPenilaianApp(`Belum Ada Hasil`);
    } else {
      if (
       // ratings.filter((rating) => rating === 0).length > 0 ||
        ratings.filter((rating) => rating === 1).length > 0 ||
        ratings.filter((rating) => rating === 2).length > 0
      ) {
        setHasilPenilaianApp(`Not Ready At This Time`);
        data.statusNilai = `Not Ready At This Time`
        data.no = 1
        setPelamarNR(oldData => [...oldData, data])
      } else if (
        ratings.filter((rating) => rating === 3).length <=2 &&
        ratings.filter((rating) => rating === 3).length >0
      ) {
        setHasilPenilaianApp(`Ready With Development`);
        data.statusNilai = `Ready With Development`
        data.no = 2
        
        setPelamarRWD(oldData => [...oldData, data])
      } 
      else  {
        setHasilPenilaianApp(`Ready Now`);
        data.statusNilai = `Ready Now`
        data.no = 3
        
        setPelamarRN(oldData => [...oldData, data])
      }
    }
  };

  const defaultGetAllEventPeserta = (api_key) => {

    const config = {
      method: 'get',
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/assesor/pesertaFinished/${ambilIdEvent}`,
      headers: { 
        'x-api-key': api_key
      }
    };

    axios(config)
      .then(function (response) {
        const data = response.data;
        
        data.forEach(element => {
          penilaianStatusAKHLAK(element);
          penilaianStatusApp(element)
          
          element.nilai = (element.aspek_Nilai_Amanah + element.aspek_Nilai_Kompeten + element.aspek_Nilai_Adaptif+
                          element.aspek_Nilai_Harmonis + element.aspek_Nilai_Kolaboratif + element.aspek_Nilai_Loyal +
                          element.appearance_Nilai_Impact+ element.appearance_Nilai_OralCom);
        });

        setSelectPeserta(
          response.data.map(peserta => {
            return {
              select: false,
              id: peserta.id,
              id_Event: peserta.id_Event,
              nama: crypto.aesDecryptIdentitas(peserta.nama),
              nilai: peserta.nilai,
              statusNilai:peserta.statusNilai,
              tanggal_Submit: peserta.tanggal_Submit,
              assesor: peserta.assesor,
              status:peserta.status, 
              kode: peserta.kode,
              
            };
          }))

        setPelamar(JSON.parse(JSON.stringify(data)));
      })
      .catch(function (error) {
        console.log(error);
      });

  }

  const changeGetAllEventPeserta = (api_key, id_event) => {

    const config = {
      method: 'get',
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/assesor/pesertaFinished/${id_event}`,
      headers: { 
        'x-api-key': api_key
      }
    };

    axios(config)
      .then(function (response) {
        const data = response.data;
        
        data.forEach(element => {
          penilaianStatusAKHLAK(element);
          penilaianStatusApp(element)
          
          element.nilai = (element.aspek_Nilai_Amanah + element.aspek_Nilai_Kompeten + element.aspek_Nilai_Adaptif+
                          element.aspek_Nilai_Harmonis + element.aspek_Nilai_Kolaboratif + element.aspek_Nilai_Loyal +
                          element.appearance_Nilai_Impact+ element.appearance_Nilai_OralCom);
        });

        setSelectPeserta(
          response.data.map(peserta => {
            return {
              select: false,
              id: peserta.id,
              id_Event: peserta.id_Event,
              nama: crypto.aesDecryptIdentitas(peserta.nama),
              nilai: peserta.nilai,
              statusNilai:peserta.statusNilai,
              tanggal_Submit: peserta.tanggal_Submit,
              assesor: peserta.assesor,
              status:peserta.status, 
              kode: peserta.kode,
              
            };
          }))

        setPelamar(JSON.parse(JSON.stringify(data)));
      })
      .catch(function (error) {
        console.log(error);
      });

  }

  // --------- MODAL DOWNLOAD ---------- //
  const [modalDetail, setModalDetail] = useState(false);
  function openModalDetail() {
    setModalDetail(!modalDetail);
  }

  // --------- MODAL SUCCESS DELETE---------- //
  const [modalSuccessDelete, setModalSuccessDelete] = useState(false);
  function openModalSuccessDelete() {
    setModalSuccessDelete(!modalSuccessDelete);
  }
  // --------- Get All User -------- //
 
  const sort = pelamar.sort((a,b) => {return b.nilai - a.nilai})
  const sorting = sort.sort((a,b) => {return b.no - a.no})

  // delete
  const [selectPeserta, setSelectPeserta] = useState([]);
  const [ids, setIds] = useState([]);
  // --------- MODAL DELETE ---------- //
  const [modalDelete, setModalDelete] = useState(false);
  function openModalDelete() {
    setModalDelete(!modalDelete);
  }
  
  const getIds = () =>{
    selectPeserta.forEach(d => {
      if (d.select) {
        setIds(d.id)
      }
    });
  }

   //delete
   const deletePeserta = () => {
    let arrayids = [];
    
    selectPeserta.forEach(d => {
      if (d.select) {
        arrayids.push(d.id);
      }
    });
    
    const data = JSON.stringify({
      "ids": arrayids
     });

    const config = {
      method: 'delete',
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/peserta/remove`,
      headers: {
        'Content-Type': 'application/json',
        'x-api-key' : dataUser.token
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        setTimeout(() => {
           getAllEventInterview(dataUser.api_key)
         }, 4000)
      })
      .catch(function (error) {
        console.log(error);
      });

  }
 
  // --------- MODAL JWT ---------- //
  const [modalJWT, setModalJWT] = useState(false);
  // ----------- CHECK JWT --------- //
  const checkJWT = () => {
    const config = {
      method: 'post',
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/checkJwt`,
      headers: { 
        'x-api-key': dataUser.token,
      }
    };
    
    axios(config)
    .then(function (response) {
      if(response.status === 403){ 
        setModalJWT(true)
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  const downloadPeserta = () => {
    let arrayids = [];
    
    selectPeserta.forEach(d => {
      if (d.select) {
        arrayids.push(d.id);
      }
    });

    if(arrayids.length === 0 ){
      openModalExport()
    }
    
    const data = JSON.stringify({
      "ids": arrayids
     });

    const config = {
      method: 'post',
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/downloadPeserta`,
      headers: { 
        //'x-api-key': dataUser.token, 
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        let data = response.data
        let temp = []
        var no =1;
        data.forEach(d => {
          let test = {
            "No": no,
            "Nama": crypto.aesDecryptIdentitas(d.nama),
            "Email": crypto.aesDecryptIdentitas(d.email),
            "Kode Peserta" :d.kode,
            "Assessor" : d.assesor,
            "Aspek Nilai Amanah" : d.aspek_Nilai_Amanah,
            "Komentar Amanah" : d.aspek_Komentar_Amanah,
            "Aspek Nilai Kompeten" : d.aspek_Nilai_Kompeten,
            "Komentar Kompeten" : d.aspek_Komentar_Kompeten,
            "Aspek Nilai Harmonis" : d.aspek_Nilai_Harmonis,
            "Komentar Harmonis" : d.aspek_Komentar_Harmonis,
            "Aspek Nilai Loyal" : d.aspek_Nilai_Loyal,
            "Komentar Loyal" : d.aspek_Komentar_Loyal,
            "Aspek Nilai Adaptif" : d.aspek_Nilai_Adaptif,
            "Komentar Adaptif" : d.aspek_Komentar_Adaptif,
            "Aspek Nilai Kolaboratif" : d.aspek_Nilai_Kolaboratif,
            "Komentar Kolaboratif" : d.aspek_Komentar_Kolaboratif,
            "Kelebihan Aspek AKHLAK" : d.aspek_KomentarUmum_Strength,
            "Kekurangan Aspek AKHLAK" : d.aspek_KomentarUmum_Weakness,
            "Nilai Appearance Impact" : d.appearance_Nilai_Impact,
            "Komentar Appearance Impact" : d.appearance_Komentar_Impact,
            "Nilai Appearance OralCom" : d.appearance_Nilai_OralCom,
            "Komentar Appearance OralCom" : d.appearance_Komentar_OralCom,
            "Kelebihan Aspek Appearance" : d.appearance_KomentarUmum_Strength,
            "Kekurangan Aspek Appearance" : d.appearance_KomentarUmum_Weakness,
          }
          temp.push(test)
          no++;
        })


        const ws = XLSX.utils.json_to_sheet(temp);
        const wb = { Sheets: { 'Data Peserta': ws }, SheetNames: ['Data Peserta'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const dataa = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});
        FileSaver.saveAs(dataa, `${judulto}.xlsx`);
    
        setTimeout(() => {
          // setModalSuccess(false)
           defaultGetAllEventPeserta(dataUser.api_key)
         }, 4000)
      })
      .catch(function (error) {
        console.log(error);
      });

  }

  const selectDataInterview = (id_event) =>{
    checkJWT();

    setAmbilIdEvent(id_event);
    sessionStorage.setItem("pilihEvent", id_event);

    changeGetAllEventPeserta(dataUser.api_key, id_event);
  }

  
 
  return (
    <React.Fragment>
      <div className="page-content">
    
        <> 
        <div>
          <h1>Lihat Nilai Interview Pelamar</h1>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', flexDirection: 'column', marginTop: '35px', marginBottom: '30px' }}>

                <p style={{ margin: '0', fontWeight: '700', fontSize: '16px', color: '#4A5568' }}>Pilih Data Interview</p>

                <select style={{ width: '320px', marginBottom: '10px' }} value={ambilIdEvent} onChange={(event) => { selectDataInterview(event.target.value) }} className="form-select" aria-label="Default select example">
                  <option value="0" selected="selected" hidden="hidden">Choose here</option>
                  {eventList.map((item, index) => (
                    <>
                      <option value={item.id}>{item.judul_Interview}</option>
                    </>
                  ))}
                </select>

                <div style={{ width: '360px', margin: '10px 0px' }} className="search-box">
                  <input type="text" className="form-control search" placeholder="Search..." onChange={(e) => setKata(e.target.value)} />
                  <i style={{ height: '40px' }} className="ri-search-line search-icon"></i>
                </div>
                </div>


                <div style={{display: 'flex', justifyContent: 'flex-end', flexDirection: 'column', alignItems: 'flex-end', marginBottom: '30px', marginTop: '35px', width: '50%'}}>
              <div style={{display: 'flex', alignItems: 'center', marginBottom:'10px', gap: '20px'}}>
              <Button style={{ padding: '0.5rem', width: '100px', height: '40px', fontWeight: '700', backgroundColor: 'white', color: '#F56565', border: 'none'}} onClick={() => {getIds(); openModalDelete(); checkJWT();}} >Hapus Data</Button>  
                <p style={{margin:'0px'}}>|</p>
                <Button style={{ padding: '0.5rem', width: '140px', height: '40px', fontWeight: '700', backgroundColor: 'white', color: '#4299E1', border: 'none'}} onClick={() => {getIds(); downloadPeserta(); checkJWT();}}>Download</Button>
              </div>
            </div>
                

             
            </div>

        <div className="table-responsive">
          <Table className="align-middle table-nowrap mb-0 table-fixed">
              <thead style={{backgroundColor: '#FED7D7', color: '#E53E3E'}}>
                  <tr>
                  <th>
                  <input
                        type="checkbox"
                        onChange={e => {
                          let value = e.target.checked;
                          setSelectPeserta(
                            selectPeserta.map(d => {
                              d.select = value;
                              return d;
                            })
                          );
                        }}
                      />
                      </th>
                      
                      <th scope="col">No</th>
                      <th scope="col">Nama</th>
                      <th scope="col" >Total Nilai</th>
                      {/* <th scope="col">Status</th> */}
                      <th scope="col">Action</th>
                  </tr>
              </thead>
              <tbody>
              {selectPeserta.filter(item => item.nama.toLowerCase().includes(kata.toLowerCase())).map((item, index) => (
                <tr key={index}>
                   <td>
                            <input
                              type="checkbox"
                              checked={item.select}
                              onChange={e => {
                                let value = e.target.checked;
                                setSelectPeserta(
                                  selectPeserta.map(sd => {
                                    if (sd.id === item.id) {
                                      sd.select = value;
                                    }
                                    return sd;
                                  })
                                );
                              }}
                            />
                          </td>
                 
                  <td>{1+index}</td>
                  <td>{item.nama}</td>
                  <td>{item.nilai}</td>
                  {/* {item.statusNilai ==="Ready Now" && (
                  <>
                  <td  className="status"><span style={{fontSize: '10px', fontWeight: '600', padding: '5px 10px', backgroundColor: '#f0fff4', color: '#38a169', borderRadius: '8px', textAlign: 'center'}} >Ready Now</span></td>
                  </>
                  )  }
                  {item.statusNilai ==="Not Ready At This Time" && (
                  <td  className="status"><span style={{fontSize: '10px', fontWeight: '600', padding: '5px 10px', backgroundColor: '#fff5f5', color: '#e53e3e', borderRadius: '8px', textAlign: 'center'}}>Not Ready At This Time</span></td>
                  )}
                  {item.statusNilai ==="Ready With Development" && (
                  <td  className="status"><span style={{fontSize: '10px', fontWeight: '600', padding: '5px 10px', backgroundColor: '#fefcbf', color: '#d69e2e', borderRadius: '8px', textAlign: 'center'}}>Ready With Development</span></td>
                  )} */}
                  <td><Button onClick={() => {openModalDetail(); getPesertaById(item.id); getJudulInterview()}} className="btn btn-info" style={{fontSize: '14px'}}>Lihat</Button></td>
                </tr>
              ))}
              </tbody>
          </Table>
        </div>
        </>
     

      </div>
    

      {/* ---------- MODAL DETAIL PELAMAR ----------- */}
      <Modal size="xl" isOpen={modalDetail} toggle={() => {openModalDetail()}} centered >

        <ModalBody>
        <div style={{padding: '20px 30px', fontFamily: 'Nunito'}}  ref={ref}>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px'}}>
              <div>
                <p style={{color: '#1A202C', fontWeight: '700', fontSize: '16px', marginBottom: '10px'}}>PT. TELEKOMUNIKASI INDONESIA, TBK</p>
                <p style={{color: '#E53E3E', fontWeight: '700', fontSize: '16px', margin: '0'}}>Assessment Center Indonesia</p>
              </div>
              <img style={{width: '170px', height: '113px'}} src={logoTelkom}  />
            </div>
            <hr/>
            <div style={{textAlign: 'center', margin: '40px 0'}}>
              <p style={{color: '#1A202C', fontWeight: '700', fontSize: '20px', margin: '0'}}>Lampiran Jawaban</p>
              <p style={{color: '#319795', fontWeight: '700', fontSize: '20px'}}>{judulto}</p>
            </div>
            <div style={{marginBottom: '20px'}}>
              <Row>
                <Col style={{width: '130px'}} xxl={1} xl={1} lg={1} md={1} sm={1} xs={1}>
                  <p style={{fontSize: '14px', fontWeight: '500', color: '#1A202C'}}>Nama Pelamar</p>
                </Col>
                <Col style={{width:'max-content', fontSize: '14px', fontWeight: '500', color: '#1A202C'}} xxl={1} xl={1} lg={1} md={1} sm={1} xs={1}>
                <p>:</p>
                </Col>
                <Col style={{width: 'max-content'}} xxl={1} xl={1} lg={1} md={1} sm={1} xs={1}>
                <p style={{fontSize: '14px', fontWeight: '500', color: '#1A202C'}} >{namato}</p>
                </Col>
              </Row>
              <Row>
                <Col style={{width:'130px'}} xxl={1} xl={1} lg={1} md={1} sm={1} xs={1}>
                  <p style={{fontSize: '14px', fontWeight: '500', color: '#1A202C'}}>Email</p>
                </Col>
                <Col style={{width: 'max-content', fontSize: '14px', fontWeight: '500', color: '#1A202C'}} xxl={1} xl={1} lg={1} md={1} sm={1} xs={1}>
                <p>:</p>
                </Col>
                <Col style={{width: 'max-content'}} xxl={1} xl={1} lg={1} md={1} sm={1} xs={1}>
                <p style={{fontSize: '14px', fontWeight: '500', color: '#1A202C'}} >{emailto}</p>
                </Col>
              </Row>
              <Row>
                <Col style={{width: '130px'}} xxl={1} xl={1} lg={1} md={1} sm={1} xs={1}>
                  <p style={{fontSize: '14px', fontWeight: '500', color: '#1A202C'}}>Kode</p>
                </Col>
                <Col style={{width: 'max-content', fontSize: '14px', fontWeight: '500', color: '#1A202C'}} xxl={1} xl={1} lg={1} md={1} sm={1} xs={1}>
                <p>:</p>
                </Col>
                <Col style={{width: 'max-content'}} xxl={1} xl={1} lg={1} md={1} sm={1} xs={1}>
                <p style={{fontSize: '14px', fontWeight: '500', color: '#1A202C'}} >{kodeto}</p>
                </Col>
              </Row>
              <Row>
                <Col style={{width: '130px'}} xxl={1} xl={1} lg={1} md={1} sm={1} xs={1}>
                  <p style={{fontSize: '14px', fontWeight: '500', color: '#1A202C'}}>Tanggal Submit</p>
                </Col>
                <Col style={{width: 'max-content', fontSize: '14px', fontWeight: '500', color: '#1A202C'}} xxl={1} xl={1} lg={1} md={1} sm={1} xs={1}>
                <p>:</p>
                </Col>
                <Col style={{width: 'max-content'}} xxl={1} xl={1} lg={1} md={1} sm={1} xs={1}>
                <p style={{fontSize: '14px', fontWeight: '500', color: '#1A202C'}} >{submitto}</p>
                </Col>
              </Row>
            </div>
        <Table className="align-middle table-nowrap mb-0 center" style={{fontSize: '14px'}}>
          <thead style={{backgroundColor: '#E6FFFA'}}>
            <tr>
              <th scope="col" style={{width: '10%', textAlign: 'center'}}>No</th>
              <th scope="col" style={{width: '70%'}}>Aspek Penilaian Akhlak</th>
              <th scope="col">Nilai</th>
            </tr>
          </thead>
          <tbody>
            {nilai.map((item, index) => (
              <tr key={index}>
                <td style={{width: '10%', textAlign: 'center'}}>{1+index}</td>
                <td>{item.jenis}</td>
                <td>{item.nilai}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        {hasilPenilaianAKHLAK == 'Ready Now'&&(
          <p style={{fontSize: '14px', marginTop: '1rem'}}> Rekomendasi Penilaian AKHLAK Peserta Adalah: <span className=" text-success"> {hasilPenilaianAKHLAK} </span> </p>
          )}
          {hasilPenilaianAKHLAK == 'Ready With Development'&&(
            <p style={{fontSize: '14px', marginTop: '1rem'}}> Rekomendasi Penilaian AKHLAK Peserta Adalah: <span className=" text-warning"> {hasilPenilaianAKHLAK} </span> </p>
          )}
          {hasilPenilaianAKHLAK == 'Not Ready At This Time'&&(
            <p style={{fontSize: '14px', marginTop: '1rem'}}> Rekomendasi Penilaian AKHLAK Peserta Adalah: <span className=" text-danger"> {hasilPenilaianAKHLAK} </span> </p>
          )}

        <Table className="align-middle table-nowrap mb-0 center" style={{fontSize: '14px'}} >
          <thead style={{backgroundColor: '#E6FFFA'}}>
            <tr>
              <th scope="col" style={{width: '10%', textAlign: 'center'}}>No</th>
              <th scope="col" style={{width: '70%'}}>Aspek Penilaian Appearance</th>
              <th scope="col">Nilai</th>
            </tr>
          </thead>
          <tbody>
             
            {nilaiApp.map((item, index) => (
              <tr key={index}>
                <td style={{width: '10%', textAlign: 'center'}}>{1+index}</td>
                <td>{item.jenis}</td>
                <td>{item.nilai}</td>
              </tr>
            ))}
            
          </tbody>
        </Table>
          {hasilPenilaianApp === 'Ready Now'&&(
            <p style={{fontSize: '14px', marginTop: '1rem'}}> Rekomendasi Penilaian Appearance Peserta Adalah: <span className=" text-success"> {hasilPenilaianApp} </span> </p>
          )}
          {hasilPenilaianApp === 'Ready With Development'&&(
             <p style={{fontSize: '14px', marginTop: '1rem'}}> Rekoemndasi Penilaian Appearance Peserta Adalah: <span className=" text-warning"> {hasilPenilaianApp} </span> </p>
          )}
          {hasilPenilaianApp === 'Not Ready At This Time'&&(
             <p style={{fontSize: '14px', marginTop: '1rem'}}> Rekomendasi Penilaian Appearance Peserta Adalah: <span className=" text-danger"> {hasilPenilaianApp} </span> </p>
          )}
          
            <hr/>
            <div style={{display: 'flex', justifyContent: 'space-between' , marginBottom: '20px'}}>
              {logto === null ? (
                  <>
                    <span style={{fontSize:'14px', fontWeight: '600', color: '#1A202C'}}>
                      Belum Update Sistem
                    </span>
                  </>
                ) : (
                  <>
                  <div style={{display: 'flex', flexDirection: 'column'}}>
                  {logto.map((item, index) => (
                    <> 
                    <p style={{fontSize:'14px', fontWeight: '600', color: '#1A202C', margin: '0px'}}>
                      {item}
                    </p>
                    </>
                   ))}
                  </div>
                  </>
                )}
              <p style={{fontSize:'14px', fontWeight: '600', color: '#1A202C'}}>Diunduh pada : <br/><span>{tanggalSekarang}</span></p>
            </div>
          </div>

         

        </ModalBody>
        <ModalFooter style={{justifyContent: 'space-evenly'}}>
        <Button style={{ padding: '0.5rem 3.0rem' ,backgroundColor: "#ffffff", color: "#718096", border: "1px solid #A0AEC0", fontWeight: "700", borderRadius: "6px", }} type="button" className="btn btn-light" onClick={() => { setModalDetail(false); }}
        >
          Tutup
        </Button>
       
        <Button style={{ padding: '0.5rem 3.0rem', borderRadius: "6px",}} type="button" className="btn btn-success" onClick={() => {handlePrint()}} >Download</Button>
        </ModalFooter>

      </Modal>

      <Modal isOpen={modalExport} toggle={() => {openModalExport()}} centered>
        <ModalBody style={{ display: "flex", flexDirection: "column", alignItems: "center", overflow: "auto"}}>
          {" "}
          <h5 style={{ textAlign: "center", marginTop: "30px" }}>
          Tidak ada data yang dipilih
          </h5>
        </ModalBody>

        <ModalFooter style={{ justifyContent: "space-evenly" }}>
          <Button style={{ padding: "0.5rem 2.0rem", backgroundColor: "#ffffff", color: "#718096", border: "1px solid #A0AEC0", fontWeight: "700", borderRadius: "6px",}} className="btn btn-light"
            onClick={() => {
              setIds([]);
              setModalExport(false);
              checkJWT();
            }}
          >
            Tutup
          </Button>

        </ModalFooter>
      </Modal>

        {/* ------- MODAL DELETE ------- */}
    <Modal isOpen={modalDelete} toggle={() => {openModalDelete();}} centered >
     
     <ModalBody>
     
       {ids.length !== 0 ? (
         <div  style={{display: 'flex', flexDirection: 'column', alignItems: 'center', overflow: 'auto'}}>  
           <img src={deletePhoto} />
           <h5 style={{textAlign: 'center', marginTop: '30px'}}>Yakin Hapus Data?</h5>
           <p style={{padding: '0 70px', textAlign: 'center'}}>Apakah anda yakin untuk menghapus data ini?</p>
         </div> 
       ) : (
         <> <h5 style={{textAlign: 'center', marginTop: '30px'}}>Tidak ada data yang dipilih</h5>
         </>
       )}
     
     </ModalBody>

     <ModalFooter style={{justifyContent: 'space-evenly'}}>
         <Button style={{ padding: '0.5rem 2.0rem',  backgroundColor: "#ffffff",
               color: "#718096",
               border: "1px solid #A0AEC0",
               fontWeight: "700",
               borderRadius: "6px",}} className="btn btn-light" onClick={() => {setIds([]);setModalDelete(false); checkJWT();}} >Batal</Button>
         
         {ids.length !== 0 && 
             <Button style={{ padding: '0.5rem 2.0rem',  
             fontWeight: "700",
             borderRadius: "6px",}} onClick={() => {deletePeserta(); setModalDelete(false); checkJWT();}} className="btn btn-success" >Yakin</Button>

         }
         
     </ModalFooter>

   </Modal>
    {/* ---------- MODAL SUCCESS DELETE ---------- */}
    <Modal size="md" isOpen={modalSuccessDelete} toggle={() => {openModalSuccessDelete()}} centered>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '90px 10px'}}>
          <img src={success} />
          <h5 style={{textAlign: 'center', margin: '20px'}}>Data Peserta Berhasil Dihapus</h5>
        </div>
      </Modal>

   {/* ---------- MODAL JWT -------- */}
   {/* <Modal isOpen={modalJWT} centered >
     <ModalBody style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
       <img src={Timeout} />
       <h5 style={{ textAlign: 'center', marginTop: '30px', fontSize: '24px', fontWeight: '700', color: '#2A4365' }}>Maaf, Silahkan Login Kembali</h5>
       <p style={{ padding: '0 45px', textAlign: 'center', fontSize: '16px', color: '#4A5568', fontWeight: '400' }}>Sesi Anda sudah kadaluarsa, Jangan khawatir... Click tombol di bawah ini untuk login kembali :)</p>
     </ModalBody>
     <ModalFooter style={{ justifyContent: 'center' }}>
       <Button style={{ padding: '0.5rem 3.0rem' }} className="btn btn-success" onClick={() => { window.localStorage.clear(); window.location.reload(); window.location.href = "/login-admin"; }} >Login</Button>
     </ModalFooter>
   </Modal> */}


    </React.Fragment>
  );
};

export default NILAI;
