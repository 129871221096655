import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const Navdata = () => {
    const history = useHistory();
    //state data
    const [isDashboard, setIsDashboard] = useState(false);

    // Pages
    const [isProfile, setIsProfile] = useState(false);

    const [iscurrentState, setIscurrentState] = useState('Dashboard');

    function updateIconSidebar(e) {
        if (e && e.target && e.target.getAttribute("subitems")) {
            const ul = document.getElementById("two-column-menu");
            const iconItems = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                var id = item.getAttribute("subitems");
                if (document.getElementById(id))
                    document.getElementById(id).classList.remove("show");
            });
        }
    }

    useEffect(() => {
        document.body.classList.remove('twocolumn-panel');
        if (iscurrentState === 'I1') {
            history.push("/i1");
        }
        if (iscurrentState === 'I2') {
            history.push("/i2");
        }
        if (iscurrentState === 'I4') {
            history.push("/i4");
        }
        if (iscurrentState !== 'Dashboard') {
            setIsDashboard(false);
        }
    }, [
        history,
        iscurrentState,
        isDashboard,
    ]);

    const menuItems = [
        {
            id: "i1",
            label: "Dashboard",
            icon: "ri-dashboard-fill",
            link: "/i1",
            click: function (e) {
                e.preventDefault();
                setIscurrentState('I1');
            }
        },
        {
            id: "i3",
            label: "Data Assessor",
            icon: " ri-user-follow-fill",
            link: "/i3",
            click: function (e) {
                e.preventDefault();
                setIscurrentState('I3');
            }
        },
        {
            id: "i2",
            label: "Data Interview",
            icon: "ri-message-3-fill",
            link: "/i2",
            click: function (e) {
                e.preventDefault();
                setIscurrentState('I2');
            }
        },
        {
            id: "i4",
            label: "Data Pelamar",
            icon: "ri-team-fill",
            link: "/#",
            stateVariables: isDashboard,
            click: function (e) {
                e.preventDefault();
                setIsDashboard(!isDashboard);
                setIscurrentState('Dashboard');
                updateIconSidebar(e);
            },
            subItems: [
                {
                    id: "onProcess",
                    label: "On Process",
                    link: "/onProcess",
                    parentId: "i4",
                },
                {
                    id: "previewResult",
                    label: "Preview Result",
                    link: "/previewResult",
                    parentId: "i4",
                },
                {
                    id: "nilaiPeserta",
                    label: "Nilai",
                    link: "/nilaiPeserta",
                    parentId: "i4",
                },
            ],
        },
    ];
    return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;