import React, { useEffect, useState } from "react";
import { Alert, Col, Container, Row, Button, Input, Modal, ModalHeader, ModalBody, ModalFooter, Table, Label, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Cleave from "cleave.js/react";
import classnames from "classnames";
import axios from "axios";
import deletePhoto from "../../assets/images/telkom/delete.png";
import soalSalah from "../../assets/images/telkom/errorSoal.svg";
import Loading from "../../assets/images/telkom/loadingred.gif";
import FotoModal from "../../assets/images/telkom/delete.png";
import Refresh from "../../assets/images/telkom/refresh.svg";
import DraftPenilaian from "../../assets/images/telkom/penilaian.svg";

import Timeout from "../../assets/images/telkom/timeout.svg";
import success from "../../assets/images/telkom/success.svg";

import ReactQuill from 'react-quill';
import DOMPurify from "dompurify";

import Dropzone from "react-dropzone";

// Import React FilePond
import { FilePond,  registerPlugin } from 'react-filepond';
// Import FilePond styles
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const I2 = () => {
  document.title = "Data Interview";

  // ------- LOCAL STORAGE -------- //
  // const auth = JSON.parse(localStorage.getItem("user")).results;
  const auth = JSON.parse(sessionStorage.getItem("user")).results;
  const id = auth.id;
  const [dataUser, setDataUser] = useState({});

  // ---------- GET ALL EVENT ------------ //
  const [eventList, setEventList] = useState([]);

  // --------- POST EVENT --------- //
  const [ambilIdEvent, setAmbilIdEvent] = useState(0);
  const [ambilJudulEvent, setAmbilJudulEvent] = useState("");
  
  const [judulInterview, setJudulInterview] = useState("");
  const [errorJudul, setErrorJudul] = useState("");
  const [tanggalMulai, setTanggalMulai] = useState("");
  const [errorTanggalMulai, setErrorTanggalMulai] = useState("");
  const [tanggalBerakhir, setTanggalBerakhir] = useState("");
  const [errorTanggalBerakhir, setErrorTanggalBerakhir] = useState("");
  const [link, setLink] = useState("");
  const [errorLink, setErrorLink] = useState("");
  const [errorAdd, setErrorAdd] = useState(true);
  const [errorTanggal, setErrorTanggal] = useState(true);

  // ---------- ERROR SOAL -------- //
  const [errorKonten, setErrorKonten] = useState("");
  const [errorSoal, setErrorSoal] = useState(false);

  // ---------- DELETE EVENT ----------- //
  const [ambilIdDelete, setAmbilIdDelete] = useState("");
  const [selectEvent, setSelectEvent] = useState([]);
  const [ids, setIds] = useState([]);

  // ---------- Dynamic Form --------- //
  const [addFirst, setAddFirst] = useState(true);
  const [inputFields, setInputFields] = useState([]);
  const [time, setTime] = useState("");
  const [errorTime, setErrorTime] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);

  // --------- UPDATE PERTANYAAN ---------- //
  const [ambilIdUpdate, setAmbilIdUpdate] = useState("");
  const [detailPertanyaan, setDetailPertanyaan] = useState([]);
  const [namaVideo, setNamaVideo] = useState("");

  const [event, setEvent] = useState();
  const [nomor, setNomor] = useState(0);
  const [feedCW, setFeedCW] = useState("");
  const [errorfeedCW, setErrorFeedCW] = useState("");
  const [konten2, setKonten2] = useState("");
  const [jdlKonten2, setJdlKonten2] = useState("");
  const [errorjdlKonten2, setErrorJdlKonten2] = useState("");
  const [konten1, setKonten1] = useState("");
  const [jdlKonten1, setJdlKonten1] = useState("");
  const [errorjdlKonten1, setErrorJdlKonten1] = useState("");
  const [CWkonten1, setCWKonten1] = useState([]);
  const [CWkonten2, setCWKonten2] = useState([]);
  const [feedKonten, setFeedKonten] = useState([]);
  const [pembukaan, setPembukaan] = useState("");
  const [errorPembukaan, setErrorPembukaan] = useState("");
  const [videoPembukaan, setVideoPembukaan] = useState([]);


  const handleAcceptedFiles = (files) => {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setSelectedFiles(files);
  }


  // --------- MODAL ----------- //
  const [modalLoading, setModalLoading] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [modalSuccessAdd, setModalSuccessAdd] = useState(false);

  // --------- MODAL LIHAT VIDEO ---------- //
  const [modalLihatVideo, setModalLihatVideo] = useState(false);
  function openModalLihatVideo() {
    setModalLihatVideo(!modalLihatVideo);
  }

  // --------- MODAL SUCCESS ---------- //
  function openModalSuccess() {
    setModalSuccess(!modalSuccess);
  }
  function openModalSuccessAdd() {
    setModalSuccessAdd(!modalSuccessAdd);
  }

  // --------- MODAL SUCCESS DELETE---------- //
  const [modalSuccessDelete, setModalSuccessDelete] = useState(false);
  function openModalSuccessDelete() {
    setModalSuccessDelete(!modalSuccessDelete);

    // --------- MODAL KONTEN DINAMIS---------- //
  }
  const [modalSuccessCW, setModalSuccessCW] = useState(false);
  function openModalSuccessCW() {
    setModalSuccessCW(!modalSuccessCW);
  }

  const [modalEditKonten1, setModalEditKonten1] = useState(false);
  function openModalEditKonten1() {
    setModalEditKonten1(!modalEditKonten1);
  }

  const [modalEditKonten2, setModalEditKonten2] = useState(false);
  function openModalEditKonten2() {
    setModalEditKonten2(!modalEditKonten2);
  }

  const [modalEditFeedCW, setModalEditFeedCW] = useState(false);
  function openModalEditFeedCW() {
    setModalEditFeedCW(!modalEditFeedCW);
  }
  const [modalEditPembukaan, setModalEditPembukaan] = useState(false);
  function openModalEditPembukaan() {
    setModalEditPembukaan(!modalEditPembukaan);
  }
  // -------- Search -------- //
  const [kata, setKata] = useState("");

  // ------ FEEDBACK ------- //
  const [selectPeserta, setSelectPeserta] = useState([]);

  // -------- DISABLER ------- //
  const [updateInteriew, setUpdateInterview] = useState(true);
  const [uploadDisabler, setUploadDisabler] = useState(true);
  const [uploadVideoDis, setUploadVideoDis] = useState(false);
  const [indexDisabler, setIndexDisabler] = useState(null);
  const [interviewerDisabler, setInterviewerDisabler] = useState(false);
  const [disablerTanggal, setDisablerTanggal] = useState(false);

  // ------ UPLOAD ------- //
  const [upload, setUpload] = useState(0);

  // -------- Buat jalanin Get All ------- //
  useEffect(() => {

    // const user = JSON.parse(localStorage.getItem("user"));
    const user = JSON.parse(sessionStorage.getItem("user"));
    if (user) {
      setDataUser(user.results);

      getAllEventInterview(user.results.api_key);
      getKonten(user.results.token)
      getKonten2(user.results.token)
      getFeedbackCopyWriting(user.results.token)
      getPembukaanInterview(user.results.token)


      //session data
      const session = sessionStorage.getItem("dataInterview");
      if (session !== null) {
        getIdEventInterview(session, user.results.api_key);
        setAmbilIdUpdate(session);
        setAmbilIdEvent(session);
        getAllEventPeserta(session, user.results.api_key);
        setUpdateInterview(false);
        toggleCustom("2");

        checkJWT();
      }

      const session2 = sessionStorage.getItem("pageHistory");
      if (session2 !== null) {
        toggleCustom1("2");
        
        if(session2 === "1") {
          
          toggleCustom2("1");
        }
        else if(session2 === "2") {
          toggleCustom2("2");
        }
        else if(session2 === "3") {
          toggleCustom2("3");
        }
        else if(session2 === "4") {
          toggleCustom2("4");
        }
        else{
          toggleCustom1("1");

        }
        checkJWT();

      }

      setTimeout(() => {
        sessionStorage.removeItem("dataInterview");
        sessionStorage.removeItem("pageHistory");
      }, 1000);
      //end session data
    } else {
      window.location.href = "/login-admin";
    }

  }, []);

  // ---------- GET ALL EVENT ------------ //
  const getAllEventInterview = (api_key) => {
    
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/eventlist`,
      headers: { 
        'x-api-key': api_key
      }
    };

    axios(config)
      .then(function (response) {
        // console.log(response.data.sort((a, b) => {new Date(a.create_At) - new Date(b.create_At); console.log(new Date(a.create_At), new Date(b.create_At))}))
        const isian = response.data
        const baca = isian.sort((a, b) => Date.parse(b.create_At) - Date.parse(a.create_At))

        setSelectEvent(
          baca.map((events) => {
            return {
              select: false,
              id: events.id,
              judul_Interview: events.judul_Interview,
              tanggal_Mulai: events.tanggal_Mulai,
              tanggal_Berakhir: events.tanggal_Berakhir,
              batas_Waktu: events.batas_Waktu,
              create_at: events.create_At
            };
          })
        );
        setEventList(JSON.parse(JSON.stringify(response.data)));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getIds = () => {
    selectEvent.forEach((e) => {
      if (e.select) {
        setIds(e.id);
      }
    });
  };

  // ---------- GET EVENT BY ID ------------ //
  const getIdEventInterview = (u, api_key) => {

    const config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/event/${u}`,
      headers: { 
        'x-api-key': api_key
      }
    };

    axios(config)
      .then(function (response) {
        const interview = response.data[0];
        const soalnya = JSON.parse(interview.detail_Pertanyaan);

        setAmbilJudulEvent(interview.judul_Interview)

        if (interview.detail_Pertanyaan == null) {
          setInputFields([]);
        } else {
           soalnya.map(item => {
            if (item.type === "pengantar" || item.type === "video&video"){
              const data = JSON.stringify({
                  fileName: item.namaVideoSoal,
              });
              const config = {
                method: 'patch',
                url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/getUrlVideo`,
                headers: {
                    'x-api-key': dataUser.token,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(config)
            .then(async function (response) {
              if (item.type === "pengantar"){
                item.file = await response.urlFile

              }
              else{
                item.tanya = await response.urlFile
              }
            })
            .catch(function (error) {
                console.log(error);
            });
            }
            return item;
          })
          setInputFields(soalnya)
        }
        
        const now = new Date()
        const date = new Date(interview.tanggal_Mulai)
        const mulai = date.getFullYear() + "-" + (date.getMonth() + 1 > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)) + "-" + (date.getDate() > 9 ? date.getDate() : "0" + date.getDate());
        setTanggalMulai(mulai);

        const datew = new Date(interview.tanggal_Berakhir)
        const berakhir = datew.getFullYear() + "-" + (datew.getMonth() + 1 > 9 ? datew.getMonth() + 1 : "0" + (datew.getMonth() + 1)) + "-" + (datew.getDate() > 9 ? datew.getDate() : "0" + datew.getDate());
        setTanggalBerakhir(berakhir);


        if (now > date) {
          setDisablerTanggal(true);
        } else {
          setDisablerTanggal(false);
        }

        setJudulInterview(interview.judul_Interview);
        setLink(interview.link);
        setTime(interview.batas_Waktu);
        setEvent(interview);
        onNext();
      })
      .catch(function (error) {
        console.log(error);
      });
  };


  // --------- POST EVENT --------- //
  const changeJudul = (event) => {
    const value = event.target.value;
    setJudulInterview(value);
    if (!value) {
      setErrorJudul("Maaf, judul tidak boleh kosong!");
    } else {
      setErrorJudul("");
    }
  };
  const changeTanggalMulai = (event) => {
    const value = event.target.value;
    setTanggalMulai(value);
    if (!value) {
      setErrorTanggalMulai("Maaf, Tanggal Mulai tidak boleh kosong!");
    } else {
      setErrorTanggalMulai("");
    }
  };
  const changeTanggalBerakhir = (event) => {
    const value = event.target.value;
    setTanggalBerakhir(value);
    if (!value) {
      setErrorTanggalBerakhir("Maaf, Tanggal Berakhir tidak boleh kosong!");
    } else {
      setErrorTanggalBerakhir("");
    }
  };
  const changeLink = (event) => {
    const value = event.target.value;
    setLink(value);
    if (!value) {
      setErrorLink("Maaf, Link tidak boleh kosong!");
    } else {
      setErrorLink("");
    }
  };
  const changeFeedback = (event) => {
    const value = event.target.value;
    setFeedCW(value);
    if (!value) {
      setErrorFeedCW("Maaf, Feedback tidak boleh kosong!");
    } else {
      setErrorFeedCW("");
    }

  };
  const changeJudulKonten1 = (event) => {
    const value = event.target.value;
    setJdlKonten1(value);
    if (!value) {
      setErrorJdlKonten1("Maaf, Judul tidak boleh kosong!");
    } else {
      setErrorJdlKonten1("");
    }

  };
  const changeJudulKonten2 = (event) => {
    const value = event.target.value;
    setJdlKonten2(value);
    if (!value) {
      setErrorJdlKonten2("Maaf, Judul tidak boleh kosong!");
    } else {
      setErrorJdlKonten2("");
    }

  };
  const changePembukaan=(event) => {
    const value = event.target.value;
    setPembukaan(value);
    if (!value) {
      setErrorPembukaan("Maaf, Pembukaan tidak boleh kosong!");
    } else {
      setErrorPembukaan("");
    }

  };

  // ---- GENERATE RANDOM CODE ---- //
  const generate = () => {
    random_kode(7);
  };
  function random_kode(string_length) {
    var random_string = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < string_length; i++) {
      random_string += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    setLink(random_string);
  }

  // ----- ADD EVENT ----- //
  const addEvent = () => {
    setErrorAdd(true);
    setErrorTanggal(true);

    const data = JSON.stringify({
      adminId: id,
      judul_Interview: judulInterview,
      tanggal_Mulai: tanggalMulai,
      tanggal_Berakhir: tanggalBerakhir,
      link: link,
    });
    console.log(data)

    const date1 = new Date(tanggalMulai)
    const date2 = new Date(tanggalBerakhir)

    if (date2 < date1) {
      setErrorTanggal(false);
      setTimeout(() => {
        setErrorTanggal(true);
      }, 5000);
    } else {
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/newEvent`,
        headers: {
          "x-api-key": dataUser.token,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          setAmbilIdEvent(JSON.parse(JSON.stringify(response)).id);
          setAmbilJudulEvent(JSON.parse(JSON.stringify(response)).interview);
          setUpdateInterview(true)
          onNext();
          openModalTambahData();
        })
        .catch(function (error) {
          setErrorAdd(false);
          setTimeout(() => {
            setErrorAdd(true);
          }, 5000);
        });
    }
  };

  // --------- MODAL TAMBAH DATA ---------- //
  const [modalTambahData, setModalTambahData] = useState(false);
  function openModalTambahData() {
    setModalTambahData(!modalTambahData);
  }

  // ---------- DELETE EVENT ----------- //
  const deleteEvent = () => {
    let arrayids = [];
    selectEvent.forEach((d) => {
      if (d.select) {
        //setIds(d.id)
        arrayids.push(d.id);
      }
    });

    const data = JSON.stringify({
      ids: arrayids,
    });

    const config = {
      method: "delete",
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/event/remove`,
      headers: {
        "x-api-key": dataUser.token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setTimeout(() => {
          setModalSuccessDelete(true);
          getAllEventInterview(dataUser.api_key);
        }, 4000);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // --------- MODAL DELETE ---------- //
  const [modalDelete, setModalDelete] = useState(false);
  function openModalDelete() {
    setModalDelete(!modalDelete);
  }

  // ---------- ON NEXT --------- //
  const [step, setStep] = useState(0);
  const onChange = (nextStep) => {
    setStep(nextStep < 0 ? 0 : nextStep > 4 ? 4 : nextStep);
  };
  const onNext = () => onChange(step + 1);
  const onPrevious = () => onChange(step - 1);

  // --------- UPDATE EVENT ----------- //
  const updateEvent = () => {
    const data = JSON.stringify({
      id: ambilIdUpdate,
      judul_Interview: judulInterview,
      tanggal_Mulai: tanggalMulai,
      tanggal_Berakhir: tanggalBerakhir,
    });

    const date1 = new Date(tanggalMulai);
    const date2 = new Date(tanggalBerakhir);

    if (date2 < date1) {
      setErrorTanggal(false);
      setTimeout(() => {
        setErrorTanggal(true);
      }, 5000);
    } else {
      const config = {
        method: "patch",
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/event/update`,
        headers: {
          "x-api-key": dataUser.token,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          setModalEditor(false);
          setModalSuccess(true);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  // --------- MODAL EDIT DATA INTERVIEW ---------- //
  const [modalEditor, setModalEditor] = useState(false);
  function openModalEditor() {
    setModalEditor(!modalEditor);
  }

  // --------- Tab --------- //
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  const [customActiveTab1, setcustomActiveTab1] = useState("1");
  const toggleCustom1 = (tab) => {
    if (customActiveTab1 !== tab) {
      setcustomActiveTab1(tab);
    }
  };

  const [customActiveTab2, setcustomActiveTab2] = useState("1");
  const toggleCustom2 = (tab) => {
    if (customActiveTab2 !== tab) {
      setcustomActiveTab2(tab);
    }
  };

  // ---------- Dynamic Form --------- //
  const handleAdd = () => {
    const tambah = [...inputFields, { tanya: "", type: "", jawab: "", file: "", waktu: "", note: "", namaVideoSoal: "", namaVideoJawab: "", }];
    setInputFields(tambah);
  };
  const handleChange = (event, index) => {
    const ganti = [...inputFields];
    ganti[index].tanya = event.target.value;
    setInputFields(ganti);
  };

  const handleChangeType = (event, index) => {
    const ganti = [...inputFields];
    ganti[index].type = event.target.value;
    if (ganti[index].type === "pengantar" || ganti[index].type === "video&video") {
      setInterviewerDisabler(true);
    } else {
      setInterviewerDisabler(false);
    }
    setInputFields(ganti);
  };
  const handleChangeWaktu = (event, index) => {
    const ganti = [...inputFields];
    ganti[index].waktu = event.target.value;
    setInputFields(ganti);
  };
  const handleChangeJawaban = (event, index) => {
    const ganti = [...inputFields];
    ganti[index].jawab = event.target.value;
    setInputFields(ganti);
  };
  const handleDelete = (index) => {
    const hapus = [...inputFields];
    if (hapus[index].type === "pengantar" || hapus[index].type === "video&video") {
      setInterviewerDisabler(false);
      hapus.splice(index, 1);
      setInputFields(hapus);
    } else {
      hapus.splice(index, 1);
      setInputFields(hapus);
    }
  };

  // ---------- UPLOAD --------- //
  function enabler(index) {
    setIndexDisabler(index);
  }
  const [disable, setDisable] = useState(true);
  function change() {
    setDisable(!disable);
  }

  function enablerActive(index) {
    if (index === indexDisabler) {
      return false;
    } else {
      return true;
    }
  }

  function onChangeUploadFile(event) {
    const files = event.target.files;
    setNamaVideo(event.target.files[0].name);
    setSelectedFiles(files);
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  function generateLink(index) {
    setModalLoading(true);

    let data = new FormData();
    data.append("file", selectedFiles[0],  "soal-"+ambilIdEvent+"-"+ambilJudulEvent+"-"+(index+1)+".mp4");

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/uploadSoalByAdmin`,
      headers: {
        "x-api-key": dataUser.api_key,
        'Content-Type': 'multipart/form-data'
      },
      data: data,
      onUploadProgress: function (event) {
        const persenSelesai = (event.loaded / event.total) * 100;
        setUpload(Math.round(persenSelesai));
      },
    };

    axios(config)
      .then(function (response) {
        const ganti = [...inputFields];
        ganti[index].file = response.urlFile;
        ganti[index].namaVideoSoal = response.file;
        setInputFields(ganti);
        setInterviewerDisabler(false);
        setModalLoading(false);
        enablerActive(null);
        setUpload(0);
      })
      .catch(function (error) {
        console.log(error);
        setModalLoading(false);
      });
  }

  function generateLinkVideo(index) { 
    setModalLoading(true);

    try {
      let data = new FormData();
    data.append("file", selectedFiles[0], "soal-"+ambilIdEvent+"-"+ambilJudulEvent+"-"+(index+1)+".mp4");

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/uploadSoalByAdmin`,
      headers: {
        "x-api-key": dataUser.api_key,
        'Content-Type': 'multipart/form-data'
      },
      data: data,
      onUploadProgress: function (event) {
        const persenSelesai = (event.loaded / event.total) * 100;
        setUpload(Math.round(persenSelesai));
      },
    };

    axios(config)
      .then(function (response) {
        const ganti = [...inputFields];
        ganti[index].tanya = response.urlFile;
        ganti[index].namaVideoSoal = response.file;
        setInputFields(ganti);
        setInterviewerDisabler(false);
        setModalLoading(false);
        enablerActive(null);
        setUpload(0);
      })
      .catch(function (error) {
        console.log(error);
        setModalLoading(false);
      });
    } catch (error) {
      console.log(error);
    }

  }

  // --------- UPDATE PERTANYAAN ---------- //
  const changeTime = (event) => {
    const value = event.target.value;
    setTime(value);
    if (!value) {
      setErrorTime("Maaf, Time tidak boleh kosong!");
    } else {
      setErrorTime("");
    }
  };
  const addQuestion = () => {
    const a = time === null ? 0 : time.split(":");
    const seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
    const soalnya = [...inputFields];
    const typeVideoWaktu = soalnya.map((item) => item.type === "text&video" || item.type === "video&video" ? +item.waktu.split(":")[0] * 60 + +item.waktu.split(":")[1] > 0 ? true : "kena" : false);
    const typeTanya = soalnya.map((item) => item.tanya);
    const typeSoal = soalnya.map((item) => item.type);
    const validasiTypeVideoWaktu = typeVideoWaktu.indexOf("kena");
    const validasiTypeSoal = typeSoal.indexOf("");
    const validasiTypeTanya = typeTanya.indexOf("");

    if (seconds > 0) {
      if (a[2] === "") {
        setErrorSoal(true);
        setErrorKonten("Format Time Interview harus sesuai & Tidak boleh 0");
      } else {
        if (validasiTypeSoal === -1) {
          if (validasiTypeTanya === -1) {
            if (validasiTypeVideoWaktu === -1) {
              const data = JSON.stringify({
                adminId: id,
                id: ambilIdEvent,
                batas_Waktu: time,
                detail_Pertanyaan: detailPertanyaan,
              });

              const config = {
                method: "patch",
                url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/newQuestion`,
                headers: {
                  "x-api-key": dataUser.api_key,
                  "Content-Type": "application/json",
                },
                data: data,
              };

              axios(config)
                .then(function (response) {
                  setModalSuccessAdd(true);
                  window.location.reload();

                  const status = response.status;
                })
                .catch(function (error) {
                  console.log(error);
                });
            } else {
              setErrorSoal(true);
              setErrorKonten(
                "Format type video Batas Waktu Menjawab harus sesuai & Tidak boleh 0"
              );
            }
          } else {
            setErrorSoal(true);
            setErrorKonten("Ada Pertanyaan yang belum terisi");
          }
        } else {
          setErrorSoal(true);
          setErrorKonten("Pilih salah satu Type Soal");
        }
      }
    } else {
      setErrorSoal(true);
      setErrorKonten("Format Time Interview harus sesuai & Tidak boleh 0");
    }
  };

  // --------- MODAL CONFIRM ---------- //
  const [modalConfirm, setModalConfirm] = useState(false);
  function openModalConfirm() {
    setDetailPertanyaan(JSON.stringify(inputFields));
    setModalConfirm(!modalConfirm);
  }

  // --------- MODAL SOAL ERROR ---------- //
  function openModalSoalError() {
    setErrorSoal(!errorSoal);
  }

  const getAllEventPeserta = (u, api_key) => {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/assesor/pesertaSelesai/${u}`,
      headers: { 
        'x-api-key': api_key
      }
    };

    axios(config)
      .then(function (response) {
        setSelectPeserta(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const feedbackCopyWriting = () => {
    if (feedCW === "") {
      errorAdd(false)
    } {
      const data = JSON.stringify({
        feedCW: feedCW
      });

      const config = {
        method: "patch",
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/feedbackCW`,
        headers: {
          "x-api-key": dataUser.token,
          "Content-Type": "application/json",
        },
        data: data
      };

      axios(config)
        .then(function (response) {
          sessionStorage.setItem("pageHistory", 3)
          openModalEditFeedCW()
          openModalSuccessCW()
          window.location.reload();
        })
        .catch(function (error) {
          console.log(error);
        });

    }

  };

  const copywritingKonten2 = () => {
    if (konten2 === "<p><br></p>" || errorjdlKonten2 !== "") {
      setErrorAdd(false);
      setTimeout(() => {
        setErrorAdd(true);
      }, 5000);
    }
    else {
      setErrorAdd(true);
      
      const data = JSON.stringify({
        judul: jdlKonten2,
        konten2: konten2
      });

      console.log("data konten 2", data);

      const config = {
        method: "patch",
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/konten2`,
        headers: {
          "x-api-key": dataUser.token,
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios(config)
        .then(function (response) {
          sessionStorage.setItem("pageHistory", 2)
          openModalEditKonten2()
          openModalSuccessCW()
          window.location.reload();
        })
        .catch(function (error) {
          console.log(error);
        });

    }
  };

  const createPembukaan = () => {

    if (pembukaan === "") {
      setErrorAdd(false);
      setTimeout(() => {
        setErrorAdd(true);
      }, 5000);
    }
    else {
      setErrorAdd(true);
      
      const data = JSON.stringify({
        pembukaan: pembukaan,
      });

      const config = {
        method: "patch",
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/changePembukaan`,
        headers: {
          "x-api-key": dataUser.token,
          "Content-Type": "application/json",
        },
        data: data
      };

      axios(config)
        .then(function (response) {
          sessionStorage.setItem("pageHistory", 4)
          setUploadVideoDis(false)
          checkJWT();
    
          openModalEditPembukaan()
          openModalSuccessCW()
          window.location.reload();
        })
        .catch(function (error) {
          console.log(error);
        });

    }

  };

  const uploadVideoPembukaan = () => {
    setModalLoading(true);

    try {
      let data = new FormData();
      data.append("file", selectedFiles[0]);

      let config = {
        method: "patch",
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/changeVideoPembukaan`,
        headers: {
          "x-api-key": dataUser.api_key,
          'Content-Type': 'multipart/form-data'
        },
        data: data,
        onUploadProgress: function (event) {
          const persenSelesai = (event.loaded / event.total) * 100;
          setUpload(Math.round(persenSelesai));
        },
      };

      axios(config)
      .then(function (response) {
        setModalLoading(false);
        setUploadVideoDis(true)
        setUpload(0);
        change()
      })
      .catch(function (error) {
        console.log(error);
        setModalLoading(false);
      });
      
    } catch (error) {
      console.log(error);
    }
  };

  const copywritingKonten1 = () => {
    if (konten1 === "<p><br></p>" || errorjdlKonten1 !== "") {
      setErrorAdd(false);
      setTimeout(() => {
        setErrorAdd(true);
      }, 5000);
    } else {
      setErrorAdd(true);

      const data = JSON.stringify({
        judul: jdlKonten1,
        konten1: konten1
      });

      const config = {
        method: "patch",
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/konten1`,
        headers: {
          "x-api-key": dataUser.token,
          "Content-Type": "application/json",
        },
        data: data
      };

      axios(config)
        .then(function (response) {
          sessionStorage.setItem("pageHistory", 1)
          openModalEditKonten1()
          openModalSuccessCW()
          window.location.reload();
        })
        .catch(function (error) {
          setErrorAdd(false);
          setTimeout(() => {
            setErrorAdd(true);
          }, 5000);
        });
    }
  };


  // --------- MODAL JWT ---------- //
  const [modalJWT, setModalJWT] = useState(false);
  // ----------- CHECK JWT --------- //
  const checkJWT = () => {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/checkJwt`,
      headers: {
        "x-api-key": auth.token,
      },
    };

    axios(config)
      .then(function (response) {
        if (response.status == 403) {
          setModalJWT(true);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  function openModalJWT() {
    setModalJWT(!modalJWT);
  }

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' },
      { 'indent': '-1' }, { 'indent': '+1' }],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    }
  }

  const formats = [
    'bold', 'italic', 'underline',
    'list', 'bullet', 'indent',
  ]

  const getKonten2 = (token) => {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/getKonten2?id=` + process.env.REACT_APP_ID_DEFAULT_KONTEN,
      headers: { 
        'x-api-key': token
      }
    };

    axios(config)
      .then(function (response) {
        setKonten2(response.data[0].konten2)
        setJdlKonten2(response.data[0].judul_konten2)
        setCWKonten2(response.data)
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getKonten = (token) => {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/getKonten1?id=` + process.env.REACT_APP_ID_DEFAULT_KONTEN,
      headers: { 
        'x-api-key': token
      }
    };

    axios(config)
      .then(function (response) {
        
        setKonten1(response.data[0].konten1)
        setJdlKonten1(response.data[0].judul_konten1)
        setCWKonten1(response.data)


      })
      .catch(function (error) {
        // setCheck(false);

        // openModalContactCenter();
        console.log(error);
      });
  };

  const getFeedbackCopyWriting = (token) => {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/getFeedbackCW`,
      headers: { 
        'x-api-key': token
      }
    };

    axios(config)
      .then(function (response) {
        const data = response.data;

        if(data.length != 0){
          setFeedCW(response.data[0].feedback)
          setFeedKonten(data)
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getPembukaanInterview = (token) => {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/admin/getPembukaan`,
      headers: { 
        'x-api-key': token
      }
    };

    axios(config)
      .then(function (response) {
        setPembukaan(response.data[0].pembukaan)
        setVideoPembukaan(response.data)
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // const refreshVideo = (orb) => {
  //   const namaBuket = process.env.REACT_APP_BUCKET_NAME

  //   const data = JSON.stringify({
  //     "bucketName": namaBuket,
  //     "fileName": orb,
  //   });

  //   const config = {
  //     method: 'patch',
  //     url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/peserta/getFile`,
  //     headers: {
  //       'Content-Type': 'application/json'
  //     },
  //     data: data
  //   };

  //   axios(config)
  //     .then(function (response) {
  //       console.log(response.data)
  //       // soalnya[typePengantar.indexOf(true)].file = response.data
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });

  // }

  return (
    <React.Fragment>
      <div className="page-content">
        {/* Halaman Utama */}
        <Nav style={{ width: "400px" }} tabs className="nav nav-tabs nav-tabs-custom nav-danger nav-justified mb-3">
          <NavItem>
            <NavLink style={{ cursor: "pointer" }}
              className={classnames({
                active: customActiveTab1 === "1",
              })}
              onClick={() => {
                toggleCustom1("1");
                checkJWT();
              }}
            >
              Interview
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink style={{ cursor: "pointer" }}
              className={classnames({
                active: customActiveTab1 === "2",
              })}
              onClick={() => {
                toggleCustom1("2");
                checkJWT();
              }}
            >
              Konten Event
            </NavLink>
          </NavItem>
        </Nav>
        {/* ------------- Content ---------------- */}
        <TabContent activeTab={customActiveTab1} >
          <TabPane tabId="1" id="interview">
            {step === 0 && (
              <>
                <div>
                  <h1 style={{ color: "#2A4365", marginTop: '30px' }}>Data Interview</h1>
                </div>

                {/* Search & Button Hapus Tambah Data */}
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div style={{ display: "flex", flexDirection: "column", marginTop: "35px", marginBottom: "30px" }}>
                    <div style={{ width: "360px", margin: "10px 0px" }} className="search-box">
                      <input type="text" className="form-control search" placeholder="Search..." onChange={(e) => setKata(e.target.value)} />
                      <i style={{ height: "40px" }} className="ri-search-line search-icon"></i>
                    </div>
                  </div>

                  <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "column", alignItems: "flex-end", marginBottom: "30px", marginTop: "35px", width: "50%", gap: "20px" }}>
                    <div style={{ display: "flex", marginTop: "10px", gap: "20px" }}>
                      <Button style={{ padding: "0.5rem 3.0rem" }} onClick={() => { openModalTambahData(); checkJWT() }} color="success">
                        Tambah Data
                      </Button>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", marginBottom: "10px", gap: "20px" }}>
                      <Button style={{ padding: "0.5rem", width: "100px", height: "40px", fontWeight: "700", backgroundColor: "white", color: "#F56565", border: "none" }} onClick={() => { getIds(); openModalDelete(); checkJWT() }} data-target="<img src=x onerror=alert('xss')>">
                        Hapus Data
                      </Button>
                    </div>
                  </div>
                </div>

                {/* ----------- Tabel ----------- */}
                <div className="table-responsive">
                  <Table className="align-middle table-nowrap mb-0">
                    <thead style={{ backgroundColor: "#FED7D7", color: "#E53E3E" }}>
                      <tr>
                        <th>
                          <input
                            type="checkbox"
                            onChange={(e) => {
                              let value = e.target.checked;
                              setSelectEvent(
                                selectEvent.map((d) => {
                                  d.select = value;
                                  return d;
                                })
                              );
                            }}
                          />
                        </th>
                        <th style={{ width: "max-content" }} scope="col">
                          No
                        </th>
                        <th scope="col">Nama</th>
                        <th scope="col">Tanggal Interview</th>
                        <th>Status</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectEvent.filter((item) => item.judul_Interview.toLowerCase().includes(kata.toLowerCase())).map((item, index) => (
                          <tr key={index}>
                            <td>
                              <input
                                type="checkbox"
                                checked={item.select}
                                onChange={(e) => {
                                  let value = e.target.checked;
                                  setSelectEvent(
                                    selectEvent.map((sd) => {
                                      if (sd.id === item.id) {
                                        sd.select = value;
                                      }
                                      return sd;
                                    })
                                  );
                                }}
                              />
                            </td>
                            <td>{1 + index}</td>
                            <td>{item.judul_Interview}</td>
                            <td>
                              {new Date(item.tanggal_Mulai).getFullYear() + "-" +
                                (new Date(item.tanggal_Mulai).getMonth() + 1 > 9 ? new Date(item.tanggal_Mulai).getMonth() + 1 : "0" +
                                  (new Date(item.tanggal_Mulai).getMonth() + 1)) + "-" +
                                (new Date(item.tanggal_Mulai).getDate() > 9 ? new Date(item.tanggal_Mulai).getDate() : "0" +
                                  new Date(item.tanggal_Mulai).getDate())}{" "}
                              -{" "}
                              {new Date(item.tanggal_Berakhir).getFullYear() + "-" +
                                (new Date(item.tanggal_Berakhir).getMonth() + 1 > 9 ? new Date(item.tanggal_Berakhir).getMonth() + 1 : "0" +
                                  (new Date(item.tanggal_Berakhir).getMonth() + 1)) + "-" +
                                (new Date(item.tanggal_Berakhir).getDate() > 9 ? new Date(item.tanggal_Berakhir).getDate() : "0" +
                                  new Date(item.tanggal_Berakhir).getDate())}
                            </td>
                            {new Date(item.tanggal_Berakhir) < new Date() ? (
                              <>
                                <td>
                                  <span style={{ fontSize: "12px",fontWeight: "600", color: "#38A169", backgroundColor: "#F0FFF4", padding: "5px 15px", borderRadius: "5px", }} >
                                    Telah Berakhir
                                  </span>
                                </td>
                              </>
                            ) : new Date(item.tanggal_Mulai) < new Date() ? (
                              <>
                                <td>
                                  <span className="wink" style={{ fontSize: "12px", fontWeight: "600", color: "#D69E2E", backgroundColor: "#FEFCBF", padding: "5px 15px", borderRadius: "5px", }}>
                                    Berlangsung
                                  </span>
                                </td>
                              </>
                            ) : (
                              <>
                                <td>
                                  <span style={{ fontSize: "12px", fontWeight: "600", color: "#4299E1", backgroundColor: "#EBF8FF", padding: "5px 15px", borderRadius: "5px", }} >
                                    Akan Datang
                                  </span>
                                </td>
                              </>
                            )}
                            <td>
                              <div className="d-flex gap-2">
                                <div className="edit">
                                  <button className="btn btn-sm btn-info edit-item-btn" data-bs-toggle="modal" data-bs-target="#showModal"
                                    onClick={() => {
                                      getIdEventInterview(item.id, dataUser.api_key);
                                      setAmbilIdUpdate(item.id);
                                      setAmbilIdEvent(item.id);
                                      getAllEventPeserta(item.id, dataUser.api_key);
                                      setUpdateInterview(false);
                                      checkJWT();
                                    }} 
                                  >
                                    Lihat
                                  </button>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
              </>
            )}

            {/* Halaman Question */}
            {step === 1 && (

              <>
                {/* ---------- HEADER ------------ */}
                <p>
                  <a style={{ cursor: "pointer" }}
                    onClick={() => {
                      onPrevious();
                      setJudulInterview("");
                      setTanggalMulai("");
                      setTanggalBerakhir("");
                      setLink("");
                      setTime("");
                      setInputFields([]);
                      getAllEventInterview(dataUser.api_key);
                      setDisablerTanggal(false);
                      setSelectPeserta([]);
                    }}
                  >
                    {" "}
                    Beranda{" "}
                  </a>{" "}
                  / <span style={{ color: "#4299E1" }}>Detail Interview</span>
                </p>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <h2>{judulInterview}</h2>
                    <p>
                      {tanggalMulai} - {tanggalBerakhir}
                    </p>
                    {/* <p>Link Interview: https://tlkm.co/{link}</p> */}
                  </div>
                  <div>
                    <Button style={{ padding: "0.5rem 3.0rem" }} className="btn btn-success"
                      onClick={() => {
                        openModalEditor();
                        checkJWT();
                      }}
                    >
                      Edit
                    </Button>
                  </div>
                </div>

                <hr />

                {/* ----------- BODY ------------ */}
                <Row>
                  <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                    {/* ----------- Navigation Top ---------- */}
                    <Nav style={{ width: "400px" }} tabs className="nav nav-tabs nav-tabs-custom nav-danger nav-justified mb-3" >
                      <NavItem>
                        <NavLink style={{ cursor: "pointer" }}
                          className={classnames({
                            active: customActiveTab === "1",
                          })}
                          onClick={() => {
                            toggleCustom("1");
                            checkJWT();
                          }}
                        >
                          Detail Pertanyaan
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink style={{ cursor: "pointer" }}
                          className={classnames({
                            active: customActiveTab === "2",
                          })}
                          onClick={() => {
                            toggleCustom("2");
                            checkJWT();
                          }}
                        >
                          Feedback
                        </NavLink>
                      </NavItem>
                    </Nav>

                    {/* ------------- Content ---------------- */}
                    <TabContent activeTab={customActiveTab} className="text-muted">
                      <TabPane tabId="1" id="pertanyaan">
                        <Row>
                          <Col xxl={2} xl={6} lg={4} md={4} sm={4} xs={4} style={{ marginBottom: "15px" }}>
                            <div>
                              <label htmlFor="cleave-time" className="form-label">
                                Time
                              </label>
                              <Cleave
                                placeholder="HH:MM:SS"
                                options={{
                                  time: true,
                                  timePattern: ["h", "m", "s"],
                                }}
                                value={time}
                                onChange={changeTime}
                                className="form-control"
                              />
                              {errorTime && (
                                <p style={{ margin: "0", color: "#F56565", fontSize: "10px", position: "absolute", }}>
                                  {errorTime}
                                </p>
                              )}
                            </div>
                          </Col>
                          <Row>
                            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                              <Card style={{ border: "1px solid #e5e5e5" }}>
                                <CardBody>
                                  {inputFields.map((data, index) => (
                                    <>
                                      <Row>
                                        <Col xxl={8} xl={8} lg={8} md={8} sm={8} xs={8} >
                                          <div>
                                            <Label className="form-label">
                                              Pertanyaan {1 + index}
                                            </Label>
                                            {data.type === "video&video" ? (
                                              <>
                                                {updateInteriew ? (
                                                  <>
                                                    <Row>
                                                      <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6} >
                                                        <input accept="video/*" type="file" className="form-control" onChange={(event) => { onChangeUploadFile(event); enabler(index) }} />
                                                      </Col>
                                                      <Col xxl={3} xl={3} lg={3} md={3} sm={3} xs={3}>
                                                        <Button onClick={() => { generateLinkVideo(index) }} disabled={enablerActive(index)}>
                                                          Upload
                                                        </Button>
                                                      </Col>
                                                    </Row>
                                                  </>
                                                ) : (
                                                  <>
                                                    <Row>
                                                      <Col xxl={3} xl={3} lg={3} md={3} sm={3} xs={3}>
                                                        <Button color="success" style={{fontWeight: "700"}}
                                                          onClick={() => {
                                                            setNomor(index);
                                                            openModalLihatVideo();
                                                          }}
                                                        >
                                                          Lihat Video
                                                        </Button>
                                                      </Col>
                                                      <Col xxl={5} xl={5} lg={5} md={5} sm={5} xs={5}>
                                                        <input
                                                          accept="video/*"
                                                          type="file"
                                                          className="form-control"
                                                          onChange={(event) => {
                                                            onChangeUploadFile(
                                                              event
                                                            );
                                                            enabler(index);
                                                          }}
                                                        />
                                                      </Col>
                                                      <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2}>
                                                        <Button
                                                          onClick={() => {
                                                            generateLinkVideo(
                                                              index
                                                            );
                                                          }}
                                                          disabled={enablerActive(
                                                            index
                                                          )}
                                                        >
                                                          Upload
                                                        </Button>
                                                      </Col>
                                                    </Row>
                                                  </>
                                                )}
                                              </>
                                            ) : (
                                              <>
                                                <Input
                                                  value={data.tanya}
                                                  onChange={(event) =>
                                                    handleChange(event, index)
                                                  }
                                                  maxLength = {275}
                                                  type="text"
                                                  className="form-control"
                                                />
                                              </>
                                            )}
                                          </div>
                                        </Col>
                                        <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                          <Label className="form-label">
                                            Type Soal & Type Video
                                          </Label>
                                          <select
                                            value={data.type}
                                            onChange={(event) =>
                                              handleChangeType(event, index)
                                            }
                                            className="form-select mb-3"
                                            aria-label="Default select example"
                                          >
                                            <option value="" selected="selected" hidden="hidden">
                                              Choose here
                                            </option>
                                            <option value="text&text">
                                              Text & Text
                                            </option>
                                            <option value="text&video">
                                              Text & Video
                                            </option>
                                            <option value="video&video">
                                              Video & Video
                                            </option>
                                            <option value="pengantar">
                                              Pengantar
                                            </option>
                                          </select>
                                        </Col>
                                        <Col style={{ display: "none" }} xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                          <Label className="form-label">
                                            Jawab
                                          </Label>
                                          <Input
                                            value={data.jawaban}
                                            onChange={(event) =>
                                              handleChange(event, index)
                                            }
                                            type="text"
                                            className="form-control"
                                          />
                                        </Col>
                                        {data.type === "text&video" && (
                                          <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                            <div>
                                              <Label className="form-label">
                                                Batas Waktu Menjawab (MM:SS)
                                              </Label>
                                              <Cleave
                                                placeholder="MM:SS"
                                                options={{
                                                  time: true,
                                                  timePattern: ["m", "s"],
                                                }}
                                                value={data.waktu}
                                                onChange={(event) =>
                                                  handleChangeWaktu(event, index)
                                                }
                                                className="form-control"
                                              />
                                            </div>
                                          </Col>
                                        )}
                                        {data.type === "pengantar" && (
                                          <>
                                            {updateInteriew ? (
                                              <>
                                                <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                                  <div>
                                                    <input
                                                      accept="video/*"
                                                      type="file"
                                                      className="form-control"
                                                      onChange={(event) => {
                                                        onChangeUploadFile(event);
                                                        enabler(index);
                                                      }}
                                                    />
                                                  </div>
                                                </Col>
                                                <Col xxl={3} xl={3} lg={3} md={3} sm={3} xs={3}>
                                                  <div>
                                                    <Button
                                                      onClick={() => {
                                                        generateLink(index);
                                                      }}
                                                      disabled={enablerActive(
                                                        index
                                                      )}
                                                    >
                                                      Upload
                                                    </Button>
                                                  </div>
                                                </Col>
                                              </>
                                            ) : (
                                              <>
                                                <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2} >
                                                  <Button color="success" style={{ fontWeight: "700" }}
                                                    onClick={() => {
                                                      setNomor(index);
                                                      openModalLihatVideo();
                                                    }}
                                                  >
                                                    Lihat Video
                                                  </Button>
                                                </Col>
                                                <Col xxl={3} xl={3} lg={3} md={3} sm={3} xs={3}>
                                                  <div>
                                                    <input
                                                      accept="video/*"
                                                      type="file"
                                                      className="form-control"
                                                      onChange={(event) => {
                                                        onChangeUploadFile(event);
                                                        enabler(index);
                                                      }}
                                                    />
                                                  </div>
                                                </Col>
                                                <Col xxl={3} xl={3} lg={3} md={3} sm={3} xs={3}>
                                                  <div>
                                                    <Button
                                                      onClick={() => {
                                                        generateLink(index);
                                                      }}
                                                      disabled={enablerActive(
                                                        index
                                                      )}
                                                    >
                                                      Upload
                                                    </Button>
                                                  </div>
                                                </Col>
                                              </>
                                            )}
                                          </>
                                        )}
                                        {data.type === "video&video" && (
                                          <>
                                            <Col xxl={3} xl={3} lg={3} md={3} sm={3} xs={3}>
                                              <div>
                                                <Label className="form-label">
                                                  Batas Waktu Menjawab (MM:SS)
                                                </Label>
                                                <Cleave
                                                  placeholder="MM:SS"
                                                  options={{
                                                    time: true,
                                                    timePattern: ["m", "s"],
                                                  }}
                                                  value={data.waktu}
                                                  onChange={(event) =>
                                                    handleChangeWaktu(event, index)
                                                  }
                                                  className="form-control"
                                                />
                                              </div>
                                            </Col>
                                          </>
                                        )}

                                        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px", marginBottom: "30px", }} >
                                          <Button style={{ padding: "0.5rem 2.0rem" }} color="danger"
                                            onClick={() => {
                                              handleDelete(index);
                                            }}
                                            disabled={disablerTanggal}
                                          >
                                            Hapus Data
                                          </Button>
                                        </div>
                                      </Row>
                                    </>
                                  ))}

                                  <div style={{ display: "flex", justifyContent: "center"}}>
                                    <Button style={{ borderRadius: "100px" }} size="lg" color="primary"
                                      onClick={() => {
                                        handleAdd();
                                        checkJWT();
                                      }}
                                      disabled={ interviewerDisabler || disablerTanggal}
                                    >
                                      +
                                    </Button>
                                  </div>
                                  <div style={{ display: "flex", justifyContent: "flex-end"}}>
                                    <Button style={{ padding: "0.5rem 5.0rem" }} color="success"
                                      onClick={() => {
                                        openModalConfirm();
                                        checkJWT();
                                      }}
                                      disabled={
                                        interviewerDisabler ||
                                          disablerTanggal ||
                                          inputFields.length === 0
                                          ? true
                                          : false
                                      }
                                    >
                                      Simpan
                                    </Button>
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </Row>
                      </TabPane>
                      <TabPane tabId="2">
                        <div className="table-responsive">
                          <Table className="align-middle table-nowrap mb-0">
                            <thead style={{ backgroundColor: "#FED7D7", color: "#E53E3E"}}>
                              <tr>
                                <th style={{ width: "100px", textAlign: "center" }} scope="col" >
                                  No
                                </th>
                                <th style={{ width: "160px" }} scope="col">
                                  Nama
                                </th>
                                <th style={{ width: "160px" }}>Email</th>
                                <th style={{ width: "150px" }}>No HP</th>
                                <th scope="col">Pesan</th>
                              </tr>
                            </thead>
                            <tbody>
                              {selectPeserta.map((item, index) => (
                                <tr key={index}>
                                  <td style={{ textAlign: "center" }} scope="row">
                                    {1 + index}
                                  </td>
                                  <td>{item.nama}</td>
                                  <td>{item.email}</td>
                                  <td>{item.no_HP}</td>
                                  <td>{item.feedback}</td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </TabPane>
                    </TabContent>
                  </Col>
                </Row>
              </>
            )}
          </TabPane>
          <TabPane tabId="2" id="konten">
            <>
              {/* ----------- BODY ------------ */}
              <Row>
                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                  {/* ----------- Navigation Top ---------- */}
                  <Nav
                    style={{ width: "400px" }}
                    tabs
                    className="nav nav-tabs nav-tabs-custom nav-danger nav-justified mb-3"
                  >
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab2 === "1",
                        })}
                        onClick={() => {
                          toggleCustom2("1");
                          checkJWT();
                        }}
                      >
                        Konten 1
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab2 === "2",
                        })}
                        onClick={() => {
                          toggleCustom2("2");
                          checkJWT();
                        }}
                      >
                        Konten 2
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab2 === "3",
                        })}
                        onClick={() => {
                          toggleCustom2("3");
                          checkJWT();
                        }}
                      >
                        Feedback
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab2 === "4",
                        })}
                        onClick={() => {
                          toggleCustom2("4");
                          checkJWT();
                        }}
                      >
                        Pembukaan Interview
                      </NavLink>
                    </NavItem>
                  </Nav>

                  {/* ------------- Content ---------------- */}
                  <TabContent activeTab={customActiveTab2} >
                    <TabPane tabId="1" id="konten1">
                      <Row>
                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                          <div style={{marginBottom: '20px', display: "flex", justifyContent: "flex-start"}}>
                            <Button style={{ padding: "0.5rem 5.0rem" }} color="success"
                              onClick={() => {
                                openModalEditKonten1();
                                checkJWT();
                              }}
                            >
                              Edit
                            </Button>
                          </div>
                          <Card style={{ border: "1px solid #e5e5e5" }}>
                            <CardBody>
                              {CWkonten1.map((item) => (
                                <>
                                  <p style={{ margin: '0', fontSize: '30px', fontWeight: '700', color: '#2A4365', padding: '0px 30px' }}>{item.judul_konten1}</p>
                                  <hr style={{ color: '#b7b7b7' }} />
                                  <Col className="quill-only" style={{ marginLeft: '25px', paddingBottom: '10px' }}>
                                    <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(item.konten1)}}></p>
                                  </Col>
                                </>
                              ))}
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row>
                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                          <div style={{marginBottom: '20px', display: "flex", justifyContent: "flex-start"}}>
                            <Button
                              style={{ padding: "0.5rem 5.0rem" }} color="success"
                              onClick={() => {
                                openModalEditKonten2();
                                checkJWT();
                              }}
                            >
                              Edit
                            </Button>
                          </div>
                          <Card style={{ border: "1px solid #e5e5e5" }}>
                            <CardBody>
                            {CWkonten2.map((item) => (<>
                              <p style={{ margin: '0', fontSize: '30px', fontWeight: '700', color: '#2A4365', padding: '0px 30px' }}>{item.judul_konten2}</p>
                              <hr style={{ color: '#b7b7b7' }} />
                              <Col className="quill-only" style={{ marginLeft: '25px', paddingBottom: '10px' }}>
                                <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(item.konten2)}}></p>
                              </Col>
                            </>))}
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="3">
                      <Row>
                        <Col>
                          <div style={{marginBottom: '20px', display: "flex", justifyContent: "flex-start"}}>
                            <Button style={{ padding: "0.5rem 5.0rem" }} color="success"
                              onClick={() => {
                                openModalEditFeedCW()
                                checkJWT();
                              }}
                            >
                              Edit
                            </Button>
                          </div>

                          <Card style={{ width: "500px", border: "1px solid #e5e5e5", justifyContent: "center" }}>
                            <CardBody>
                            {feedKonten.map((item) => (
                              <> 
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center",}}>
                                  <div>
                                    <h5 style={{textAlign: "center", marginTop: "15px", color: "#2A4365", fontWeight: "700", fontSize: "18px"}}>
                                      {item.feedback}
                                    </h5>
                                  </div>
                                  <div style={{ marginTop: "10px", width: "100%" }}>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      placeholder="Tuliskan pendapat Anda ..."
                                      maxLength={250}
                                      disabled
                                    />
                                  </div>
                                  <Button style={{ marginTop: "10px", padding: "0.5rem 3.0rem", fontWeight: "700", borderRadius: "6px",}} className="btn btn-success" disabled>
                                    Kirim
                                  </Button>
                                </div>
                                </>
                            ))}
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="4">
                      <Row>
                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                          <div style={{ marginBottom: '20px', display: "flex",justifyContent: "flex-start"}}>
                            <Button style={{ padding: "0.5rem 5.0rem" }} color="success"
                              onClick={() => {
                                openModalEditPembukaan();
                                checkJWT();
                              }}
                            >
                              Edit
                            </Button>
                          </div>
                          {videoPembukaan.map((item) => (
                            <> 
                              <div style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", boxShadow: "0px 2px 5px #0000001a, 0px 0px 2px #00000033", borderRadius: "8px", width: "680px" }}>
                                  <p style={{ fontSize: "24px", fontWeight: "600", color: "#2A4365", margin: "20px 0px" }}>
                                    {item.pembukaan}
                                  </p>

                                  <video style={{ width: "480px", borderRadius: "8px",  marginBottom: "40px" }} controls>
                                    <source src={item.urlFile} type="video/mp4" />
                                    Your browser does not support HTML5 video.
                                  </video>
                                </div>
                              </div>
                            </>
                          ))}
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </Col>
              </Row>
            </>

          </TabPane>

        </TabContent>
      </div>

      {/* --------- MODAL TAMBAH DATA -------- */}
      <Modal isOpen={modalTambahData} toggle={() => { openModalTambahData() }} centered >
        <ModalHeader className="modal-title" id="myModalLabel" toggle={() => { openModalTambahData() }}>
          Data Interview
        </ModalHeader>

        <ModalBody style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <Row style={{ alignItems: "center" }}>
            <Alert hidden={errorAdd} color="warning" style={{ textAlign: "center", backgroundColor: "#FFF3B5", color: "#D88E00", borderColor: "#FFF3B5", margin: "10px 0px", transition: "0.5s" }}>
              Something wrong, please check again
            </Alert>
            <Alert hidden={errorTanggal} color="warning" style={{ textAlign: "center", backgroundColor: "#FFF3B5", color: "#D88E00", borderColor: "#FFF3B5", margin: "10px 0px", transition: "0.5s" }}>
              Tanggal Berakhir tidak boleh kurang dari Tanggal Mulai
            </Alert>
            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{ marginBottom: "15px" }}
            >
              <div>
                <Label htmlFor="judul" className="form-label">
                  Judul
                </Label>
                <Input
                  type="text"
                  value={judulInterview}
                  onChange={changeJudul}
                  className="form-control"
                  id="judul"
                  maxLength={50}
                />
              </div>
              {errorJudul && (
                <p style={{ margin: "0", color: "#F56565", fontSize: "10px", position: "absolute" }}>
                  {errorJudul}
                </p>
              )}
            </Col>

            <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6} style={{ marginBottom: "15px" }} >
              <div>
                <Label htmlFor="mulai" className="form-label">
                  Tanggal Mulai
                </Label>
                <Cleave
                  placeholder="YYYY-MM-DD"
                  options={{
                    date: true,
                    delimiter: "-",
                    datePattern: ["Y", "m", "d"],
                  }}
                  value={tanggalMulai}
                  onChange={changeTanggalMulai}
                  className="form-control"
                />
                {errorTanggalMulai && (
                  <p style={{ margin: "0", color: "#F56565", fontSize: "10px", position: "absolute"}}>
                    {errorTanggalMulai}
                  </p>
                )}
              </div>
            </Col>
            <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6} style={{ marginBottom: "15px" }}>
              <div>
                <Label htmlFor="berakhir" className="form-label">
                  Tanggal Berakhir
                </Label>
                <Cleave
                  placeholder="YYYY-MM-DD"
                  options={{
                    date: true,
                    delimiter: "-",
                    datePattern: ["Y", "m", "d"],
                  }}
                  value={tanggalBerakhir}
                  onChange={changeTanggalBerakhir}
                  className="form-control"
                />
                {errorTanggalBerakhir && (
                  <p style={{ margin: "0", color: "#F56565", fontSize: "10px", position: "absolute", }}>
                    {errorTanggalBerakhir}
                  </p>
                )}
              </div>
            </Col>
            <Col xxl={10} xl={10} lg={10} md={10} sm={10} xs={10} style={{ marginBottom: "24px" }}
            >
              <div>
                <Label htmlFor="link" className="form-label">
                  Link
                </Label>
                <Input
                  type="text"
                  value={link}
                  onChange={changeLink}
                  className="form-control"
                  id="link"
                  disabled
                />
                {errorLink && (
                  <p style={{ margin: "0", color: "#F56565", fontSize: "10px", position: "absolute" }}
                  >
                    {errorLink}
                  </p>
                )}
              </div>
            </Col>

            <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2}>
              <Button onClick={generate} style={{ backgroundColor: "#ffffff", borderColor: "#ffffff" }}>
                <img src={Refresh} />
              </Button>
            </Col>
          </Row>
        </ModalBody>

        <ModalFooter style={{ justifyContent: "space-evenly" }}>
          <Button style={{ padding: "0.5rem 3.0rem", backgroundColor: "#ffffff", color: "#718096", border: "1px solid #A0AEC0", fontWeight: "700", borderRadius: "6px"}} className="btn btn-light"
            onClick={() => {
              setModalTambahData(false);
            }}
          >
            Batal
          </Button>
          <Button style={{ padding: "0.5rem 3.0rem", fontWeight: "700", borderRadius: "6px" }} className="btn btn-success"
            onClick={() => {
              addEvent();
              checkJWT();
            }}
          >
            Membuat
          </Button>
        </ModalFooter>
      </Modal>

      {/* --------- MODAL TAMBAH EDIT -------- */}
      <Modal isOpen={modalEditor} toggle={() => { openModalEditor() }} centered>
        <ModalHeader className="modal-title" id="myModalLabel" toggle={() => { openModalEditor() }}>
          Edit Data Interview
        </ModalHeader>

        <ModalBody style={{ display: "flex", flexDirection: "column", alignItems: "center"}}>
          <Row style={{ alignItems: "center" }}>
            <Alert hidden={errorAdd} color="warning" style={{ textAlign: "center", backgroundColor: "#FFF3B5", color: "#D88E00", borderColor: "#FFF3B5", margin: "10px 0px", transition: "0.5s" }}>
              Something wrong, please check again
            </Alert>
            <Alert hidden={errorTanggal} color="warning" style={{ textAlign: "center", backgroundColor: "#FFF3B5", color: "#D88E00", borderColor: "#FFF3B5", margin: "10px 0px", transition: "0.5s" }}
            >
              Tanggal Berakhir tidak boleh kurang dari Tanggal Mulai
            </Alert>
            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{ marginBottom: "15px" }}>
              <div>
                <Label htmlFor="judul" className="form-label">
                  Judul
                </Label>
                <Input
                  type="text"
                  value={judulInterview}
                  onChange={changeJudul}
                  className="form-control"
                  id="judul"
                  maxLength={50}
                />
              </div>
              {errorJudul && (
                <p style={{ margin: "0", color: "#F56565", fontSize: "10px", position: "absolute" }}>
                  {errorJudul}
                </p>
              )}
            </Col>

            <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6} style={{ marginBottom: "15px" }}>
              <div>
                <Label htmlFor="mulai" className="form-label">
                  Tanggal Mulai
                </Label>
                <Cleave
                  placeholder="YYYY-MM-DD"
                  options={{
                    date: true,
                    delimiter: "-",
                    datePattern: ["Y", "m", "d"],
                  }}
                  value={tanggalMulai}
                  onChange={changeTanggalMulai}
                  className="form-control"
                />
                {errorTanggalMulai && (
                  <p style={{ margin: "0", color: "#F56565", fontSize: "10px", position: "absolute" }}>
                    {errorTanggalMulai}
                  </p>
                )}
              </div>
            </Col>
            <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6} style={{ marginBottom: "15px" }}>
              <div>
                <Label htmlFor="berakhir" className="form-label">
                  Tanggal Berakhir
                </Label>
                <Cleave
                  placeholder="YYYY-MM-DD"
                  options={{
                    date: true,
                    delimiter: "-",
                    datePattern: ["Y", "m", "d"],
                  }}
                  value={tanggalBerakhir}
                  onChange={changeTanggalBerakhir}
                  className="form-control"
                />
                {errorTanggalBerakhir && (
                  <p style={{ margin: "0", color: "#F56565", fontSize: "10px", position: "absolute" }}>
                    {errorTanggalBerakhir}
                  </p>
                )}
              </div>
            </Col>
          </Row>
        </ModalBody>

        <ModalFooter style={{ justifyContent: "space-evenly" }}>
          <Button style={{ padding: "0.5rem 3.0rem", backgroundColor: "#ffffff", color: "#718096", border: "1px solid #A0AEC0", fontWeight: "700", borderRadius: "6px" }}className="btn btn-light"
            onClick={() => {
              setModalEditor(false);
            }}
          >
            Batal
          </Button>
          <Button style={{ padding: "0.5rem 3.0rem", fontWeight: "700", borderRadius: "6px" }} className="btn btn-success"
            onClick={() => {
              updateEvent();
              checkJWT();
            }}
          >
            Update
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalEditKonten1} toggle={() => {openModalEditKonten1()}} centered>
        <ModalBody style={{ display: "flex", flexDirection: "column", alignItems: "center"}}>
          <Row style={{ alignItems: "center" }}>
            <Alert hidden={errorAdd} color="warning" style={{ textAlign: "center", backgroundColor: "#FFF3B5", color: "#D88E00", borderColor: "#FFF3B5", margin: "10px 0px", transition: "0.5s",}}>
              Something wrong, please check again
            </Alert>
            <div style={{ marginTop: "20px", marginBottom: "35px" }}>
              <Label htmlFor="judul" className="form-label">
                Judul
              </Label>
              <Input
                type="text"
                value={jdlKonten1}
                onChange={changeJudulKonten1}
                className="form-control"
                id="judul"
                maxLength={50}
              />
              {errorjdlKonten1 && (
                <p style={{ margin: "0", color: "#F56565", fontSize: "10px", position: "absolute" }}>
                  {errorjdlKonten1}
                </p>
              )}
            </div>

            <Label htmlFor="konten" className="form-label">
              Konten
            </Label>

            <ReactQuill theme="snow" value={konten1} onChange={setKonten1} modules={modules} formats={formats} />
            {konten1 === "<p><br></p>" && (
              <p style={{ marginTop: "470px", color: "#F56565", fontSize: "10px", position: "fixed"}}>
                Maaf, Konten tidak boleh kosong
              </p>
            )}

          </Row>
        </ModalBody>

        <ModalFooter style={{ justifyContent: "space-evenly" }}>
          <Button style={{ padding: "0.5rem 3.0rem", backgroundColor: "#ffffff", color: "#718096", border: "1px solid #A0AEC0", fontWeight: "700", borderRadius: "6px"}} className="btn btn-light"
            onClick={() => {
              setModalEditKonten1(false);
            }}
          >
            Batal
          </Button>
          <Button style={{ padding: "0.5rem 3.0rem", fontWeight: "700", borderRadius: "6px"}} className="btn btn-success"
            onClick={() => {
              copywritingKonten1()
              //judulKonten1()
              checkJWT();
            }}
          >
            Simpan
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalEditKonten2} toggle={() => {  openModalEditKonten2() }} centered>
        <ModalBody style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <Row style={{ alignItems: "center" }}>
            <Alert hidden={errorAdd}  color="warning" style={{ textAlign: "center", backgroundColor: "#FFF3B5", color: "#D88E00", borderColor: "#FFF3B5", margin: "10px 0px", transition: "0.5s" }}>
              Something wrong, please check again
            </Alert>
            <div style={{ marginTop: "20px", marginBottom: "35px" }}>
              <Label htmlFor="judul" className="form-label">
                Judul
              </Label>
              <Input
                type="text"
                value={jdlKonten2}
                onChange={changeJudulKonten2}
                className="form-control"
                id="judul"
                maxLength={50}
              />
              {errorjdlKonten2 && (
                <p
                  style={{
                    margin: "0",
                    color: "#F56565",
                    fontSize: "10px",
                    position: "absolute",
                  }}
                >
                  {errorjdlKonten2}
                </p>
              )}
            </div>

            <Label htmlFor="konten" className="form-label">
              Konten
            </Label>
            <ReactQuill theme="snow" value={konten2} onChange={setKonten2} modules={modules} formats={formats} />
            {konten2 === "<p><br></p>" && (
              <p
                style={{
                  marginTop: "470px",
                  color: "#F56565",
                  fontSize: "10px",
                  position: "fixed",
                }}
              >
                Maaf, Konten tidak boleh kosong
              </p>
            )}

          </Row>
        </ModalBody>

        <ModalFooter style={{ justifyContent: "space-evenly" }}>
          <Button
            style={{
              padding: "0.5rem 3.0rem",
              backgroundColor: "#ffffff",
              color: "#718096",
              border: "1px solid #A0AEC0",
              fontWeight: "700",
              borderRadius: "6px",
            }}
            className="btn btn-light"
            onClick={() => {
              setModalEditKonten2(false);
            }}
          >
            Batal
          </Button>
          <Button
            style={{
              padding: "0.5rem 3.0rem",
              fontWeight: "700",
              borderRadius: "6px",
            }}
            className="btn btn-success"
            onClick={() => {
              copywritingKonten2()
              //judulKonten2()
              // judulKonten1()
              checkJWT();
            }}
          >
            Simpan
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalEditFeedCW} toggle={() => { openModalEditFeedCW() }} centered>
        <ModalBody style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', overflow: 'auto', }}>
          <Row style={{ alignItems: "center" }}>
            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} >

              <div style={{ width: "400px" }} >
                <Label className="form-label">
                  Copywrite feedback
                </Label>
                <textarea value={feedCW} style={{ padding: '10px 10px 80px', borderRadius: '8px' }}
                  rows="6" className="form-control" onChange={changeFeedback}></textarea>
                {errorfeedCW && (
                  <p style={{ margin: "0", color: "#F56565", fontSize: "10px", position: "absolute" }}>
                    {errorfeedCW}
                  </p>
                )}

              </div>
            </Col>


          </Row>
        </ModalBody>

        <ModalFooter style={{ justifyContent: "space-evenly" }}>
          <Button style={{ padding: "0.5rem 3.0rem", backgroundColor: "#ffffff", color: "#718096", border: "1px solid #A0AEC0", fontWeight: "700", borderRadius: "6px" }} className="btn btn-light"
            onClick={() => {
              openModalEditFeedCW();
            }}
          >
            Batal
          </Button>
          <Button style={{ padding: "0.5rem 3.0rem", fontWeight: "700", borderRadius: "6px" }}className="btn btn-success"
            onClick={() => {
              feedbackCopyWriting()
              checkJWT();
            }}
          >
            Simpan
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalEditPembukaan} toggle={() => { openModalEditPembukaan()}} centered>
        <ModalBody style={{ display: "flex", flexDirection: "column", alignItems: "center"}} >
          <Row style={{ alignItems: "center" }}>
            <Alert hidden={errorAdd} color="warning" style={{ textAlign: "center", backgroundColor: "#FFF3B5", color: "#D88E00", borderColor: "#FFF3B5", margin: "10px 0px", transition: "0.5s",}}>
              Something wrong, please check again
            </Alert>
            <div style={{ marginTop: "20px", marginBottom: "35px" }}>
              <Label htmlFor="judul" className="form-label">
                Judul
              </Label>
              <Input
                type="text"
                value={pembukaan}
                onChange={changePembukaan}
                className="form-control"
                id="judul"
                maxLength={50}
              />
              {errorPembukaan && (
                <p style={{margin: "0", color: "#F56565", fontSize: "10px", position: "absolute",}}>
                  {errorPembukaan}
                </p>
              )}
            </div>

            <Label htmlFor="konten" className="form-label">
              Video Pembukaan
            </Label>
            <Row>
              <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6} >
                <input accept="video/*" type="file" className="form-control" onChange={(event) => { onChangeUploadFile(event); change() }} />
              </Col>
              <Col xxl={3} xl={3} lg={3} md={3} sm={3} xs={3}>
                <Button onClick={() => { uploadVideoPembukaan()  }} disabled = {disable}>
                  Upload
                </Button>
              </Col>
            </Row>
          </Row>
        </ModalBody>

        <ModalFooter style={{ justifyContent: "space-evenly" }}>
          <Button style={{ padding: "0.5rem 3.0rem",backgroundColor: "#ffffff", color: "#718096", border: "1px solid #A0AEC0", fontWeight: "700", borderRadius: "6px",}}className="btn btn-light"
            onClick={() => {
              openModalEditPembukaan()
            }}
          >
            Batal
          </Button>
          <Button style={{ padding: "0.5rem 3.0rem", fontWeight: "700", borderRadius: "6px",}}className="btn btn-success"
            onClick={() => {
              createPembukaan()
              checkJWT();
            }}
          >
            Simpan
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size="md" isOpen={modalSuccess} toggle={() => { openModalSuccess() }} centered>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", margin: "90px 10px" }}>
          <img src={success} />
          <h5 style={{ textAlign: "center", margin: "20px" }}>
            Data Event Berhasil Diedit
          </h5>
        </div>
      </Modal>

      <Modal size="md" isOpen={modalSuccessCW} toggle={() => { openModalSuccessCW() }} centered>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", margin: "90px 10px" }}>
          <img src={success} />
          <h5 style={{ textAlign: "center", margin: "20px" }}>
            Konten Telah Berhasil Diperbarui
          </h5>
        </div>
      </Modal>

      {/* ------- MODAL DELETE ------- */}
      <Modal
        isOpen={modalDelete}
        toggle={() => {
          openModalDelete();
        }}
        centered
      >
        <ModalBody
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            overflow: "auto",
          }}
        >
          {ids.length !== 0 ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img src={deletePhoto} />
              <h5 style={{ textAlign: "center", marginTop: "30px" }}>
                Yakin Hapus Data?
              </h5>
              <p style={{ padding: "0 70px", textAlign: "center" }}>
                Apakah anda yakin untuk menghapus data ini?
              </p>
            </div>
          ) : (
            <>
              {" "}
              <h5 style={{ textAlign: "center", marginTop: "30px" }}>
                Tidak ada data yang dipilih
              </h5>
            </>
          )}
        </ModalBody>

        <ModalFooter style={{ justifyContent: "space-evenly" }}>
          <Button
            style={{
              padding: "0.5rem 2.0rem",
              backgroundColor: "#ffffff",
              color: "#718096",
              border: "1px solid #A0AEC0",
              fontWeight: "700",
              borderRadius: "6px",
            }}
            className="btn btn-light"
            onClick={() => {
              setIds([]);
              setModalDelete(false);
              checkJWT();
            }}
          >
            Batal
          </Button>
          {ids.length !== 0 && (
            <Button
              style={{
                padding: "0.5rem 2.0rem",
                fontWeight: "700",
                borderRadius: "6px",
              }}
              onClick={() => {
                deleteEvent();
                setModalDelete(false);
                checkJWT();
              }}
              className="btn btn-success"
            >
              Hapus
            </Button>
          )}
        </ModalFooter>
      </Modal>

      {/* ---------- MODAL SUCCESS DELETE ---------- */}
      <Modal
        size="md"
        isOpen={modalSuccessDelete}
        toggle={() => {
          openModalSuccessDelete();
        }}
        centered
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "90px 10px",
          }}
        >
          <img src={success} />
          <h5 style={{ textAlign: "center", margin: "20px" }}>
            Event Interview Berhasil Dihapus
          </h5>
        </div>
      </Modal>

      {/* ------- MODAL CONFIRM ------- */}
      <Modal
        isOpen={modalConfirm}
        toggle={() => {
          openModalConfirm();
        }}
        centered
      >
        <ModalBody
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "40px",
          }}
        >
          <img src={DraftPenilaian} />
          <h5
            style={{
              textAlign: "center",
              marginTop: "30px",
              color: "#2A4365",
              fontSize: "24px",
              fontWeight: "700",
            }}
          >
            Apakah Anda Yakin untuk membuat Pertanyaan Interview ?
          </h5>
          {/* <p style={{ padding: '0 70px', textAlign: 'center' }}>Apakah anda yakin untuk menghapus data ini?</p> */}
        </ModalBody>
        <ModalFooter style={{ justifyContent: "center" }}>
          <Button
            style={{
              padding: "0.5rem 3.0rem",
              backgroundColor: "#ffffff",
              color: "#718096",
              border: "1px solid #A0AEC0",
              fontWeight: "700",
              borderRadius: "6px",
            }}
            onClick={() => {
              setModalConfirm(false);
            }}
          >
            Batal
          </Button>
          <Button
            style={{
              padding: "0.5rem 3.0rem",
              fontWeight: "700",
              borderRadius: "6px",
            }}
            className="btn btn-success"
            onClick={() => {
              addQuestion();

              setModalConfirm(false);
              checkJWT();
            }}
          >
            Yakin
          </Button>
        </ModalFooter>
      </Modal>

      {/* ---------- MODAL SUCCESS Tambah ---------- */}
      <Modal size="md" isOpen={modalSuccessAdd} toggle={() => { openModalSuccessAdd() }} centered>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '90px 10px' }}>
          <img src={success} />
          <h5 style={{ textAlign: 'center', margin: '20px' }}> Event Berhasil Ditambahkan</h5>
        </div>
      </Modal>

      {/* ------- MODAL SOAL ERROR ------- */}
      <Modal
        isOpen={errorSoal}
        toggle={() => {
          openModalSoalError();
        }}
        centered
      >
        <ModalBody
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            overflow: "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img src={soalSalah} style={{ width: "170px" }} />
            <h5 style={{ textAlign: "center", padding: "0 70px" }}>
              Maaf, {errorKonten}{" "}
            </h5>
            <p style={{ padding: "0 70px", textAlign: "center", margin: "0" }}>
              Silahkan check kembali...
            </p>
          </div>
        </ModalBody>

        <ModalFooter style={{ justifyContent: "space-evenly" }}>
          <Button
            style={{ padding: "0.5rem 2.0rem" }}
            onClick={() => {
              setErrorSoal(false);
              checkJWT();
            }}
            className="btn btn-success"
          >
            Baik
          </Button>
        </ModalFooter>
      </Modal>

      {/* ------- MODAL LOADING ------- */}
      <Modal isOpen={modalLoading} toggle={() => { }} centered>
        <ModalBody
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            overflow: "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img src={Loading} style={{ width: "170px" }} />
            <h5 style={{ textAlign: "center", padding: "0 70px" }}>
              Tunggu Sebentar
            </h5>
            <div>
              <label htmlFor="upload">{upload}%</label>
              <progress id="upload" value={upload} max="100"></progress>
            </div>
          </div>
        </ModalBody>
      </Modal>

      {/* ---------- MODAL LIHAT VIDEO ----------- */}
      <Modal size="md" isOpen={modalLihatVideo} toggle={() => { openModalLihatVideo() }} centered >
        {step === 1 && (
          <>
            {/* {inputFields !== null && (
            <> */}
            {inputFields.map((item, index) => (
              <>
                {nomor === index && (
                  <>
                    {item.type === "video&video" && (
                      <>
                        <ModalHeader className="modal-title" id="myModalLabel" toggle={() => { openModalLihatVideo() }}>
                          Soal Video
                        </ModalHeader>
                        <ModalBody style={{ display: "flex", flexDirection: 'column', alignItems: 'center', gap: '24px' }}>
                          {/* <Button style={{ padding: "0.5rem 3.0rem" }} className="btn btn-success" onClick={() => {refreshVideo(item.namaVideoSoal)}}> Hit me </Button> */}
                          <div style={{ width: "50%", display: "flex", justifyContent: "center" }}>
                            <video style={{ width: "480px", borderRadius: "8px" }} controls>
                              <source src={item.tanya} type="video/mp4" />
                              Your browser does not support HTML5 video.
                            </video>
                          </div>
                        </ModalBody>
                      </>
                    )}
                    {item.type === "pengantar" && (
                      <>
                        <ModalHeader className="modal-title" id="myModalLabel" toggle={() => { openModalLihatVideo() }}>
                          {item.tanya}
                        </ModalHeader>
                        <ModalBody style={{ display: "flex", flexDirection: 'column', alignItems: 'center', gap: '24px' }}>
                        {/* <Button style={{ padding: "0.5rem 3.0rem" }} className="btn btn-success" onClick={() => {refreshVideo(item.namaVideoSoal)}}> Hit me </Button> */}
                          <div style={{ width: "50%", display: "flex", justifyContent: "center" }}>
                            <video style={{ width: "480px", borderRadius: "8px" }} controls>
                              <source src={item.file} type="video/mp4" />
                              Your browser does not support HTML5 video.
                            </video>
                          </div>
                        </ModalBody>
                      </>
                    )}
                  </>
                )}
              </>
            ))}
          </>
        )}
        <ModalFooter style={{ justifyContent: "center" }}>
          <Button style={{ padding: "0.5rem 3.0rem", backgroundColor: "#ffffff", color: "#718096", border: "1px solid #A0AEC0", fontWeight: "700" }} className="btn btn-light"
            onClick={() => {
              setModalLihatVideo(false);
              checkJWT();
            }}
          >
            Tutup
          </Button>
        </ModalFooter>
      </Modal>

      {/* ---------- MODAL JWT -------- */}
      <Modal isOpen={modalJWT} centered>
        <ModalBody
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={Timeout} />
          <h5
            style={{
              textAlign: "center",
              marginTop: "30px",
              fontSize: "24px",
              fontWeight: "700",
              color: "#2A4365",
            }}
          >
            Maaf, Silahkan Login Kembali
          </h5>
          <p
            style={{
              padding: "0 45px",
              textAlign: "center",
              fontSize: "16px",
              color: "#4A5568",
              fontWeight: "400",
            }}
          >
            Sesi Anda sudah kadaluarsa, Jangan khawatir... Click tombol di bawah
            ini untuk login kembali :)
          </p>
        </ModalBody>
        <ModalFooter style={{ justifyContent: "center" }}>
          <Button
            style={{ padding: "0.5rem 3.0rem" }}
            className="btn btn-success"
            onClick={() => {
              window.localStorage.clear();
              window.sessionStorage.clear();
              window.location.reload();
              window.location.href = "/login-admin";
            }}
          >
            Login
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default I2;
