import React from "react";
import { Col, Container, Row } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import Widget from "./Widgets";
import BestSellingProducts from "./BestSellingProducts";
import RecentActivity from "./RecentActivity";
import RecentOrders from "./RecentOrders";
import Revenue from "./Revenue";
import SalesByLocations from "./SalesByLocations";
import Section from "./Section";
import StoreVisits from "./StoreVisits";
import TopSellers from "./TopSellers";

const p1 = () => {
  document.title="Dashboard | Velzon - React Admin & Dashboard Template";
  return (
    <React.Fragment>
      <div className="page-content">
        <h3>Peraturan Interview</h3>
        <ol>
          <li>Perserta <span style={{fontWeight: '800'}}>wajib</span> menggunakan komputer / laptop yang memiliki webcam atau kamera yang memadai</li>
          <li>Perserta <span style={{fontWeight: '800'}}>wajib</span> menyalakan webcam atau kamera selama proses interview berlangsung</li>
          <li>Perserta <span style={{fontWeight: '800'}}>perlu memastikan</span> koneksi internet stabil dan bebas gangguan</li>
          <li>Perserta <span style={{fontWeight: '800'}}>tidak diperkenankan</span> untuk membuka tab lain selain halaman website interview</li>
          <li>Perserta <span style={{fontWeight: '800'}}>diperbolehkan</span> untuk berlatih terlebih dahulu menggunakan fitur "trial" sebelum menjawab pertanyaan interview</li>
          <li>Perserta <span style={{fontWeight: '800'}}>wajib</span> menggunakan pakaian rapi dan sopan</li>
          <li>Perserta <span style={{fontWeight: '800'}}>diharapkan</span> untuk dapat memilih tempat yang tenang dan minim gangguan selama sesi interview</li>
          <li>Selama sesi interview berlangsung, perserta <span style={{fontWeight: '800'}}>tidak diperkenankan</span> untuk berbicara dengan orang lain, makan atau minum ataupun meninggalkan ruangan.</li>
        </ol>

        {/* <Container fluid>
          <BreadCrumb title="Dashboard" pageTitle="Dashboards" />
          <Row>
            <Col>
              <div className="h-100">
                <Section />
                <Row>
                  <Widget />
                </Row>
                <Row>
                  <Col xl={8}>
                    <Revenue />
                  </Col>
                  <SalesByLocations />
                </Row>
                <Row>
                  <BestSellingProducts />
                  <TopSellers />
                </Row>
                <Row>
                  <StoreVisits />
                  <RecentOrders />
                </Row>
              </div>
            </Col>
            <RecentActivity />
          </Row>
        </Container> */}
      </div>
    </React.Fragment>
  );
};

export default p1
;
